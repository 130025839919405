import React from "react";
import PageTitle from "../common/pagetitle/pagetitle";
import PageName from "../common/pagename/pagename";
import { connect } from "react-redux";
import { historyStatement, missionStatement, serverLink, visionStatement } from "../../resources/url";
import { Link } from "react-router-dom";
import * as DOMPurify from 'dompurify';

function About(props) {
  return (
    <>
      <PageName page={"About Us"} />
      <PageTitle title={"About Us"} breadCrumbs={["Home", "About"]} />
      <div className="container mt-5">
        <div className="row  mb-5">
          <div className="col-lg-8" style={{ textAlign: "justify" }}>
            <h2>About the University</h2>
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.homeData.AboutContent) }}
            />
          </div>
          <div className="col-lg-4">
            <div className="additional-information">
              <h3>Additional Information</h3>
              <div className="list">
                <ul>
                  <li>
                    <Link to="/our-campus">Our Campus</Link>
                  </li>
                  <li>
                    <Link to="/admission/course-finder">Courses</Link>
                  </li>
                  <li>
                    <Link to="/admission/application">Application</Link>
                  </li>
                  <li>
                    <Link to="/education">Education</Link>
                  </li>
                  <li>
                    <Link to="/news">News</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center pb-50">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Our Mission
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse active show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body text-justify">{missionStatement}</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Our Vision
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">{visionStatement}</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                >
                  Our Core Values
                </button>
              </h2>
              <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  •	Excellence <br/>
                  •	Professionalism <br/>
                  •	Service
                </div>
              </div>
            </div>
            {historyStatement !== "" && (
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Our History
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">{historyStatement}</div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    homeData: state.HomepageContentDetails,
  };
};

export default connect(mapStateToProps, null)(About);
