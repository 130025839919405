import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { serverLink } from "../../../../resources/url";
import {
  setApplicationDataDetails,
  setApplicationLoginDetails,
  setPGApplicationDataDetails,
  setPGApplicationRequirementListDetails,
} from "../../../../actions/setactiondetails";
import axios from "axios";
import PGApplicationRequirement from "./section/pgapplicationrequirement";
import PGApplicationSidebar from "./section/pgapplicationsidbar";
import PGApplicationPersonalInfo from "./section/pgapplicationpersonalinfo";
import PGApplicationQualification from "./section/pgapplicationqualification";
import PGApplicationEmployment from "./section/pgapplicationemployment";
import PGApplicationDocument from "./section/pgapplicationdocument";
import PGApplicationPreview from "./section/pgapplicationpreview";

function PostgraduateApplicationDashboard(props) {
  const navigate = useNavigate();

  const [activePage, setActivePage] = useState({
    dashboard: "active",
    personal_info: "",
    qualification: "",
    employment: "",
    document: "",
    preview: "",
  });
  const [applicationDetail, setApplicationDetail] = useState({
    qualification: [],
    employment: [],
    document: [],
    information: [],
  });

  const [applicationRequirement, setApplicationRequirement] = useState([]);

  const [requiredDoc, setRequiredDoc] = useState(false);

  const handleLogout = () => {
    props.setOnApplicationLoginDetails("");
    props.setOnApplicationDataDetails("");
    navigate("/admission/application");
  };

  const getPGApplicationRequirementList = async () => {
    await axios
      .get(`${serverLink}application/pg/requirements/`)
      .then((response) => {
        if(response.data.message ==="success"){
          setApplicationRequirement(response.data.data);
        }
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });
  };

  const getPGApplicationData = async () => {
    await axios
      .get(
        `${serverLink}application/pg/application/data/${props.applicationData.EntryID}/${props.applicationData.ApplicantID}`
      )
      .then((response) => {
        setApplicationDetail(response.data);
        checkIfAllRequiredDocUploaded(response.data.document);
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });
  };

  useEffect(() => {
    if (props.applicationData === "" || props.applicationLogin === "")
      navigate("/admission/application");

    getPGApplicationRequirementList().then((r) => {});
    getPGApplicationData().then((r) => {});
  }, []);

  const checkIfAllRequiredDocUploaded = (documents) => {
    let checker = 0;
    applicationRequirement.length > 0 &&
      applicationRequirement.map((req) => {
        if (req.IsRequired === 1) {
          let check = documents.filter(
            (doc) => doc.DocumentType === req.DocumentName
          );
          if (check.length === 0 || check.length < req.MinDocument) {
            setRequiredDoc(false);
            checker = 1;
          }
        }
      });

    if (checker === 0) setRequiredDoc(true);
  };

  useEffect(() => {
    checkIfAllRequiredDocUploaded(applicationDetail.document);
  }, [applicationDetail]);

  const start_application = () => {
    setActivePage({
      dashboard: "",
      personal_info: "active",
    });
  };

  return (
    props.applicationData !== "" &&
    props.applicationLogin !== "" && (
      <>
        <>
          <hr className="pt-1" />
          <div className="graduate-admission pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <PGApplicationSidebar
                    userData={props.applicationLogin}
                    activePage={activePage}
                    handleLogout={handleLogout}
                    applicationDetail={applicationDetail}
                    doc_required={requiredDoc}
                  />
                </div>

              <div className="col-lg-8">
                  {activePage.dashboard === "active" && (
                    <PGApplicationRequirement
                      requirement={applicationRequirement}
                      start_application={start_application}
                      app_status={props.applicationData.Status}
                      app_reason={props.applicationData.RejectReason}
                    />
                  )}

                  {activePage.personal_info === "active" && (
                    <PGApplicationPersonalInfo
                      applicant_info={props.applicationLogin}
                      application_id={props.applicationData.ApplicantID}
                      update_app_data={setApplicationDetail}
                      active_page={setActivePage}
                    />
                  )}

                  {activePage.qualification === "active" && (
                    <PGApplicationQualification
                      application_id={props.applicationData.EntryID}
                      applicant_data={applicationDetail}
                      // update_app_data={setApplicationDetail}
                      update_app_data={getPGApplicationData}
                      active_page={setActivePage}
                    />
                  )}

                  {activePage.employment === "active" && (
                    <PGApplicationEmployment
                      application_id={props.applicationData.EntryID}
                      applicant_data={applicationDetail}
                      update_app_data={getPGApplicationData}
                      active_page={setActivePage}
                    />
                  )}

                  {activePage.document === "active" && (
                    <PGApplicationDocument
                      application_id={props.applicationData.EntryID}
                      applicant_data={applicationDetail}
                      update_app_data={getPGApplicationData}
                      application_requirement={applicationRequirement}
                      active_page={setActivePage}
                    />
                  )}

                  {activePage.preview === "active" && (
                    <PGApplicationPreview
                      application_id={props.applicationData.EntryID}
                      applicant_data={applicationDetail}
                      update_app_data={getPGApplicationData}
                      application_requirement={applicationRequirement}
                      active_page={setActivePage}
                      requiredDoc={requiredDoc}
                      app_record={props.applicationData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    )
  );
}
const mapStateToProps = (state) => {
  return {
    applicationLogin: state.ApplicationLoginDetails,
    applicationData: state.ApplicationDataDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnApplicationLoginDetails: (p) => {
      dispatch(setApplicationLoginDetails(p));
    },
    setOnApplicationDataDetails: (p) => {
      dispatch(setApplicationDataDetails(p));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostgraduateApplicationDashboard);
