import { Link } from "react-router-dom";
import { encryptData } from "../common/cryptography/cryptography";
import { formatDate } from "../common/dateformat/dateformat";

const JobCards = (props) => {
    return (
        <div className="col-lg-8">
            <div className="courses-left-content">
                {props.jobList.length > 0 ? (
                    <div className="row">
                        {props.jobList.map((item, key) => {
                            return <div className="col-lg-6 col-md-6" key={key}>
                                <div className="single-courses-card style4">
                                    <div className="courses-content">
                                        <Link to={`/jobs/details/${encryptData(item.EntryID.toString())}`}><h3>{item.Position}</h3></Link>
                                        <div className="bottom-content">
                                            <ul className="">
                                                <li>{item.Type}</li>
                                                <li>Closing Date:&nbsp;{formatDate(item.ClosingDate)}</li>
                                                {item.Urgent.toString() === '1' && <li><i />Status: Urgent</li>}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}

                    </div>
                ) : (
                    <h3>No Jobs Found</h3>
                )}
            </div>
        </div>
    )
}

export default JobCards;