import React from "react";
import { Link } from "react-router-dom";
import OurCampus from "../../../images/general/Olivia/content-03.jpg";
import { connect } from "react-redux";

function SectionUniversityContent() {
    return (
        <div className="campus-information-area pb-70 bg-f4f6f9">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="campus-image pl-20">
                            <img src={OurCampus} alt={"Our campus"} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="campus-content pl-20">
                            <div className="campus-title">
                                <h3>Admissions and affiliated Universities</h3>
                                <p style={{ textAlign: "justify" }}>
                                    Whether you are new to OUB or rejoining, find out everything you need to know about applying to a flexible and accessible University undergraduate degree program. We are one of the fully accredited universities in Burundi and affiliated with some schools in Europe and America.
                                    We’re so pleased that you’re interested in joining the OUB family. Our dedicated admissions team is waiting for your application.
                                    OUB is affiliated with several Universities in Europe, America and Asia.</p>
                            </div>
                            <Link to={"/about-us"} className="default-btn btn">
                                Learn More <i className="flaticon-next" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        courseList: state.CourseListDetails.length,
        departmentList: state.DepartmentListDetails.length,
    };
};

export default connect(mapStateToProps, null)(SectionUniversityContent);
