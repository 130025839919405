import React from "react";
import { projectName } from "../../../resources/url";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import OurCampus from "../../../images/general/Olivia/content-02.jpg";
import { connect } from "react-redux";

function SectionStartApplication(props) {
    return (
        <div className="campus-information-area pb-70 bg-f4f6f9">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="campus-content pr-20">
                            <div className="campus-title">
                                <h2>Admission into Olivia University</h2>
                                <p style={{ textAlign: "justify" }}>
                                    Admission into Olivia University Bujumbura is open to candidates with the requisite qualifications and subject to written and/or oral examination as the management of the University may determine.

                                    Admission is open to all candidates irrespective of religion, ethnic group, gender, creed and disability. All applicants for Admission into Olivia University Bujumbura must have attained 16 years of age.
                                </p>
                            </div>
                            <Link to={"/admission/application"} className="default-btn btn">
                                Start your application <i className="flaticon-next" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="campus-image pl-20">
                            <img src={OurCampus} alt={"Our campus"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        courseList: state.CourseListDetails.length,
        departmentList: state.DepartmentListDetails.length,
    };
};

export default connect(mapStateToProps, null)(SectionStartApplication);
