import React from "react";
import PageName from "../common/pagename/pagename";
import PageTitle from "../common/pagetitle/pagetitle";
import BujumBura1 from './Bujumbura1.jpg'
import BujumBura2 from './Bujumbura2.jpg'


const BriefHistory = () => {

    return (
        <>
            <PageName page={"Our History"} />
            <PageTitle title={"Our History"} breadCrumbs={["About","Our History"]} />
            <div className="container">
                <div className="row align-items-center pt-10 mt-3">
                    <div className="col-lg-12">
                        <div className="campus-content pr-20">
                            <div className="campus-title">
                                <h3>History</h3>
                                <p style={{ textAlign: 'justify' }}>
                                    The historical background for the establishment of OUB finds expression within the context of the founder's existential reality. Sir Paul Chukwuma, the founder and Pro-chancellor of OUB is fortunate to have been born into a strong Catholic family. He had the opportunity and privilege of attending Catholic schools from his primary education through secondary school to higher institution. Indeed he, at an early stage in life, indicated interest in becoming a Catholic priest, of the Congregation of the Holy Spirit (Spiritans). His first degree in philosophy, where he graduated with first class honours, was fully paid and sponsored by the Catholic Church, (the Congregation of the Holy Spirit Nigeria Province) this foundation to a very large extent prepared him for success and life challenges ahead.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    It is therefore against this background that the idea of OUB was conceived; as a Catholic conventional private university. A private initiative on "education mission" in Burundi. A Catholic University open to all men and women irrespective of Creed, Ethnicity or Gender.  A university open to all who seek genuine quest for knowledge, learning and character.  A university open to all men and women who seek God and truth with a sincere heart, irrespective of religious tendencies, as intended by the Catholic Eucharistic Prayer IV ....... ‘’your entire people, and all who seek you with a sincere heart."
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    OUB is therefore a private Catholic education initiative of the founder with the primary purpose of giving back to others, especially the less privileged, what he received freely through the instrumentality of the Catholic education system. 
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    The choice of Burundi for the establishment of OUB is not an accident. Not at all. As one of the acclaimed poorest country, but undoubtedly one of the most beautiful, green, serene and peaceful countries of the world, at least at the moment, the need to establish OUB in Burundi remains ad rem. It is therefore our intention to offer as many tuition free scholarships, as we can afford, in Computing, Engineering, Medical Pharmaceutical and Agricultural sciences to teeming young Burundians and others who cannot afford quality education while we charge reasonable fees, commensurate with OUB quality and standard, to those who can afford it.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    It is equally our hope that the establishment of OUB in Burundi will deepen the roots and broadens the branches of the olive tree (as reflected in the meaning of Olivia) we planted with the establishment of OUB, such that lasting peace, the very peace of Christ, will be sustained, not only in Burundi but throughout the East African region. It is also not a mere coincidence that the founder's daughter bears the name Olivia. Her prayers for the realisation of this education mission in Burundi cannot be overemphasised.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BriefHistory;