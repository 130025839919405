import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PageTitle from "../../common/pagetitle/pagetitle";
import EventSidebar from "./section/eventsidebar";
import NewsDetailsContent from "../news/section/newsdetailscontent";
import PageName from "../../common/pagename/pagename";
import EventDetailsContent from "./section/EventDetailsContent";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {isEmptyObject, serverLink} from "../../../resources/url";
import Loader from "../../common/loader/loader";
import {setGeneralDetails, setNewsDetails} from "../../../actions/setactiondetails";

function EventDetail(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const { slug } = useParams();

    if (slug === "") navigate("/");
console.log(isEmptyObject(props.eventData))
    const [loading, setLoading] = useState(isEmptyObject(props.eventData));
    const [eventData, setEventData] = useState(
        props.eventList.length < 1
            ? {}
            : props.eventList.filter((e) =>
                e.Slug.toLowerCase().includes(slug.toLowerCase())
            )[0] );
    const [eventList, setEventList] = useState(props.eventList);
    const [change, setChange] = useState("");

    const getData = async () => {
        await axios
            .get(`${serverLink}event/list/all/General`)
            .then((result) => {
                const data = result.data.filter(e=>e.Status.toString() === "1");
                if (data.length > 0){
                    setEventData(data.filter((e) => e.Slug.toLowerCase().includes(slug.toLowerCase()))[0])
                    props.setOnGeneralDetails(data.filter((e) => e.Slug.toLowerCase().includes(slug.toLowerCase()))[0]);
                }else{
                    navigate("/event")
                }
                setLoading(false);
                setEventList(data);
                props.setOnEventDetails(data);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        if (slug === ""){
            navigate("/event")
        }
        getData();

    }, [change]);

    const changeScreen = (items) => {
        setChange(items);
    };

    return (loading ? (
                    <Loader />
                ) :
    <>
      <PageName page={eventData.Title} />
      <PageTitle
        title={"Event Details"}
        breadCrumbs={[
          "Resources",
           "Event",
          "Event Details",
        ]}
      />
      <div className="news-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <EventDetailsContent eventData={eventData} />
            <EventSidebar eventList={eventList} channgeScreen={changeScreen} />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    eventData: state.GeneralDetails,
    eventList: state.EventDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setOnEventDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EventDetail);
