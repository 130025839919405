import React from "react";
import CountUp from "react-countup";
import {connect} from "react-redux";

function SectionUniversityCount(props) {
    return (
        <div className="campus-information-area pb-50  bg-f4f6f9">
            <div className="container-fluid">
                <div className="counter mt-0 text-center" style={{backgroundColor: '#eeeeee', paddingTop: '35px'}}>
                    <div className="row">
                        <div className="col-lg-6 col-6">
                            <div className="counter-card">
                                <h1>
                        <span className="odometer">
                          <CountUp end={props.facultyList} />
                        </span>
                                    <span className="target">+</span>
                                </h1>
                                <p>Faculties</p>
                            </div>
                        </div>
                        {/*<div className="col-lg-4 col-4">*/}
                        {/*    <div className="counter-card">*/}
                        {/*        <h1>*/}
                        {/*<span className="odometer">*/}
                        {/*  <CountUp end={props.departmentList} />*/}
                        {/*</span>*/}
                        {/*            <span className="target">+</span>*/}
                        {/*        </h1>*/}
                        {/*        <p>Departments</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-lg-6 col-6">
                            <div className="counter-card">
                                <h1>
                        <span className="odometer">
                          <CountUp end={props.courseList} />
                        </span>
                                    <span className="target">+</span>
                                </h1>
                                <p>Courses</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
const mapStateToProps = (state) => {
    return {
        facultyList: state.FacultyListDetails.length,
        courseList: state.CourseListDetails.length,
        departmentList: state.DepartmentListDetails.length,
    };
};
export default connect(mapStateToProps, null)(SectionUniversityCount);
