import axios from "axios";
import React, { useEffect, useState } from "react";
import { projectShortCode, serverLink } from "../../../resources/url";
import _ from "lodash";
import PageTitle from "../../common/pagetitle/pagetitle";
import Loader from "../../common/loader/loader";
import PageName from "../../common/pagename/pagename";

const Senate = () => {
  const [senateList, setSenateList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getSenateList() {
      const result = await axios.get(`${serverLink}leadership/senate/list`);
      setSenateList(result.data);
      setLoading(false);
    }
    getSenateList();
  }, []);
  return (
    <>
      {/*{loading ? <Loader /> : ""}*/}

      <PageName page={"Senate"} />
      <PageTitle title={"Senate"} breadCrumbs={["Home", "Senate"]} />
      <div className="container">
        {/*<div className="mt-5" style={{ height: 400 }}>*/}
        {/*  <h1>Senate list coming soon</h1>*/}
        {/*</div>*/}

        <div className="row">
          <h1 className="text-center my-4">Senate</h1>
          {senateList?.map((senate, index) => (
            <div className="col-3 " key={index}>
              <img
                style={{ height: 300, width: 300 }}
                src={senate.ImagePath !== null ? senate.ImagePath.includes("simplefileupload") ? senate.ImagePath :
                    !_.isEmpty(senate.ImagePath)
                    ? `${serverLink}public/uploads/${projectShortCode}/leaderships_uploads/${senate.ImagePath}`
                    : `${serverLink}public/uploads/${projectShortCode}/no-image.jpeg` : ""
                }
                alt={senate.FullName}
              />
              <h6 className="text-center mt-1">
                {senate.Title} {senate.FullName}
              </h6>
              <p className="text-center mb-5">{senate.Designation}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Senate;
