import React from "react";
import PageTitle from "../../common/pagetitle/pagetitle";
import PageName from "../../common/pagename/pagename";
import { connect } from "react-redux";
const Community = (props) => {
    return (
        <>
            <PageName page={"Community"} />
            <PageTitle title={"Community"} breadCrumbs={["Academics", "Community"]} />
            <div className="container mt-5 mb-5">
                <div className="row align-items-center pt-10 mt-3">
                    <div className="col-lg-12">
                        <div className="campus-content pr-20">
                            <div className="campus-title">
                                <h3>With our hosts</h3>
                                <p style={{ textAlign: 'justify' }}>
                                While Africa and the world at-large is OUB’s “Community,” OUB has a special commitment to the Bujumbura community in which it is located, hence, it’s dedicated sense of corporate social responsibility to the same. Not only does OUB hire the majority of its staff from the local community, it has a commitment towards admitting as many Burundians as are qualified to be at the university. Additionally, OUB has planned projects to interface and integrate with area farmers and husbandry businesses; with the objective of establishing internationally collaborative agricultural extension projects, to improve farming and husbandry tools, crop yields, environmental conservation, etc. OUB sees itself as an integral part of the Bujumbura community.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default connect(mapStateToProps, null)(Community);
