import React from "react";

function OfflineMessage() {
  return (
    <div>
      <div className="error-area ptb-100">
        <div>
          <h4>No internet</h4>
          <p>
            The Internet connection appears to be offline. Please connect back
            and refresh the page.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OfflineMessage;
