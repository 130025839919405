import React from "react";
import PageTitle from "../../common/pagetitle/pagetitle";
import { Link } from "react-router-dom";
import fussball from "../../../images/fussball.jpg";
import television from "../../../images/tvarea.jpg";
import tennis from "../../../images/tennis.jpg";
import PageName from "../../common/pagename/pagename";
export default function Accommodation() {
  return (
    <>
      <PageName page={"Accommodation"} />
      <PageTitle
        title={` Accommodation `}
        breadCrumbs={["Academics", "Accommodation"]}
      />
      <div className="academics-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-4">OUB Provides Accommodation for Foreign Students</h3>
              <ul>
                <li>Our on-campus accommodation is safe, secure, and affordable. It is located close to all academic buildings and facilities, making it convenient for students to get around.</li>
                <li>Foreign students who are interested in on-campus accommodation should contact the Olivia University Bujumbura International Student Services Office for more information.</li>
                <li>To apply for on-campus accommodation:
                  <ul>
                    <li>Complete the on-campus accommodation application form.</li>
                    <li>Pay the application fee.</li>
                    <li>Submit your application form and application fee to the Olivia University Bujumbura International Student Services Office.</li>
                  </ul>
                </li>
              </ul>
             <h3 className="mb-4">The hostel rooms</h3>
             <ul>
              <li>All rooms are fully furnished and equipped with the following amenities:
                <ul>
                  <li>Bed</li>
                  <li>Desk</li>
                  <li>Chair</li>
                  <li>Wardrobe</li>
                  <li>Wi-Fi access</li>
                  <li>Shared bathroom</li>
                </ul>
              </li>
              <li>We also have a number of common areas where students can relax, socialize, and study. These include a lounge, a kitchen, and a dining room.</li>
             </ul>
              <h3 className="mt-4">Other Facilities</h3>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="single-campus-card">
                    <div className="img">
                      <img
                        src={fussball}
                        style={{ width: "100%" }}
                        alt="Fussball"
                      />
                    </div>
                    <div className="campus-content">
                      <span className="text-secondary">Fussball</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="single-campus-card">
                    <div className="img">
                      <img
                        src={television}
                        style={{ width: "100%" }}
                        alt="Television Area"
                      />
                    </div>
                    <div className="campus-content">
                      <span className="text-secondary">Television Area</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="single-campus-card">
                    <div className="img">
                      <img
                        src={tennis}
                        style={{ width: "100%" }}
                        alt="Indoor Tennis"
                      />
                    </div>
                    <div className="campus-content">
                      <span className="text-secondary">Indoor Tennis</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="detgails-right-content">
                <div className="academics-list">
                  <h3>QUICK LINKS</h3>
                  <ul>
                    <li>
                      <Link to={`/fees`}>
                        Fees <i className="ri-arrow-drop-right-fill" />
                      </Link>
                    </li>
                    <li>
                      <Link to={`/admission/course-finder`}>
                        Course Finder <i className="ri-arrow-drop-right-fill" />
                      </Link>
                    </li>
                    <li>
                      <Link to={`/education`}>
                        Education <i className="ri-arrow-drop-right-fill" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
