import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { projectShortCode, serverLink } from "../../../resources/url";
import './section-partners.css'
const Partners = () => {
    const [partners, setPartners] = useState([])
    const getData = async () => {
        await axios.get(`${serverLink}slider/partners/homepage`)
            .then((result) => {
                setPartners(result.data)
            })
            .catch((e) => {
                console.log('NETWORK ERROR')
            })
    }
    const centralize = {
        maxWidth: '100%',
        textAlign: 'center',
        position: 'relative'
    }

    useEffect(() => {
        getData();
    }, [])
    return (
        <>
            {
                partners.length > 0 ?
                    <>
                        <div className="campus-life-area ptb-10 pb-3">
                            <div className="container">
                                <div className="section-title">
                                    <h2>Our Partners</h2>
                                </div>


                                <div style={centralize}>
                                    {
                                        partners.map((item, index) => {
                                            return (
                                                <img key={index} src={item.ImagePath !== null ? item.ImagePath.includes("simplefileupload") ? item.ImagePath :  `${serverLink}public/uploads/${projectShortCode}/partners_uploads/${item.ImagePath}` : ""} height={150} width={'25%'} alt="" style={{marginRight:'10px', marginLeft:'10px'}} />
                                            )
                                        })
                                    }
                                    {/* <img src="https://download.logo.wine/logo/University_of_Manchester/University_of_Manchester-Logo.wine.png" height={150} width={'25%'} alt="" />
                                    <img src="https://download.logo.wine/logo/University_of_Manchester/University_of_Manchester-Logo.wine.png" height={150} width={'25%'} alt="" />
                                    <img src="https://pulitzercenter.org/sites/default/files/styles/orig_optimized/public/03-28-17/clark-atlanta-logosmall.jpg?itok=h2Dz-Unk" height={150} width={'25%'} alt="" />
                                    <img src="https://assets.website-files.com/5e318ddf83dd66053d55c38a/6260040bed3520251f0d3947_Central%20State%20Uni.png" height={150} width={'25%'} alt="" /> */}
                                </div>

                            </div>
                        </div>
                    </>
                    : <></>
            }

        </>
    )
}

export default Partners;