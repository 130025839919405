import React from "react";
import { projectName } from "../../../../resources/url";
import { subjects } from "../../../common/ssce/subjects";

export default function UGExamResult(props) {
  let options = [];
  if(projectName.toLocaleLowerCase().includes('olivia')){
    options = ["A1(80% - 100%)", "B2(70% - 79%)", "B3(65% - 69%)", "C4(60% - 64%)", "C5(55% - 59%)", "C6(50% - 54%)", "D7(45% - 49%)", "E8 (40% - 44%)", "F9 (39% and below)"];
  }else{
    options = ["A1", "B2", "B3", "C4", "C5", "C6", "D7", "E8", "F9"];
  }

  return (
    <>
      <div className="col-4">
        <div className="form-group">
          <label htmlFor={`result_type`}>
            Exam Type{" "}
            {props.index < 5 && <span className="text-danger">*</span>}
          </label>
          <select
            id={`result_type`}
            data={props.index}
            className="form-control"
            onChange={props.handleChange}
          >
            <option value="">--</option>
            {props.exam_type.ExamType1 !== "" && (
              <option value={props.exam_type.ExamType1}>
                {props.exam_type.ExamType1}
              </option>
            )}
            {props.exam_type.ExamType2 !== "" && (
              <option value={props.exam_type.ExamType2}>
                {props.exam_type.ExamType2}
              </option>
            )}
          </select>
        </div>
      </div>
      <div className="col-4">
        <div className="form-group">
          <label htmlFor={`result_subject`}>
            Subject {props.index < 5 && <span className="text-danger">*</span>}
          </label>
          <select
            id={`result_subject`}
            data={props.index}
            className="form-control"
            onChange={props.handleChange}
          >
            <option value="">--</option>
            {subjects.map((s, i) => (
              <option key={i} value={s}>
                {s}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-4 ">
        <div className="form-group">
          <label htmlFor={`result_score`}>
            Grade {props.index < 5 && <span className="text-danger">*</span>}
          </label>
          <select
            id={`result_score`}
            data={props.index}
            className="form-control"
            onChange={props.handleChange}
          >
            <option value="">--</option>
            {options.map((o, i) => (
              <option key={i} value={o}>
                {o}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}
