import { useNavigate } from "react-router-dom";
import stateData from "../../../../resources/state_and_lga.json";
import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "../../../../resources/url";
import {
  setApplicationLoginDetails,
  setGeneralDetails,
} from "../../../../actions/setactiondetails";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";

function GuardianInformation(props) {
  const navigate = useNavigate();
  const [userData] = useState(props.applicationLogin);
  const [guardianData, setGuardianData] = useState(
    props.applicant_data.guardian
  );
  const [guardian, setGuardian] = useState({
    ApplicationID: props.applicationData.EntryID,
    FirstName: "",
    MiddleName: "",
    Surname: "",
    PhoneNumber: "",
    EmailAddress: "",
    Address: "",
    Gender: "",
  });

  const [shouldSave, setShouldSave] = useState(false);

  const handleChange = (e) => {
    setGuardian({ ...guardian, [e.target.id]: e.target.value });
    setShouldSave(true);
  };

  const allApplicationData = async () => {
    await axios
      .get(
        `${serverLink}application/ug/application/data/${props.applicationData.EntryID}`
      )
      .then((response) => {
        props.setOnGeneralDetails(response.data);
        setGuardianData(response.data.guardian);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let value in guardian) {
      if (guardian.hasOwnProperty(value) && value !== "MiddleName") {
        if (guardian[value] === "") {
          toast.error(`${value} can't be empty`);
          return false;
        }
      }
    }

    if (shouldSave) {
      toast.info(`Submitting... Please wait!`);
      await axios
        .post(`${serverLink}application/guardian/info`, guardian)
        .then((res) => {
          if (res.data.message === "success") {
            toast.success(`Guardian Information Submitted Successfully`);
            allApplicationData();
            onNext();
          } else
            toast.error(
              `Something went wrong. Please check your connection and try again!`
            );
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    }
  };

  const handleNewEntry = async () => {
    await axios
      .delete(
        `${serverLink}application/guardian/delete/${props.applicationData.EntryID}`
      )
      .then((res) => {
        if (res.data.message === "success") {
          allApplicationData();
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
      });
  };

  const onPrevious = () => {
    navigate("/admission/application/undergraduate/secondary-school-result");
  };

  const onNext = () => {
    navigate("/admission/application/undergraduate/documents-uploads");
  };
  return (
    <div className="graduate-admission pb-70">
      <div className="container">
        <div className="row">
          <Sidebar userData={userData} />
          <div className="col-9">
            <div className="register">
              <h3>Guardian Information</h3>
              {guardianData.length < 1 && (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label htmlFor="FirstName">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="FirstName"
                          className="form-control"
                          placeholder="First Name"
                          // required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label htmlFor="MiddleName">Middle Name</label>
                        <input
                          type="text"
                          id="MiddleName"
                          className="form-control"
                          placeholder="Middle Name"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label htmlFor="Surname">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="Surname"
                          className="form-control"
                          placeholder="Last Name"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label htmlFor="PhoneNumber">
                          Phone Number <span className="text-danger">*</span>
                        </label>
                        <input
                          type="tel"
                          id="PhoneNumber"
                          className="form-control"
                          placeholder="Phone Number"
                          // required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label htmlFor="EmailAddress">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          id="EmailAddress"
                          className="form-control"
                          placeholder="Email Address"
                          // required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-4 ">
                      <div className="form-group">
                        <label htmlFor="Gender">
                          Gender <span className="text-danger">*</span>
                        </label>
                        <select
                          id="Gender"
                          className="form-control"
                          onChange={handleChange}
                        >
                          <option value="">Select Option</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="Address">
                          Address <span className="text-danger">*</span>
                        </label>
                        <textarea
                          type="text"
                          id="Address"
                          className="form-control"
                          placeholder="Address"
                          rows="3"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="default-btn btn w-50 active"
                    onClick={onPrevious}
                  >
                    Previous
                  </button>
                  <button type="submit" className="default-btn btn w-50 active">
                    Save and Continue
                  </button>
                </form>
              )}

              {guardianData.length > 0 && (
                <>
                  <button
                    className="btn btn-secondary btn-sm float-end"
                    onClick={handleNewEntry}
                  >
                    Edit Guardian
                  </button>
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th>Guardian Name</th>
                        <td>
                          {guardianData[0].FirstName}{" "}
                          {guardianData[0].MiddleName} {guardianData[0].Surname}
                        </td>
                      </tr>
                      <tr>
                        <th>Email Address</th>
                        <td>{guardianData[0].EmailAddress}</td>
                      </tr>
                      <tr>
                        <th>Phone Number</th>
                        <td>{guardianData[0].PhoneNumber}</td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td>{guardianData[0].Address}</td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    className="default-btn btn w-50 active"
                    onClick={onPrevious}
                  >
                    Previous
                  </button>
                  <button
                    onClick={onNext}
                    className="default-btn btn w-50 active"
                  >
                    Save and Continue
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    applicationLogin: state.ApplicationLoginDetails,
    applicant_data: state.GeneralDetails,
    applicationData: state.ApplicationDataDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnApplicationLoginDetails: (p) => {
      dispatch(setApplicationLoginDetails(p));
    },
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuardianInformation);
