import axios from "axios";
import React, { useEffect, useState } from "react";
import {projectShortCode, serverLink} from "../../../resources/url";
import PageTitle from "../../common/pagetitle/pagetitle";
import Loader from "../../common/loader/loader";
import PageName from "../../common/pagename/pagename";
import LeadershipProfileModal from "./profile-modal/modal";
import './profile-modal/modal.css'

const Board = () => {
  const [boards, setBoard] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getBoardMemebers() {
      const result = await axios.get(`${serverLink}leadership/board/list`);
      setBoard(result.data);
      setLoading(false);
    }
    getBoardMemebers();
  }, []);

  const [boardmember, setMember] = useState({})
  // window.onclick(() => {
  //   let modal = document.getElementById("myModal");
  //   modal.style.display = "none";
  // })
  return (
    <>
      {loading ? <Loader /> : ""}
      <PageName page={"Board Members"} />
      <PageTitle
        title={"Board Members"}
        breadCrumbs={["Home", "Board Members"]}
      />
      <div className="container">
        <div className="col-12">
          <h3 className="text-center my-5">Board Members</h3>
          <div className="d-flex align-items-center align-content-between flex-column">
            <div className="row">
              {boards.map((board, index) => (
                <div
                  className={`text-center ${index === 0 ? "col-md-12 d-block" : "col-md-4"
                    }  `}
                  key={index}
                >
                  <img
                    src={board.ImagePath !== null ? board.ImagePath.includes("simplefileupload") ? board.ImagePath : `${serverLink}public/uploads/${projectShortCode}/leaderships_uploads/${board.ImagePath}` : ""}
                    alt={board.FullName}
                    className="img-thumbnail"
                    style={{
                      height: 400,
                      width: 400,
                    }}
                  />
                  <h4 className="my-2">
                    {board.Title} {board.FullName}
                  </h4>
                  <h5 >{board.Designation}</h5>
                  {/* <p style={{ cursor: 'pointer' }} className="text-center mb-5" id="myBtn" onClick={() => {
                    let modal = document.getElementById("myModal");
                    modal.style.display = "block";
                    setMember(board)
                  }}>View profile</p> */}
                  <p style={{ cursor: 'pointer'}} className="text-center mb-5" data-bs-toggle="modal" data-bs-target="#pModal" onClick={() => {
                    setMember(board)
                  }}>View profile</p>

                </div>

              ))}
            </div>


            <LeadershipProfileModal boardmember={boardmember} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Board;
