import React, { useEffect, useState } from "react";
import axios from "axios";
import {projectCode, projectName, serverLink} from "../../../../resources/url";
import Loader from "../../../common/loader/loader";
import {
    formatDateAndTime,
    projectAddress,
    projectLogo
} from "../../../../resources/url";
import "./payment-receipt.css";
import BarcodeImage from "../../../common/barcode/barcode";
import {useNavigate, useParams} from "react-router-dom";

export default function PaymentReceipt() {
    const { app_id, app_status } = useParams();
    const navigate = useNavigate();
    const AppID = atob(app_id)
    const AppStatus = atob(app_status);
    if (AppID === '' || typeof AppID === 'undefined' || AppStatus === '' || typeof AppStatus === 'undefined') {
        window.location.href = '/'
    }

    const east_african_countries = ['Tanzania', 'Kenya', 'Uganda', 'Rwanda', 'Burundi', 'Democratic', 'Congo', 'Sudan']
    const [isLoading, setIsLoading] = useState(true);
    const [record, setRecord] = useState({});
    const [applicationID, setApplicationID] = useState(0);
    const [region, setRegion] = useState(true);
    const [itemList, setItemList] = useState([{
        Description: 'Application Fee',
        Amount: 0
    },{
        Description: 'Tuition Fee',
        Amount: 0
    },{
        Description: 'Hostel Fee',
        Amount: 0
    },
    ]);

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const today = new Date();

    const dateNow = `${days[today.getDay()]}, ${monthNames[today.getMonth()]} ${today.getDate()}, ${today.getFullYear()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`

    const loadReceiptItems = async () => {
        await axios.get( `${serverLink}application/applicant_detail/${AppID}` )
            .then((response) => {
                if (response.data.length > 0){
                    let data = response.data[0];
                    const padApplicationID = (data, places) => String(data).padStart(places, "0");
                    let application_id;
                    if (parseInt(AppStatus) === 2)
                        application_id = `OUB/APP/${data.AdmissionSemester}/${padApplicationID(data.ApplicationID, 4)}`;
                    else
                        application_id = `OUB/APP/${padApplicationID(data.ApplicationID, 4)}`;

                    setRecord(response.data[0]);
                    setApplicationID(application_id)
                    let IsEastAfrican = east_african_countries.includes(data.Nationality);
                    setRegion(IsEastAfrican);
                    console.log(data)
                    let itemList = [];
                    if (parseInt(AppStatus) === 2)
                    {
                        itemList.push({Description: 'Tuition Fee', Amount: IsEastAfrican ? 3150000 : 3000})
                        if (data.IsHostelRequired === 'Yes')
                            itemList.push({Description: 'Hostel Fee', Amount: 800})
                    } else {
                        itemList.push({Description: 'Application Fee', Amount: IsEastAfrican ? 100000  : 150})
                    }
                    setItemList(itemList)
                }else {
                    window.location.href = '/'
                }
                setIsLoading(false)
            })
            .catch((error) => {
                window.location.href = '/'
            });
    }

    useEffect(()=> {
        loadReceiptItems();
    }, []);

    const printNow = () => {
        window.print();
    }

    const currencyConverter = (amount, region) => {
        let currencyCode = 'NGN';
        switch (projectCode) {
            case 'OLIVIA_UNIVERSITY':
                currencyCode = 'BIF';
                break;
            case 'BABA_AHMAD_UNIVERSITY_STAFF_PORTAL':
                currencyCode = 'NGN';
                break;
            default:
                currencyCode = 'NGN';
        }
        const formatter = new Intl.NumberFormat('en-NG', {
            style: 'currency',
            currency: projectCode === 'OLIVIA_UNIVERSITY' ? region ? currencyCode : 'USD' : currencyCode,
        });
        return formatter.format(amount)
    }

    return isLoading ? (
        <Loader />
    ) : (
        <div className="container">
            <div className="col-md-8 offset-sm-2">
                <div className="col-lg-12 bgImg hero-image">
                    <div className="head-main">
                        <center className="mb-2"><img src={projectLogo} alt="Logo" width={230} height={100}  style={{marginRight: '15px'}}/></center>
                        <div className="head-item">
                            <div>
                                <h4 className="m-0 text-center text-uppercase">{projectName}</h4>
                                <span className="" style={{width: '50px'}}>{projectAddress}</span><br/>
                                <div  className="text-center text-uppercase">Date: {dateNow}</div>
                            </div>

                        </div>
                    </div>

                    <div className="text-center mt-2" style={{marginBottom: '0px'}}><b> Payment Invoice</b></div>
                    <BarcodeImage value={applicationID} height={60} width={2.0}/>

                    <div className="item-data mt-3">
                        <div className="">
                            <table>
                                <tbody>
                                <tr>
                                    <th>Application ID: </th>
                                    <td className="fw-bold">{applicationID}</td>
                                </tr>
                                <tr>
                                    <th>Applicant Name: </th>
                                    <td className="fw-bold">{record.FirstName} {record.MiddleName} {record.Surname}</td>
                                </tr>
                                {
                                    record.Status === 2 &&
                                    <tr>
                                        <th>Course: </th>
                                        <td className="fw-bold text-capitalize">{record.CourseName}</td>
                                    </tr>
                                }

                                </tbody>
                            </table>
                        </div>

                        <div className="">
                            <table>
                                <tbody>
                                {
                                    record.Status === 1 ?
                                        <tr>
                                            <th>Course: </th>
                                            <td className="fw-bold text-capitalize">{record.CourseName}</td>
                                        </tr> :
                                        <>
                                            <tr>
                                                <th>Level: </th>
                                                <td className="fw-bold text-capitalize">{record.DecisionLevel}</td>
                                            </tr>
                                            <tr>
                                                <th>Semester: </th>
                                                <td className="fw-bold"> {record.DecisionSemester}</td>
                                            </tr>
                                        </>
                                }

                                <tr>
                                    <th>Invoice Date: </th>
                                    <td> <b>  {formatDateAndTime(record.ApplicationDate, 'date')}</b></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="">
                        <table className="table  table-responsive-sm mt-2 mb-2">
                            <thead style={{borderBottom: '1px solid grey'}}>
                            <tr>
                                <th>S/No</th>
                                <th>Description</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {itemList&&
                                itemList.map((item ,key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{(key+1)}</td>
                                            <td>{item.Description}</td>
                                            <td>{currencyConverter(parseFloat(item.Amount), region)}</td>
                                        </tr>
                                    )
                                })}
                            <tr>
                                <td className="fw-bold text-center" colSpan={2} style={{fontWeight: 'bold'}}>TOTAL: </td>
                                <td  className="fw-bold"  style={{fontWeight: 'bold'}}>{currencyConverter(itemList.map(e=>e.Amount).reduce((a, b) => a + b, 0), region)}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="col-md-12 mt-2">
                            <h5 className="text-center">BANK INFORMATION</h5>
                            {
                                region ?
                                    <p className="text-size">
                                        <b>For Burundi nationals:</b><br/>
                                        <b>Beneficiary name:</b> Olivia University<br/>
                                        <b>Beneficiary Account no.:</b> 0038125024251 (BIF)<br/>
                                        <b>Beneficiary Bank name:</b> ECOBANK BURUNDI LTD<br/>
                                    </p>
                                    :
                                    <p className="text-size">
                                        <b>Please pay into Olivia University Account as per following details:</b><br/>
                                        <b>For international Students:</b><br/>
                                        <b>Beneficiary name:</b> Olivia University<br/>
                                        <b>Beneficiary Naira Account No.:</b> 4011483174 (NGN)<br/>
                                        <b>Beneficiary USD Account No.:</b> 5250380260 (USD)<br/>
                                        <b>Beneficiary Bank name:</b> Fidelity Bank<br/>
                                    </p>
                                    // <p className="text-size">
                                    //     <b>Please pay into Olivia University Account as per following details:</b><br/>
                                    //     <b>For international Students:</b><br/>
                                    //     <b>Beneficiary name:</b> Olivia University<br/>
                                    //     <b>Beneficiary Account no.:</b> 0038125024252 (USD)<br/>
                                    //     <b>Beneficiary Bank name:</b> ECOBANK BURUNDI LTD<br/>
                                    //     <b>IBAN:</b>BI4220007161010038125024252<br/>
                                    //     <b>SWIFT CODE:</b> ECOBIBI<br/>
                                    // </p>
                            }


                        </div>
                        {/*<table className="table  table-responsive-sm mt-3 mb-1" style={{fontSize: '12px'}}>*/}
                        {/*    <thead style={{borderBottom: '1px solid grey'}}>*/}
                        {/*    <tr>*/}
                        {/*        <th>Currency</th>*/}
                        {/*        <th>Code</th>*/}
                        {/*        <th>Country</th>*/}
                        {/*        <th>Bank Code</th>*/}
                        {/*        <th>Account NO.</th>*/}
                        {/*    </tr>*/}
                        {/*    </thead>*/}
                        {/*    <tbody>*/}
                        {/*    <tr>*/}
                        {/*        <td>EUR</td>*/}
                        {/*        <td>ECOCBIBI</td>*/}
                        {/*        <td>ALLEMAGNE</td>*/}
                        {/*        <td>COBADEFF</td>*/}
                        {/*        <td>400885013300</td>*/}
                        {/*    </tr>*/}
                        {/*    <tr>*/}
                        {/*        <td>USD</td>*/}
                        {/*        <td>ECOCBIBI</td>*/}
                        {/*        <td>ETATS-UNIS D'AMERIQUE</td>*/}
                        {/*        <td>CITIUS33</td>*/}
                        {/*        <td>36393029</td>*/}
                        {/*    </tr>*/}
                        {/*    <tr>*/}
                        {/*        <td>XOF</td>*/}
                        {/*        <td>ECOCBIBI</td>*/}
                        {/*        <td>ECOBANK SENEGAL</td>*/}
                        {/*        <td>ECOCSNDA</td>*/}
                        {/*        <td>19211001416901</td>*/}
                        {/*    </tr>*/}
                        {/*    </tbody>*/}
                        {/*</table>*/}
                        <h4 className="mt-2 mb-3">For Bank Only</h4>
                        <div className="item-data">
                            <table className="table table-borderless">
                                <tbody>
                                <tr>
                                    <td style={{display: 'block', paddingBottom: '50px'}}>Amount Paid: -------------------------</td>
                                </tr>
                                <tr>
                                    <td style={{display: 'block'}}>Payment Reference: -------------------</td>
                                </tr>

                                </tbody>
                            </table>

                            <table className="table table-borderless">
                                <tbody>
                                <tr>
                                    <td style={{display: 'block', paddingBottom: '50px'}}>Received By: -------------------------</td>
                                </tr>
                                <tr>
                                    <td>Stamp: ------------------------------</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <button id="printPageButton" onClick={printNow} className="btn btn-secondary mb-5 mt-3">Print <i className="fa fa-print"/></button>

                <button  id="printPageButton" onClick={()=> navigate("/admission/application/offer")} className="btn btn-warning mb-5 mlr-5 mt-3">
                    Back <i className="fa fa-arrow-circle-o-left"/>
                </button>
            </div>
        </div>
    )
}
