import React from "react";
import {formatDate, serverLink} from "../../../resources/url";
import {Button} from "react-bootstrap";

export default function NewStudentEnrolmentForm(props) {
    return (
        <>
            <h5>Basic Information</h5>
            <hr />
            <div className="row">
                <div className="form-group col-md-4 mb-4">
                    <label htmlFor="FirstName">First Name</label>
                    <input
                        type="text"
                        id="FirstName"
                        className="form-control"
                        placeholder="First Name"
                        onChange={props.onEdit}
                        value={props.data.FirstName}
                    />
                </div>
                <div className="form-group col-md-4 mb-4">
                    <label htmlFor="MiddleName">Middle Name</label>
                    <input
                        type="text"
                        id="MiddleName"
                        className="form-control"
                        placeholder="Middle Name"
                        onChange={props.onEdit}
                        value={props.data.MiddleName}
                    />
                </div>
                <div className="form-group col-md-4 mb-4">
                    <label htmlFor="Surname">Surname</label>
                    <input
                        type="text"
                        id="Surname"
                        className="form-control"
                        placeholder="Surname"
                        onChange={props.onEdit}
                        value={props.data.Surname}
                    />
                </div>
                <div className="form-group  col-md-4 mb-4">
                    <label htmlFor="Gender"> Gender</label>
                    <select
                        id={"Gender"}
                        onChange={props.onEdit}
                        value={props.data.Gender}
                        className={"form-control"}
                    >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>
                <div className="form-group col-md-4 mb-4">
                    <label htmlFor="DateOfBirth">Date Of Birth</label>
                    <input
                        type="date"
                        id="DateOfBirth"
                        className="form-control"
                        placeholder="Date Of Birth"
                        onChange={props.onEdit}
                        value={formatDate(props.data.DateOfBirth)}
                    />
                </div>
                <div className="form-group col-md-4 mb-4">
                    <label htmlFor="PhoneNumber">Phone Number</label>
                    <input
                        type="number"
                        id="PhoneNumber"
                        className="form-control"
                        placeholder="Phone Number"
                        onChange={props.onEdit}
                        value={props.data.PhoneNumber}
                    />
                </div>
                <div className="form-group col-md-4 mb-4">
                    <label htmlFor="EmailAddress">EmailAddress</label>
                    <input
                        type="email"
                        id="EmailAddress"
                        className="form-control"
                        placeholder="Email Address"
                        onChange={props.onEdit}
                        value={props.data.EmailAddress}
                    />
                </div>
                <div className="form-group  col-md-4 mb-4">
                    <label htmlFor="Nationality">Nationality</label>
                    <select
                        id={"Nationality"}
                        onChange={props.onEdit}
                        value={props.data.Nationality}
                        className={"form-control"}
                    >
                        <option value="">Select Nationality</option>
                        {
                            props.nationalityList.map((country, index) => {
                                return <option key={index} value={country}>{country}</option>
                            })
                        }

                    </select>
                </div>
                <div className="form-group  col-md-4 mb-4">
                    <label htmlFor="StateOfOrigin">State Of Origin</label>
                    <select
                        id={"StateOfOrigin"}
                        onChange={props.onEdit}
                        value={props.data.StateOfOrigin}
                        className={"form-control"}
                    >
                        <option>Select State</option>
                        {
                            props.stateList.map((state, index) => {
                                return <option key={index} value={state}>{state}</option>
                            })
                        }

                    </select>
                </div>
                <div className="form-group  col-md-4 mb-4">
                    <label htmlFor="Lga">LGA</label>
                    <input
                        type="Lga"
                        id="Lga"
                        className="form-control"
                        placeholder="LGA"
                        onChange={props.onEdit}
                        value={props.data.Lga}
                    />

                </div>
                <div className="form-group col-md-4 mb-4">
                    <label htmlFor="Address">Address</label>
                    <input
                        type="text"
                        id="Address"
                        className="form-control"
                        placeholder="Address"
                        onChange={props.onEdit}
                        value={props.data.Address}
                    />
                </div>
                <div className="form-group mb-2 mt-5">
                    <h5>Program Details</h5>
                    <hr />
                </div>
                <div className="form-group col-md-4 mb-0">
                    <label htmlFor="CourseCode">Course </label>
                    <select
                        id={"CourseCode"}
                        onChange={props.onEdit}
                        value={props.data.CourseCode}
                        className={"form-control"}
                        disabled
                    >
                        <option>Select Course</option>
                        {
                            props.courseList.length > 0 && props.courseList.map((course, index) => {
                                return <option key={index} value={course.CourseCode}>{course.CourseName}</option>
                            })
                        }

                    </select>
                </div>
                <div className="form-group mb-4 col-md-4">
                    <label htmlFor="StudentLevel">Student Level</label>
                    <select
                        id={"StudentLevel"}
                        onChange={props.onEdit}
                        value={props.data.StudentLevel}
                        className={"form-control"}
                        disabled
                    >
                        <option>Select Student Level</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="300">300</option>
                        <option value="400">400</option>
                        <option value="500">500</option>
                        <option value="600">600</option>
                        <option value="700">700</option>
                        <option value="800">800</option>
                        <option value="900">900</option>
                    </select>
                </div>
                <div className="form-group col-md-4 mb-4">
                    <label htmlFor="StudentSemester">Student Semester</label>
                    <input
                        type="text"
                        id="StudentSemester"
                        className="form-control"
                        placeholder="Student Semester"
                        onChange={props.onEdit}
                        value={props.data.StudentSemester}
                        disabled
                    />
                </div>
                <div className="form-group col-md-4 mb-4">
                    <label htmlFor="ModeOfEntry">Mode Of Entry</label>
                    <select
                        id={"ModeOfEntry"}
                        onChange={props.onEdit}
                        value={props.data.ModeOfEntry}
                        className={"form-control"}
                        disabled
                    >
                        <option>Select Mode Of Entry</option>
                        <option value="UTME">UTME</option>
                        <option value="IJMB">IJMB</option>
                        <option value="Direct Entry">Direct Entry</option>
                        <option value="Transfer">Transfer</option>
                        <option value="Conditional">Conditional</option>
                        <option value="Foundation">Foundation</option>
                    </select>
                </div>
                {/*<div className="form-group col-md-4 mb-4">*/}
                {/*    <label htmlFor="YearOfAdmission">Year Of Admission </label>*/}
                {/*    <select*/}
                {/*        id={"YearOfAdmission"}*/}
                {/*        onChange={props.onEdit}*/}
                {/*        value={props.data.YearOfAdmission}*/}
                {/*        className={"form-control"}*/}
                {/*        disabled*/}
                {/*    >*/}
                {/*        <option>Select Year Of Admission</option>*/}
                {/*        <option value="2022">2022</option>*/}
                {/*        <option value="2021">2021</option>*/}
                {/*        <option value="2020">2020</option>*/}
                {/*        <option value="2019">2019</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
                <div className="form-group col-md-4 mb-4">
                    <label htmlFor="AdmissionSemester">Admission Semester</label>
                    <select
                        id={"AdmissionSemester"}
                        onChange={props.onEdit}
                        value={props.data.AdmissionSemester}
                        className={"form-control"}
                        disabled
                    >
                        <option>Select Admission Semester</option>
                        {
                            props.semesterList.length > 0 && props.semesterList.map((semester, index) => {
                                return <option key={index} value={semester.SemesterCode}>{semester.SemesterCode}</option>
                            })
                        }

                    </select>
                </div>
            </div>
            {
                props.parentData.length > 0 ?
                    <div className={"row"}>
                        <div className="form-group mb-5 mt-5">
                            <h5>Guardian Details</h5>
                            <hr />
                        </div>
                        <div className="form-group col-md-4 mb-4">
                            <label htmlFor="ParentName">Parent Name</label>
                            <input
                                type="text"
                                id="ParentName"
                                className="form-control"
                                placeholder="Parent Name"
                                onChange={props.onEdit}
                                value={props.data.ParentName}
                            />
                        </div>
                        <div className="form-group col-md-4 mb-4">
                            <label htmlFor="ParentPhoneNumber">Parent Phone Number</label>
                            <input
                                type="number"
                                id="ParentPhoneNumber"
                                className="form-control"
                                placeholder="ParentPhoneNumber"
                                onChange={props.onEdit}
                                value={props.data.ParentPhoneNumber}
                            />
                        </div>
                        <div className="form-group col-md-4 mb-4">
                            <label htmlFor="ParentAddress">Parent Address</label>
                            <input
                                type="text"
                                id="ParentAddress"
                                className="form-control"
                                placeholder="ParentAddress"
                                onChange={props.onEdit}
                                value={props.data.ParentAddress}
                            />
                        </div>
                    </div> : ""
            }

            <div className="form-group col-md-4 offset-sm-4 pt-4">
                <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="default-btn btn w-100 active">
                    {
                        props.isFormLoading ?
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                            </span>
                            :
                            <span className="indicator-label">Submit</span>
                    }
                </button>
            </div>
        </>
    )
}