import React from "react";
import {
    formatDateAndTime,
    historyStatement,
    missionStatement,
    serverLink,
    visionStatement
} from "../../../resources/url";
import {Link} from "react-router-dom";

export default function SectionUniversityMission() {
    return (
        <div className="events-area  ptb-50">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-4 shadow">
                        <div className="single-events-card">
                            <div className="events-content">
                                <div className="mission">
                                    <p>
                                        <i className="fa fa-area-chart fa-4x"/>
                                    </p>
                                </div>
                                <h4>Mission</h4>
                                <p style={{textAlign: "justify"}}>
                                    {missionStatement}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 shadow">
                        <div className="single-events-card">
                            <div className="events-content">
                                <div className="mission">
                                    <p>
                                        <i className="fa fa-eye fa-4x"/>
                                    </p>
                                </div>
                                <h4>Vision</h4>
                                <p style={{textAlign: "justify"}}>
                                    {visionStatement}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 shadow">
                        <div className="single-events-card">
                            <div className="events-content">
                                <div className="mission">
                                    <p>
                                        <i className="fa fa-institution fa-4x"/>
                                    </p>
                                </div>
                                <h4> Our Core Values</h4>
                                <p style={{textAlign: "justify"}}>
                                    <ol>
                                        <li>Excellence</li>
                                        <li>Professionalism</li>
                                        <li>Service</li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
