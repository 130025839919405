import React, { useEffect, useState } from "react";
import {
  setApplicationDataDetails,
  setApplicationLoginDetails,
} from "../../../../actions/setactiondetails";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  formatDateAndTime,
  projectName,
  projectURL,
  sendEmail,
  serverLink,
} from "../../../../resources/url";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import PageName from "../../../common/pagename/pagename";

function ApplicationDashboard(props) {
  const navigate = useNavigate();
  const [userData] = useState(props.applicationLogin);
  const [applicationData, setApplicationData] = useState([]);
  const [showApplicationModal, setShowApplicationModal] = useState(false);

  const handleCloseApplicationModal = () => setShowApplicationModal(false);
  const handleShowApplicationModal = () => setShowApplicationModal(true);

  const getApplicationRecord = async () => {
    props.setOnApplicationDataDetails("");
    toast.info('Loading application data. Please wait!')
    await axios
        .get(
            `${serverLink}application/applicant/application/list/${userData.EntryID}`
        )
        .then((response) => {
          setApplicationData(response.data);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
  };

  const onApplyNow = async (course) => {
    const sendData = {
      ApplicantID: userData.EntryID,
      ApplicationType: course.ApplicationType,
      CourseCode: course.CourseCode,
    };

    await axios
        .post(`${serverLink}application/apply_now`, sendData)
        .then((response) => {
          const message = response.data.message;
          if (message === "success") {
            toast.success("Course Selected Successful");
            handleCloseApplicationModal();
            getApplicationRecord();

            sendEmail(
                userData.EmailAddress,
                `Thank you for your application to the ${projectName}`,
                "Application for Admission",
                `${userData.FirstName} ${userData.MiddleName} ${userData.Surname}`,
                `Thank you for starting an application with ${projectName}. <br/>  
                    If you do not have all the required information and supplemental items to submit your application, you can always go back to complete it by logging in to ${projectURL}admission/application <br/> 
                    `,
                `Best Regards <br/> Admission Team <br/> ${projectURL}`
            );
          } else if (message === "record exist") {
            toast.error(
                "You have a pending application awaiting decision for the course you've selected"
            );
          } else {
            toast.error(
                "Something went wrong. Please check your network and try again!"
            );
          }
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
  };

  useEffect(() => {
    if (props.applicationLogin === "") navigate("/admission/application");
  }, [props.applicationLogin]);

  useEffect(() => {
    getApplicationRecord();
  }, []);

  const handleLogout = () => {
    props.setOnApplicationLoginDetails("");
    props.setOnApplicationDataDetails("");
    navigate("/admission/application");
  };

  return (
      props.applicationLogin !== "" && (
          <>
            <PageName page={"Dashboard"} />
            <Modal
                show={showApplicationModal}
                onHide={handleCloseApplicationModal}
                size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Apply Now</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {props.courseList.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Course Name</th>
                          <th>Code</th>
                          <th>Duration</th>
                          <th>Type</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.courseList.map((course, index) => {
                          return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{course.CourseName}</td>
                                <td>{course.CourseCode}</td>
                                <td>
                                  {course.Duration} {course.DurationType}
                                </td>
                                <td>{course.ApplicationType}</td>
                                <td>
                                  <button
                                      className="btn btn-secondary btn-sm"
                                      onClick={() => onApplyNow(course)}
                                  >
                                    Apply
                                  </button>
                                </td>
                              </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </div>
                ) : (
                    <div className="alert alert-info">NO COURSE FOUND!</div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleCloseApplicationModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="graduate-admission pb-70">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="additional-information">
                      <h3>Welcome, {userData.FirstName}!</h3>
                      <div className="list">
                        <ul>
                          <li>
                            <Link to={"/admission/application/dashboard"}>
                              Dashboard
                            </Link>
                          </li>
                          <li onClick={handleLogout}>
                            <Link to={""}>
                              Logout
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="additional-information">
                      <button
                          className="default-btn btn w-100"
                          onClick={handleShowApplicationModal}
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="instructor">
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <div className="instructor-content">
                            <div className="name">
                              <h3>{`${userData.FirstName} ${userData.MiddleName} ${userData.Surname}`}</h3>
                              <p>{userData.Occupation}</p>
                            </div>
                            <div className="mb-30 table-responsive">
                              <table className="table table-striped">
                                <tbody>
                                <tr>
                                  <th>Email Address</th>
                                  <td>{userData.EmailAddress}</td>
                                </tr>
                                <tr>
                                  <th>Phone Number</th>
                                  <td>{userData.PhoneNumber}</td>
                                </tr>
                                <tr>
                                  <th>Date Of Birth</th>
                                  <td>
                                    {formatDateAndTime(
                                        userData.DateOfBirth,
                                        "date"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Gender</th>
                                  <td>{userData.Gender}</td>
                                </tr>
                                <tr>
                                  <th>Address</th>
                                  <td>{userData.Address}</td>
                                </tr>
                                <tr>
                                  <th>State</th>
                                  <td>{userData.StateOfOrigin}</td>
                                </tr>
                                <tr>
                                  <th>Local Government</th>
                                  <td>{userData.LGA}</td>
                                </tr>
                                <tr>
                                  <th>Nationality</th>
                                  <td>{userData.Nationality}</td>
                                </tr>
                                <tr>
                                  <th>Religion</th>
                                  <td>{userData.Religion}</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="how-to-apply">
                      <h3>Application History</h3>

                      <div className="table-responsive">
                        {applicationData.length > 0 ? (
                            <table className="table table-hover">
                              <thead>
                              <tr>
                                <th>S/N</th>
                                <th>AppID</th>
                                <th>Course</th>
                                <th>Type</th>
                                <th>Application Date</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              {applicationData.map((app, index) => {
                                let status = "";
                                let action = "--";
                                if (app.Status === 0) {
                                  status = (
                                      <span className="badge bg-warning">
                                  Not Submitted
                                </span>
                                  );
                                  action = (
                                      <Link
                                          to={`/admission/application/${app.ApplicationType}`}
                                      >
                                        <button
                                            className="btn btn-info btn-sm w-100"
                                            onClick={() =>
                                                props.setOnApplicationDataDetails(app)
                                            }
                                        >
                                          continue
                                        </button>
                                      </Link>
                                  );
                                } else if (app.Status === 1) {
                                  status = (
                                      <span className="badge bg-primary">
                                  Submitted
                                </span>
                                  );
                                  action = (
                                      <Link to={`/admission/application/offer`}>
                                        <button
                                            className="btn btn-primary w-100 btn-sm"
                                            onClick={() =>
                                                props.setOnApplicationDataDetails(app)
                                            }
                                        >
                                          print invoice
                                        </button>
                                      </Link>
                                  );
                                } else if (app.Status === 2) {
                                  status = (
                                      <span className="badge bg-success">
                                  Approved
                                </span>
                                  );
                                  action = (
                                      <Link to={`/admission/application/offer`}>
                                        <button
                                            className="btn btn-success btn-sm w-100"
                                            onClick={() =>
                                                props.setOnApplicationDataDetails(app)
                                            }
                                        >
                                          view offer
                                        </button>
                                      </Link>
                                  );
                                } else {
                                  status = (
                                      <span className="badge bg-danger">
                                  Rejected
                                </span>
                                  );
                                }

                                return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{app.EntryID}</td>
                                      <td>{app.CourseName}</td>
                                      <td>{app.ApplicationType}</td>
                                      <td>
                                        {formatDateAndTime(
                                            app.InsertedDate,
                                            "date_and_time"
                                        )}
                                      </td>
                                      <td>{status}</td>
                                      <td>{action}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                            </table>
                        ) : (
                            <div className="alert alert-info">
                              NO APPLICATION HISTORY FOUND
                            </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
      )
  );
}

const mapStateToProps = (state) => {
  return {
    applicationLogin: state.ApplicationLoginDetails,
    courseList: state.CourseListDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnApplicationLoginDetails: (p) => {
      dispatch(setApplicationLoginDetails(p));
    },
    setOnApplicationDataDetails: (p) => {
      dispatch(setApplicationDataDetails(p));
    },
  };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplicationDashboard);