import React from "react";
import { Link } from "react-router-dom";

export default function HomepageSectionEducationArea() {
  return (
    <div className="eduction-area pb-70">
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-lg-4 col-md-6">
            <div className="single-education-card bg-2">
              <div className="edication-content">
                <Link to={"/admission/application"}>
                  <h3>Apply Now</h3>
                <div className="icon">
                  <i className="flaticon-student-with-graduation-cap"></i>
                </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-education-card bg-3">
              <div className="edication-content">
                <Link to={"/admission/course-finder"}>
                  <h3>Our Courses</h3>
                <div className="icon">
                  <i className="flaticon-college-graduation"></i>
                </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-education-card bg-4">
              <div className="edication-content">
                <Link to={"/about-us"}>
                  <h3>About Us</h3>
                <div className="icon">
                  <i className="flaticon-user"></i>
                </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
