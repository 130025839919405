import React from "react";
import {
  historyStatement,
  missionStatement,
  projectShortCode,
  serverLink,
  visionStatement,
} from "../../resources/url";
import PageTitle from "../common/pagetitle/pagetitle";
import PageName from "../common/pagename/pagename";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as DOMPurify from 'dompurify';


const OurCampus = (props) => {
  return (
    <>
      <PageName page={"Our Campus"} />
      <PageTitle title={"Our Campus"} breadCrumbs={["About","Our-Campus"]} />
      <div className="container">
        <div className="row align-items-center pt-10">
          <div className="col-lg-6">
            <div className="campus-content pr-20">
              <div className="campus-title">
                <p
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(props.homeData.CampusContent),
                  }}
                />
                <Link to={"/admission/application"} className="default-btn btn">
                  Start your application <i className="flaticon-next" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="campus-image pl-20">
              <img
                src={props.homeData.CampusImage !== null ? props.homeData.CampusImage.includes("simplefileupload") ? props.homeData.CampusImage :  `${serverLink}public/uploads/${projectShortCode}/content_uploads/${props.homeData.CampusImage}` : ""}
                alt={"Our campus"}
              />
            </div>
          </div>
        </div>

        {props.homeData.CampusContent2 !== "" && (
          <div className="row align-items-center pt-50">
            <div className="col-lg-6">
              <div className="campus-image pl-20">
                <img
                  src={props.homeData.CampusImage2 !== null ? props.homeData.CampusImage2.includes("simplefileupload") ? props.homeData.CampusImage2 :  `${serverLink}public/uploads/${projectShortCode}/content_uploads/${props.homeData.CampusImage2}` : ""}
                  alt={"Our campus"}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="campus-content pr-20">
                <div className="campus-title">
                  <p
                    style={{ textAlign: "justify" }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(props.homeData.CampusContent2),
                    }}
                  />

                  <Link
                    to={"/admission/application"}
                    className="default-btn btn"
                  >
                    Start your application <i className="flaticon-next" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row align-items-center ptb-50">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Our Mission
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse active show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">{missionStatement}</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Our Vision
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">{visionStatement}</div>
              </div>
            </div>
            {historyStatement !== "" && (
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Our History
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">{historyStatement}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    homeData: state.HomepageContentDetails,
  };
};

export default connect(mapStateToProps, null)(OurCampus);
