import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { Link } from "react-router-dom";
import Section from "../section/section";
import OfflineMessage from "../../common/message/offline";
import PageName from "../../common/pagename/pagename";
import axios from "axios";
import { serverLink } from "../../../resources/url";

function Education() {
  const [loading, setLoading] = useState(true);
  const [education, setEducation] = useState([]);

  const getUniversityLearningGoals = async () => {
    await axios
      .get(`${serverLink}academic/education/all/`)
      .then((response) => {
        setEducation(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });
  };

  useEffect(() => {
    getUniversityLearningGoals().then((r) => {});
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageName page={"Academic Education"} />
          <div className="page-banner-area bg-2">
            <div className="container">
              <div className="page-banner-content">
                <h1>Education</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Education</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="graduate-admission pt-100 pb-70" style={{ textAlign: "justify" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="how-to-apply">
                    {education.length > 0 &&
                      education.map((r, index) => (
                        <div key={index}>
                          <h3>{r.ContentTitle}</h3>
                          <p>{r.ContentText}</p>
                        </div>
                      ))}
                  </div>
                </div>
                <Section />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default Education;
