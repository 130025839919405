import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { formatDateAndTime, serverLink } from "../../../../../resources/url";
import { Button } from "react-bootstrap";

function PGApplicationEmployment(props) {
  const [employment, setEmployment] = useState(props.applicant_data.employment);

  const [addEmployment, setAddEmployment] = React.useState(false);
  const [register, setRegister] = useState({
    ApplicationID: props.application_id,
    Designation: "",
    Employer: "",
    EmployerAddress: "",
    StartDate: "",
    EndDate: "",
  });
  const [stillWorking, setStillWorking] = useState(false);
  const addForm = () => {
    setAddEmployment(true);
    setStillWorking(false);
    setRegister({
      ApplicationID: props.application_id,
      Designation: "",
      Employer: "",
      EmployerAddress: "",
      StartDate: "",
      EndDate: "",
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    for (let key in register) {
      if (register.hasOwnProperty(key) && key !== "EndDate") {
        if (register[key] === "") {
          toast.error(`${key} can't be empty`);
          return false;
        }
      } else {
        if (register[key] === "" && !stillWorking) {
          toast.error(`${key} can't be empty`);
          return false;
        }
      }
    }

    toast.info(`Submitting... Please wait!`);
    await axios
      .post(`${serverLink}application/pg/employment`, register)
      .then((res) => {
        if (res.data.message === "success") {
          toast.success(`Added Successfully`);
          props.update_app_data();
          setAddEmployment(false);
        } else {
          toast.error(
            `Something went wrong. Please check your connection and try again!`
          );
        }
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });
  };

  const onEdit = (e) => {
    const value = e.target.value;
    const id = e.target.id;

    setRegister({
      ...register,
      [id]: value,
    });

    setEmployment({
      ...employment,
      [id]: value,
    });
  };

  const onPrevious = () => {
    props.active_page({
      qualification: "active",
      employment: "",
    });
  };

  const onNext = () => {
    props.active_page({
      employment: "",
      document: "active",
    });
  };

  const deleteItem = async (id) => {
    if (id) {
      toast.info(`Deleting... Please wait!`);
      await axios
        .delete(`${serverLink}application/pg/employment/delete/${id}`)
        .then((res) => {
          if (res.data.message === "success") {
            props.update_app_data();
            toast.success(`Deleted`);
          } else {
            toast.error(
              `Something went wrong. Please check your connection and try again!`
            );
          }
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    }
  };

  const handleStillWorking = (e) => {
    setStillWorking(e.target.checked);
  };

  return (
    <div className="row">
      <div className="register">
        <h3>Employment</h3>

        {addEmployment ? (
          <div style={{ float: "right" }}>
            <button className="default-btn btn w-100 active" onClick={onSubmit}>
              Save
            </button>
          </div>
        ) : (
          <div style={{ float: "right" }}>
            <button className="default-btn btn w-100 active" onClick={addForm}>
              Add Employment
            </button>
          </div>
        )}
        <br />
        <br />
        <br />
        <hr />

        {addEmployment ? (
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="Designation">Designation</label>
                <input
                  type="text"
                  id="Designation"
                  className="form-control"
                  placeholder="Designation"
                  required
                  onChange={onEdit}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="Employer">Employer</label>
                <input
                  type="text"
                  id="Employer"
                  className="form-control"
                  placeholder="Employer"
                  required
                  onChange={onEdit}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="Employer">Employer Address</label>
                <input
                  type="email"
                  id="EmployerAddress"
                  className="form-control"
                  placeholder="Employer Address"
                  required
                  onChange={onEdit}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="Employer">Start Date</label>
                <input
                  type="date"
                  id="StartDate"
                  required
                  className="form-control"
                  placeholder="Start Date"
                  onChange={onEdit}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="Employer">End Date</label>
                <input
                  type="date"
                  id="EndDate"
                  required
                  className="form-control"
                  placeholder="EndDate"
                  onChange={onEdit}
                />
                <span>
                  Still working here?{" "}
                  <input
                    type="checkbox"
                    value={stillWorking}
                    onChange={handleStillWorking}
                    id="StillWorking"
                  />
                </span>
              </div>
            </div>
          </div>
        ) : null}

        <div className="table-responsive">
          {props.applicant_data.employment ? (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Designation</th>
                  <th>Employer</th>
                  <th>StartDate</th>
                  <th>EndDate</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {props.applicant_data.employment.map((item, index) => (
                  <tr key={index}>
                    <td>{item.Designation}</td>
                    <td>{item.Employer}</td>
                    <td>
                      {formatDateAndTime(item.StartDate.split("T")[0], "date")}
                    </td>
                    <td>
                      {item.EndDate !== null
                        ? formatDateAndTime(item.EndDate.split("T")[0], "date")
                        : ""}
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => deleteItem(item.EntryID)}
                      >
                        <i
                          className="fa fa-trash-o small"
                          style={{ fontsize: "30px" }}
                        ></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-info">
              There is no record. Click on Add Employment
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <button
              className="default-btn btn w-100 active"
              onClick={onPrevious}
            >
              Previous
            </button>
          </div>
          <div className="col-lg-6 col-sm-6">
            <button className="default-btn btn w-100 active" onClick={onNext}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PGApplicationEmployment;
