import axios from "axios";
import swal from "sweetalert";
const status = "Live";

export const serverLink = status === "Dev"
  ? "http://localhost:4480/"
  : "https://smartsourcingapi.com:5004/";

export const projectName = "Olivia University Burundi";
export const projectCode = "OLIVIA_UNIVERSITY";
export const serverStatus = status;
export const projectURL = "https://oliviauniversity.edu.bi/";
export const projectPhone = "+25767800004";
export const projectEmail = "info@oliviauniversity.edu.bi";
export const projectAddress =
  "Olivia University Bujumbura, № 4&5, Mayugi, Avenue, Mukaza, Bujumbura-Burundi";
export const projectStudentURL = "https://student.oliviauniversity.edu.bi";
export const projectStaffURL = "https://staff.oliviauniversity.edu.bi";
export const projectLogo = `${projectURL}logo.png`;
export const projectShortCode = "OUB"
export const simpleFileUploadAPIKey = "305e0ddf925a3fc5dd34d314f8230349";


let domain = "";
switch (projectCode) {
  case "OLIVIA_UNIVERSITY":
    domain = "@oliviauniversity.edu.bi";
    break;
  case "BABA_AHMED_UNIVERSITY":
    domain = "@babaahmeduniversity.edu.ng";
    break;
  case "ALANSAR_UNIVERSITY":
    domain = "@aum.edu.ng";
    break;
  default:
    domain = "";
}
export const projectDomain = domain;


export function showAlert(title, msg, type){
  return swal({
      title: title,
      text: msg,
      icon: type,
      button: "OK",
  })
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

// SOCIAL MEDIA
export const projectInstagram = "https://instagram.com/oliviauniversitybujumbura?igshid=YmMyMTA2M2Y=";
export const projectLinkedIn = "https://linkedin.com/?lang=en";
export const projectTwitter = " https://twitter.com/search?q=%23OliviaUniversity&src=typed_query";
export const projectFacebook = "https://www.facebook.com/Olivia-University-Bujumbura-103773839028110/";
export const projectYoutube = "https://www.youtube.com/user/oliviauniversity";

export const formatDateAndTime = (date, option) => {
  if (date !== null) {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "date")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();

    return date_string;
  } else {
    return "--";
  }
};

export const formatDate = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};


export const sendEmail = (email, subject, title, name, body, signature, app_id) => {
  const sendEmail = {
    logo: projectLogo,
    from: projectEmail,
    to: email,
    subject: subject,
    title: title,
    name: name,
    body: body,
    signature: signature,
    app_id: app_id
  };
  axios.post(`${serverLink}send_email/send`, sendEmail).then((r) => {
    console.log("email sent");
  });

  return "sent";
};

export const currencyConverter = (amount, country=false) => {
  const east_african_countries = ['Tanzania', 'Kenya', 'Uganda', 'Rwanda', 'Burundi', 'Democratic', 'Congo', 'Sudan'];
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: country ? east_african_countries.includes(country) ? 'BIF' : 'USD' : 'USD',
  });
  return formatter.format(amount);
};

export const visionStatement =
  "At Olivia University Bujumbura, we envision a globally competitive university driven by passion for excellence in Science, Technology, Innovation, Entrepreneurship, and Leadership."
export const missionStatement =
  "To position the University as an international citadel of learning attracting staff and students from all parts of Africa and beyond through quality education and research."
export const historyStatement = `The historical background for the establishment of OUB finds expression within the context of the founder's existential reality. Sir Paul Chukwuma, the founder and Pro-chancellor of OUB is fortunate to have been born into a strong Catholic family.`;



export const EmailTemplates = (type, details) => {
  if (type === '1') {
    const message = {
      subject: 'Password Recovery',
      title: '',
      body: `<div style="line-height: 1.6">Your job application portal password is ${details.Password} <br/><br/>For further enquiries, please contact <b/>${projectEmail}<br/>${projectPhone}</div>`
    }
    return message;
  }
  else if (type === '2') {
    const message = {
      subject: 'Admission',
      title: 'Password Recovery',
      body: `<div style="line-height: 1.6">
      You have requested to reset your password, kindly follow this <a href='${projectURL}admission/application/reset-password/${details}'>link</a> to reset your password.
       <br/><br/>For further enquiries, please contact <b/>${projectEmail}<br/>${projectPhone}
      </div>`
    }
    return message;
  }
};

export const AdmissionSuccessfulEmailTemplate = (course_name) => {
  return `I am delighted to inform you that you have been admitted to Olivia University Bujumbura (OUB), Burundi; to pursue an undergraduate degree in ${course_name}.<br/>
            You will be required to provide a signed and dated Undertaking as part of your acceptance letter for your admission to OUB.<br/>
            We look forward to your joining the OUB Family and having an exciting and illustrious educational experience at OUB!<br/>
            Best wishes,&nbsp;<br/>
            Registrar,<br/>
            Olivia University<br/>
            Bujumbura (OUB),<br/>`;
};
