import React from "react";
import { Link } from "react-router-dom";

function PGApplicationSidebar(props) {
  return (
    <>
      <div className="additional-information">
        <h3>Welcome, {props.userData.FirstName}!</h3>
        <div className="list">
          <ul>
            <li
              className={
                props.activePage.dashboard === "active"
                  ? "default-btn bg-secondary"
                  : ""
              }
            >
              <Link to="#">
                <span
                  className={
                    props.activePage.dashboard === "active" ? "text-white" : ""
                  }
                >
                  Dashboard
                </span>
              </Link>
            </li>
            <li
              className={
                props.activePage.personal_info === "active"
                  ? "default-btn bg-secondary"
                  : ""
              }
            >
              <Link to="#">
                <span
                  className={
                    props.activePage.personal_info === "active"
                      ? "text-white"
                      : ""
                  }
                >
                  Personal Information
                </span>
                <span className="fa fa-check text-success" />
              </Link>
            </li>
            <li
              className={
                props.activePage.qualification === "active"
                  ? "default-btn bg-secondary"
                  : ""
              }
            >
              <Link to="#">
                <span
                  className={
                    props.activePage.qualification === "active"
                      ? "text-white"
                      : ""
                  }
                >
                  Qualification
                </span>
                <span
                  className={`fa ${
                    props.applicationDetail.qualification.length > 0
                      ? "fa-check text-success"
                      : "fa-times text-danger"
                  }`}
                />
              </Link>
            </li>
            <li
              className={
                props.activePage.employment === "active"
                  ? "default-btn bg-secondary"
                  : ""
              }
            >
              <Link to="#">
                <span
                  className={
                    props.activePage.employment === "active" ? "text-white" : ""
                  }
                >
                  Employment
                </span>
                <span
                  className={`fa ${
                    props.applicationDetail.employment.length > 0
                      ? "fa-check text-success"
                      : "fa-times text-danger"
                  }`}
                />
              </Link>
            </li>
            <li
              className={
                props.activePage.document === "active"
                  ? "default-btn bg-secondary"
                  : ""
              }
            >
              <Link to="#">
                <span
                  className={
                    props.activePage.document === "active" ? "text-white" : ""
                  }
                >
                  Document
                </span>
                <span
                  className={`fa ${
                    props.doc_required
                      ? "fa-check text-success"
                      : "fa-times text-danger"
                  }`}
                />
              </Link>
            </li>
            <li
              className={
                props.activePage.preview === "active"
                  ? "default-btn bg-secondary"
                  : ""
              }
            >
              <Link to="#">
                <span
                  className={
                    props.activePage.preview === "active" ? "text-white" : ""
                  }
                >
                  Preview
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-lg-12 col-sm-12">
          <button
            className="default-btn btn w-100"
            onClick={props.handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
    </>
  );
}

export default PGApplicationSidebar;
