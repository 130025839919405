import React from "react";
import { Link } from "react-router-dom";
import { projectShortCode, serverLink } from "../../../../resources/url";

export default function NewsContent(props) {
  const topClicked = async (item) => {
    const newsData = { ...item };
    await props.setGeneralDetails(newsData);
  };
  const bottomClicked = async (item) => {
    const newsData = { ...item };
    props.setGeneralDetails(newsData);
  };
  return (
    <div className="col-lg-8">
      {props.newsList.length > 0 ? (
        <div className="latest-news-left-content pr-20">
          <div
            className="latest-news-simple-card"
            style={{
              backgroundImage: props.newsList[0].ImagePath !== null ? props.newsList[0].ImagePath.includes("simplefileupload") ? `url(${ props.newsList[0].ImagePath })` :   `url(${serverLink}public/uploads/${projectShortCode}/news_uploads/${props.newsList[0].ImagePath })` : `url("")`,
            }}
          >
            <div className="news-content">
              <div className="list">
                <ul>
                  <li>
                    <i className="flaticon-user" />
                    By {props.newsList[0].InsertedBy}
                  </li>
                  <li>
                    <i className="flaticon-tag" />
                    {props.newsList[0].FacultyCode}
                  </li>
                </ul>
              </div>
              <Link to={`/news/${props.newsList[0].Slug}`}>
                <h3 onClick={() => topClicked(props.newsList[0])}>
                  {props.newsList[0].Title}
                </h3>
              </Link>
              <Link
                to={`/news/${props.newsList[0].Slug}`}
                className="read-more-btn active"
              >
                {" "}
                <span onClick={() => topClicked(props.newsList[0])}>
                  Read More
                  <i className="flaticon-next" />
                </span>
              </Link>
            </div>
          </div>
          {props.newsList.length > 1 ? (
            <div className="latest-news-card-area">
              <h3>Latest News</h3>
              <div className="row">
                {props.newsList.slice(1).map((item, key) => {
                  return (
                    <div className="col-lg-6 col-md-6" key={key}>
                      <div className="single-news-card">
                        <div
                          className="news-img"
                          style={{ maxHeight: "150px" }}
                        >
                          <Link to={`/news/${item.Slug}`}>
                            <img
                              src={item.ImagePath !== null ? item.ImagePath.includes("simplefileupload") ? item.ImagePath :  `${serverLink}public/uploads/${projectShortCode}/news_uploads/${item.ImagePath}` : ""}
                              onClick={() => bottomClicked(item)}
                              alt="Image"
                            />
                          </Link>
                        </div>
                        <div className="news-content">
                          <div className="list">
                            <ul>
                              <li>
                                <i className="flaticon-user" />
                                By {item.InsertedBy}
                              </li>
                              <li>
                                <i className="flaticon-tag" />
                                {item.FacultyCode}
                              </li>
                            </ul>
                          </div>
                          <Link to={`/news/${item.Slug}`}>
                            <h3 onClick={() => bottomClicked(item)}>
                              {item.Title}
                            </h3>
                          </Link>
                          <Link
                            to={`/news/${item.Slug}`}
                            className="read-more-btn"
                          >
                            <span onClick={() => bottomClicked(item)}>
                              {" "}
                              Read More
                              <i className="flaticon-next" />{" "}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        "No News Found!"
      )}
    </div>
  );
}
