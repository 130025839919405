import React from "react";
import SkyView from "../../../images/general/university_sky_view.png";
import { Link } from "react-router-dom";

export default function HomepageSectionAdmissionArea() {
  return (
    <div className="admisssion-area ptb-100">
      <div className="container">
        <div className="admission-content">
          <div className="section-title">
            <h2>Our Campus</h2>
          </div>
          <div className="admission-image">
            <img src={SkyView} alt={"Sky View of the University"} />
            <div className="icon">
              <a
                className="popup-youtube play-btn"
                href="https://youtu.be/EA_yE7WHNFs"
              >
                <i className="ri-play-fill" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
