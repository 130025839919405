import axios from "axios";
import { useState } from "react";
import { serverLink } from "../../resources/url";

const ContactForm = () => {
  const [status, setStatus] = useState("Submit");
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = event.target.elements;
    let details = {
      name: name.value,
      email_from: email.value,
      message: message.value,
    };
    const result = await axios.post(`${serverLink}contact/send_email`, details);
    setStatus("Message Sent");
  };

  return (
    <div className="contacts-form">
      <h3>For Enquiries Leave a message</h3>
      <form id="contactForm" noValidate onSubmit={handleFormSubmit}>
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="form-group has-error">
              <label>Your name</label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                required=""
                data-error="Please enter your name"
              />
              <div className="help-block with-errors">
                <ul className="list-unstyled">
                  <li>Please enter your name</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="form-group has-error">
              <label>Your email</label>
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                required=""
                data-error="Please enter your email"
              />
              <div className="help-block with-errors">
                <ul className="list-unstyled">
                  <li>Please enter your email</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group has-error">
              <label>Your message</label>
              <textarea
                name="message"
                className="form-control"
                id="message"
                cols="30"
                rows="8"
                required=""
                data-error="Write your message"
              ></textarea>
              <div className="help-block with-errors">
                <ul className="list-unstyled">
                  <li>Write your message</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <button type="submit" className="default-btn disabled">
              <span>{status}</span>
            </button>
            <div id="msgSubmit" className="h3 text-center hidden"></div>
            <div className="clearfix"></div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
