import React, { useEffect, useState } from "react";
import Loader from "../common/loader/loader";
import { connect } from "react-redux";
import {
  setCourseListDetails,
  setGeneralDetails,
} from "../../actions/setactiondetails";
import axios from "axios";
import { projectName, serverLink } from "../../resources/url";
import PageTitle from "../common/pagetitle/pagetitle";
import PageName from "../common/pagename/pagename";
import JobCards from "./job-cards";
import JobListingSide from "./jobs-side";
import { result } from "lodash";

function JobOpenings(props) {
  const [loading, setLoading] = useState(true);
  const [jobList, setJobList] = useState([]);
  const [jobList2, setJobList2] = useState([]);
  const [filter, setFilter] = useState([]);
  const [byFaculty, setByFaculty] = useState([]);
  const [FacultyList, setFacultyList] = useState([]);

  const setJobsByCategories = (e) => {
    setJobList(filter.filter((x) => x.Faculty.includes(e)));
  };
  const setAllJobs = () => {
    setJobList(jobList2);
  };

  const SearchJob = (e) => {
    const filtered = filter.filter(
      (item) =>
        item.Faculty.toLowerCase().includes(e.toLowerCase()) ||
        item.Position.toLowerCase().includes(e.toLowerCase())
    );
    setJobList(filtered);
  };

  const getData = async () => {
    await axios
      .get(`${serverLink}jobs/job-openings/list`)
      .then((result) => {
        const data = result.data;
        setFilter(data);
        setJobList(data);
        setJobList2(data);
      })
      .catch((error) => {
        console.log("error", error);
      });

    await axios
      .get(`${serverLink}jobs/job-openings/by_faculty/list`)
      .then((result) => {
        if (result.data.length > 0) {
          setByFaculty(result.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });

    await axios
      .get(`${serverLink}jobs/faculty/list`)
      .then((result) => {
        if (result.data.length > 0) {
          setFacultyList(result.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageName page={"Job Openings"} />
          <PageTitle
            title={"Job Openings"}
            breadCrumbs={["Resources", "career"]}
          />
          <div className="latest-news-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <JobCards jobList={jobList} />

                <JobListingSide
                  jobList={jobList}
                  jobList2={jobList2}
                  SearchJob={SearchJob}
                  byFaculty={byFaculty}
                  setJobsByCategories={setJobsByCategories}
                  setAllJobs={setAllJobs}
                  FacultyList={FacultyList}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    courseList: state.CourseListDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
    setOnCourseDetails: (p) => {
      dispatch(setCourseListDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(JobOpenings);
