import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PageTitle from "../../common/pagetitle/pagetitle";
import SideSearch from "./section/sidebar";
import NewsDetailsContent from "./section/newsdetailscontent";
import {
  setGeneralDetails, setNewsDetails,
  setPublicationDetails,
} from "../../../actions/setactiondetails";
import EventSidebar from "../event/section/eventsidebar";
import PageName from "../../common/pagename/pagename";
import axios from "axios";
import {isEmptyObject, serverLink} from "../../../resources/url";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../common/loader/loader";

function NewsDetails(props) {
  const navigate = useNavigate();
  const { slug } = useParams();

  if (slug === "") navigate("/");

  const [loading, setLoading] = useState(isEmptyObject(props.newsData));
  const [newsData, setNewsData] = useState(
      props.newsList.length < 1
          ? {}
          : props.newsList.filter((e) =>
              e.Slug.toLowerCase().includes(slug.toLowerCase())
          )[0] );
  const [newsList, setNewsList] = useState(props.newsList);
  const [change, setChange] = useState("");

  const getData = async () => {
    await axios
        .get(`${serverLink}news/list/all/General`)
        .then((result) => {
          const data = result.data.filter(e=>e.Status.toString() === "1");
          if (data.length > 0){
            setNewsData(data.filter((e) => e.Slug.toLowerCase().includes(slug.toLowerCase()))[0])
            props.setOnGeneralDetails(data.filter((e) => e.Slug.toLowerCase().includes(slug.toLowerCase()))[0]);
          }else{
            navigate("/event")
          }
          setLoading(false);
          setNewsList(data);
          props.setOnNewsDetails(data);
        })
        .catch((error) => {
          console.log("error", error);
        });
  };

  useEffect(() => {
    if (slug === ""){
      navigate("/event")
    }
    getData();
  }, [change]);
  const changeScreen = (items) => {
    setChange(items);
  };

  return (loading ? (
              <Loader />
          ) :
    <>
      <PageName page={newsData.Title} />
      <PageTitle
        title={"News Details"}
        breadCrumbs={["Resources", "News", "News Details"]}
      />
      <div className="news-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <NewsDetailsContent newsData={newsData} IsNews={props.IsNews} />
            {props.IsNews === true ? (
              <SideSearch
                newsList={newsList}
                setGeneralDetails={props.setOnGeneralDetails}
                channgeScreen={changeScreen}
              />
            ) : (
              <EventSidebar
                eventList={newsList}
                setGeneralDetails={props.setOnGeneralDetails}
                channgeScreen={changeScreen}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    newsData: state.GeneralDetails,
    newsList: state.NewsDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
    setOnNewsDetails: (p) => {
      dispatch(setNewsDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);
