import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function CourseFinderSide(props) {
  const app_type = [];
  if (props.courseList.length > 0) {
    props.courseList.map((course) => {
      app_type.push(course.ApplicationType);
    });
  }
  const unique_app_type = app_type.filter((v, i, a) => a.indexOf(v) === i);

  return (
    <div className="col-lg-4">
      <div className="courses-right-content pl-20">
        <div className="serch-content">
          <h3>Search</h3>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              onChange={props.onCourseChange}
              placeholder="Find Your Course"
            />
            <button type="submit" className="src-btn">
              <i className="flaticon-search" />
            </button>
          </div>
        </div>

        {unique_app_type.length > 1 && (
          <div className="academics-list">
            <h3>Application Type</h3>
            <ul>
              {unique_app_type.length > 0 &&
                unique_app_type.map((app, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => props.handleOnApplicationTypeClick(app)}
                    >
                      <Link to={""} className="text-capitalize">
                        {app} (
                        {
                          props.courseList.filter(
                            (item) => item.ApplicationType === app
                          ).length
                        }
                        )
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}

        <div className="academics-list">
          <h3>Faculties</h3>
          <ul>
            {props.facultyList.length > 0 &&
              props.facultyList.map((faculty, index) => {
                return (
                  <li
                    key={index}
                    onClick={() =>
                      props.handleOnFacultyClick(faculty.FacultyCode)
                    }
                  >
                    <Link to={""}>
                      {faculty.FacultyName} (
                      {
                        props.courseList.filter(
                          (item) => item.FacultyCode === faculty.FacultyCode
                        ).length
                      }
                      )
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>

        <div className="category-content">
          <div className="top-content text-center">
            <i
              className="flaticon-college-graduation"
              style={{ fontSize: "40px" }}
            />
            <h3 style={{ fontSize: "26px" }}>Find Course</h3>
          </div>
          <select
            name=""
            id=""
            className="form-control"
            onChange={props.onCourseChange}
          >
            <option value="">Select Course</option>
            <option value="">All Courses</option>
            {props.courseList.length > 0 &&
              props.courseList.map((course, index) => {
                return (
                  <option key={index} value={course.Slug}>
                    {course.CourseName}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    facultyList: state.FacultyListDetails,
    courseList: state.CourseListDetails,
  };
};

export default connect(mapStateToProps, null)(CourseFinderSide);
