import React from "react";
import { Link } from "react-router-dom";
import paul from "../../../images/general/Olivia/vc-image.jpeg";

function SectionWelcomeMessage() {
    return (
        <div className="campus-information-area pb-70 bg-f4f6f9">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 text-center">
                        <div className="campus-image pl-20">
                            <img src={paul} alt={"VC"} />
                        </div>
                        <h3>Rev Fr Prof Obi J. Oguejiofor</h3>
                        <small>( Vice Chancellor)</small>
                    </div>
                    <div className="col-lg-8">
                        <div className="campus-content pr-20">
                            <div className="campus-title">
                                <h3>A WORD OF WELCOME!</h3>
                                <hr style={{ width: '100px', border: '1px solid darkblue' }} />
                                <p style={{ textAlign: "justify" }}>
                                    You are heartily welcome to Olivia University in Bujumbura. Olivia University is not just any university in Africa, but one that is full of promises as it is poised to be a trailblazer among other universities in Africa and beyond. Olivia University is therefore programmed for excellence in tertiary education in sub-Saharan Africa and by so doing aligns itself with international best practices in higher education. You are very welcome as students to be part of the history that Olivia University is making by providing meaningful and exceptional contributions to the advancement of learning. You are highly welcome to make optimal use of the opportunities offered by the University to its young students which include the provision of adequate and up to date facilities that are invaluable for effective tertiary education in the modern world. The University has supreme trust in your ability to imbibe the quality of learning and research ability which it impacts on its students. It creates a learning atmosphere that enables its students to optimize the positive learning factors at their disposal.
                                </p>
                            </div>
                            <Link to={"/welcome-message"} className="default-btn btn">
                                Read More <i className="flaticon-next" />
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default SectionWelcomeMessage;
