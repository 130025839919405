import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { projectShortCode, serverLink, simpleFileUploadAPIKey } from "../../../../resources/url";
import {
  setApplicationLoginDetails,
  setGeneralDetails,
  setPGApplicationRequirementListDetails,
} from "../../../../actions/setactiondetails";
// import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import SimpleFileUpload from "react-simple-file-upload";

function DocumentsUpload(props) {
  const navigate = useNavigate();
  const [userData] = useState(props.applicationLogin);
  const [addDocument, setAddDocument] = useState(false);
  const addForm = () => {
    setAddDocument(true);
    setRegister({
      ApplicationID: props.applicationData.EntryID,
      DocumentType: "",
      file: "",
    });
  };
  const [register, setRegister] = useState({
    ApplicationID: props.applicationData.EntryID,
    DocumentType: "",
    file: "",
  });

  const allApplicationData = async () => {
    await axios
      .get(
        `${serverLink}application/ug/application/data/${props.applicationData.EntryID}`
      )
      .then((response) => {
        props.setOnGeneralDetails(response.data);
        // setJambData(response.data.jamb);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    for (let key in register) {
      if (register[key] === "") {
        toast.error(`${key} can't be empty`);
        return false;
      }
    }

    if (register.file.size / 1024 > 2048) {
      toast.error(`File Size Can't be more than 2MB`);
      return false;
    }

    toast.info(`Submitting... Please wait!`);

    let formData = new FormData();
    formData.append("file", register.file);

    const sendData = {
      ApplicationID: register.ApplicationID,
      DocumentType: register.DocumentType,
      FileName: register.file,
    };
    axios
        .post(`${serverLink}application/upload/pg/document`, sendData)
        .then((res) => {
          if (res.data.message === "success") {
            allApplicationData();
            toast.success(`Document Uploaded`);
            setAddDocument(false);
          } else {
            toast.error(`Something went wrong submitting your document!`);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });

    // await axios
    //   .post(`${serverLink}application/uploadDocument`, formData)
    //   .then((res) => {
    //     if (res.data.type === "success") {
    //             allApplicationData();
    //             toast.success(`Document Uploaded`);
    //             setAddDocument(false);
    //
    //     } else {
    //       console.log("error", res);
    //       toast.error(
    //         `Something went wrong uploading your document. Please try again!`
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("NETWORK ERROR", error);
    //   });
  };

  const onEdit = (e) => {
    const id = e.target.id;
    const value = id === "file" ? e.target.files[0] : e.target.value;

    setRegister({
      ...register,
      [id]: value,
    });
  };

  const onPrevious = () => {
    navigate("/admission/application/undergraduate/guardian-information");
  };

  const onNext = () => {
    navigate("/admission/application/undergraduate/document-preview");
  };

  const deleteItem = async (id, image) => {
    if (id) {
      toast.info(`Deleting... Please wait!`);
      await axios
        .delete(`${serverLink}application/pg/document/delete/${id}/${image}`)
        .then((res) => {
          if (res.data.message === "success") {
            allApplicationData();
            toast.success(`Deleted`);
          } else {
            toast.error(
              `Something went wrong. Please check your connection and try again!`
            );
          }
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    }
  };

  const handleFile = (url) => {
    setRegister({
      ...register,
      file: url,
    })
  }


  return (
    <div className="graduate-admission pb-70">
      <div className="container">
        <div className="row">
          <Sidebar userData={userData} />
          <div className="col-9">
            <div className="register">
              <h3>Supporting Documents</h3>
              {addDocument ? (
                <div style={{ float: "right" }}>
                  <button
                    className="default-btn btn w-100 active"
                    onClick={onSubmit}
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div style={{ float: "right" }}>
                  <button
                    className="default-btn btn w-100 active"
                    onClick={addForm}
                  >
                    Add Document
                  </button>
                </div>
              )}
              <br />
              <br />
              <br />
              <hr />

              {addDocument ? (
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="DocumentType">Document Type</label>
                      <select
                        id="DocumentType"
                        name="DocumentType"
                        className="form-control"
                        onChange={onEdit}
                      >
                        <option value="">Select Option</option>
                        {props.appRequirement ? (
                          <>
                            {props.appRequirement.map((item, index) => {
                              return (
                                <option key={index} value={item.DocumentName}>
                                  {item.DocumentName}
                                </option>
                              );
                            })}
                          </>
                        ) : (
                          ""
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      {
                        register.DocumentType !== '' ?
                            <SimpleFileUpload
                                apiKey={simpleFileUploadAPIKey}
                                tag="olivia-application"
                                onSuccess={handleFile}
                                preview="false"
                                width="100%"
                                height="100"
                            /> : <div className="pt-5 ">Select Document Type to Upload</div>
                      }
                    </div>
                  </div>
                </div>
              ) : null}

              <h4>Required Documents</h4>
              <ul>
                {props.appRequirement && (
                    <>
                      {props.appRequirement.map((item, index) => {
                        if (item.IsRequired === 1) {
                          return <li key={index}>{item.DocumentName}</li>;
                        }
                      })}
                    </>
                )}
              </ul>
              <div className="table-responsive">
                {props.applicant_data.documents.length > 0 ? (
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Document Type</th>
                        <th>File Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.applicant_data.documents.map((item, index) => (
                        <tr key={index}>
                          <td>{item.DocumentType}</td>
                          <td>
                            <a
                              target="_blank"
                              referrerPolicy="no-referrer"
                              href={item.FileName !== null ? item.FileName.includes("simplefileupload") ? item.FileName : `${serverLink}public/uploads/${projectShortCode}/application/document/${item.FileName}`: ""}
                            >
                              <i className="fa fa-file-pdf-o" />
                            </a>
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() =>
                                deleteItem(item.EntryID, item.FileName)
                              }
                            >
                              <i
                                className="fa fa-trash-o small"
                                style={{ fontsize: "30px" }}
                              ></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="alert alert-info">
                    There is no record. Click on Add Document
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                  <button
                    className="default-btn btn w-100 active"
                    onClick={onPrevious}
                  >
                    Previous
                  </button>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <button
                    className="default-btn btn w-100 active"
                    onClick={onNext}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    applicationLogin: state.ApplicationLoginDetails,
    applicant_data: state.GeneralDetails,
    appRequirement: state.ApplicationRequirementDetails,
    applicationData: state.ApplicationDataDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsUpload);
