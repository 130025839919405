import {
  projectAddress,
  projectEmail,
  projectFacebook,
  projectInstagram, projectLinkedIn,
  projectPhone,
  projectTwitter
} from "../../resources/url";

const ContactAddress = () => {
  return (
    <div className="contact-and-address">
      <div className="contact-and-address-content">
        <div className="row">
          <div className="col  ">
            <div className="contact-card">
              <div className="icon">
                <i className="ri-phone-line"></i>
              </div>
              <h4>Contact</h4>
              <p>
                Mobile: <a href={projectPhone}>{projectPhone}</a>
              </p>
              <p>
                Inquiries:{" "}
                <a href={`mailto:${projectEmail}`}>
                  {projectEmail}
                </a>
              </p>
            </div>
          </div>
          <div className="col-12">
            <div className="contact-card">
              <div className="icon">
                <i className="ri-map-pin-line"></i>
              </div>
              <h4>Address</h4>
              <p>
                {" "}
                {projectAddress}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="social-media">
        <h3>Social Media</h3>
        <ul>
          <li>
            <a href={projectFacebook} rel="noreferrer" target="_blank">
              <i className="flaticon-facebook"></i>
            </a>
          </li>
          <li>
            <a href={projectTwitter} rel="noreferrer" target="_blank">
              <i className="flaticon-twitter"></i>
            </a>
          </li>
          <li>
            <a
              href={projectInstagram}
              rel="noreferrer"
              target="_blank"
            >
              <i className="flaticon-instagram"></i>
            </a>
          </li>
          <li>
            <a
              href={projectLinkedIn}
              rel="noreferrer"
              target="_blank"
            >
              <i className="flaticon-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactAddress;
