import React, { useEffect, useState } from "react";
import PageTitle from "../../../common/pagetitle/pagetitle";
import { serverLink } from "../../../../resources/url";
import { toast } from "react-toastify";
import CountryList from "../../../../resources/country.json";
import StateData from "../../../../resources/state_and_lga.json";
import axios from "axios";
import { decryptData, encryptData } from "../../../common/cryptography/cryptography";
import { setApplicationLoginDetails } from "../../../../actions/setactiondetails";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PageName from "../../../common/pagename/pagename";

function ApplicationRegistration(props) {
  const navigate = useNavigate();
  const [register, setRegister] = useState({
    FirstName: "",
    MiddleName: "",
    Surname: "",
    PhoneNumber: "",
    EmailAddress: "",
    Address: "",
    DateOfBirth: "",
    Gender: "",
    Nationality: "Burundi",
    StateOfOrigin: "",
    LGA: "",
    Religion: "",
    Occupation: "",
    Password: "",
    ConfirmPassword: "",
  });
  const [lgaList, setLGAList] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (props.applicationLogin !== "")
      navigate("/admission/application/dashboard");
  }, [props.applicationLogin]);

  const handleRegister = async (e) => {
    e.preventDefault();

    for (let key in register) {
      if (
        register.hasOwnProperty(key) &&
        key !== "Occupation" &&
        key !== "MiddleName"
      ) {
        if (register[key].trim() === "") {
          toast.error(`${key} can't be empty`);
          return false;
        }
      }
    }
    if (register.Password !== register.ConfirmPassword) {
      toast.error("Password and Confirm Password Didn't match");
      return false;
    }

    toast.info("Submitting... Please wait!");

    await axios
      .post(`${serverLink}application/registration`, register)
      .then((response) => {
        const data = response.data;
        if (data.message === "record exist")
          toast.error(
            "An applicant with the same email or phone number exist. Login instead!"
          );
        else if (data.message === "failed")
          toast.error("Something went wrong. Please try again!");
        else {
          register.EntryID = data.entry_id;
          toast.success("Registration Successful. Please wait...");
          setTimeout(() => {
            props.setOnApplicationLoginDetails(register);
          }, 2000);
        }
      })
      .catch((error) => {
        toast.error("Network error. Check your connection and try again!");
      });
  };
  const onEdit = (e) => {
    // if (e.target.id === "StateOfOrigin") {
    //   setRegister({
    //     ...register,
    //     [e.target.id]: e.target.value,
    //   });
    //   if (e.target.value !== "") {
    //     setLGAList(
    //       StateData.filter((state) => state.state === e.target.value)[0].lgas
    //     );
    //   } else {
    //     setLGAList([]);
    //   }
    // } else
      if (
      e.target.id === "Password" ||
      e.target.id === "ConfirmPassword"
    ) {
      setRegister({
        ...register,
        [e.target.id]: encryptData(e.target.value.trim()),
      });
    } else {
      setRegister({
        ...register,
        [e.target.id]: e.target.value.trim(),
      });
    }
  };


  return (
    props.applicationLogin === "" && (
      <>
        <PageName page={"Register"} />
        <PageTitle
          title={"Register"}
          breadCrumbs={["Home", "Application", "Register"]}
        />

        <div className="register-area pt-100 pb-70">
          <div className="container">
            <div className="register">
              <h3>Register</h3>
              <form onSubmit={handleRegister} autoComplete={"off"}>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label htmlFor="FirstName">First Name</label>
                      <input
                        type="text"
                        id="FirstName"
                        className="form-control"
                        placeholder="First Name*"
                        onChange={onEdit}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label htmlFor="MiddleName">Middle Name</label>
                      <input
                        type="text"
                        id="MiddleName"
                        className="form-control"
                        placeholder="Middle Name"
                        onChange={onEdit}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label htmlFor="Surname">Surname</label>
                      <input
                        type="text"
                        id="Surname"
                        className="form-control"
                        placeholder="Surname*"
                        onChange={onEdit}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="PhoneNumber">Phone Number</label>
                      <input
                        type="number"
                        id="PhoneNumber"
                        className="form-control"
                        placeholder="Phone Number*"
                        onChange={onEdit}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="EmailAddress">Email Address</label>
                      <input
                        type="email"
                        id="EmailAddress"
                        className="form-control"
                        placeholder="Email Address*"
                        onChange={onEdit}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="DateOfBirth">Date of Birth</label>
                      <input
                        type="date"
                        id="DateOfBirth"
                        className="form-control"
                        placeholder="Date of Birth*"
                        max={`${currentYear - 13}-01-01`}
                        onChange={onEdit}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="Gender">Gender</label>
                      <select
                        id="Gender"
                        className="form-control"
                        onChange={onEdit}
                      >
                        <option value="">Select Option</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="Nationality">Nationality</label>
                      <select
                        id="Nationality"
                        value={register.Nationality}
                        className="form-control"
                        onChange={onEdit}
                      >
                        <option value="">Select Option</option>
                        {CountryList.length > 0 &&
                          CountryList.map((country, index) => {
                            return (
                              <option key={index} value={country}>
                                {country}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-8">
                    <div className="form-group">
                      <label htmlFor="Address">Address</label>
                      <input
                        type="text"
                        id="Address"
                        className="form-control"
                        placeholder="Address*"
                        onChange={onEdit}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="StateOfOrigin">State of Origin</label>
                      <input
                          type="text"
                          id="StateOfOrigin"
                          className="form-control"
                          placeholder="State Of Origin*"
                          onChange={onEdit}
                      />
                      {/*<select*/}
                      {/*  id="StateOfOrigin"*/}
                      {/*  className="form-control"*/}
                      {/*  onChange={onEdit}*/}
                      {/*>*/}
                      {/*  <option value="">Select Option</option>*/}
                      {/*  {StateData.length > 0 &&*/}
                      {/*    StateData.map((state, index) => {*/}
                      {/*      return (*/}
                      {/*        <option key={index} value={state.state}>*/}
                      {/*          {state.state}*/}
                      {/*        </option>*/}
                      {/*      );*/}
                      {/*    })}*/}
                      {/*</select>*/}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="LGA">Local Government</label>
                      <input
                          type="text"
                          id="LGA"
                          className="form-control"
                          placeholder="Local Government Area*"
                          onChange={onEdit}
                      />
                      {/*<select*/}
                      {/*  id="LGA"*/}
                      {/*  className="form-control"*/}
                      {/*  onChange={onEdit}*/}
                      {/*>*/}
                      {/*  <option value="">Select Option</option>*/}
                      {/*  {lgaList.length > 0 &&*/}
                      {/*    lgaList.map((lga, index) => {*/}
                      {/*      return (*/}
                      {/*        <option key={index} value={lga}>*/}
                      {/*          {lga}*/}
                      {/*        </option>*/}
                      {/*      );*/}
                      {/*    })}*/}
                      {/*</select>*/}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="Occupation">Occupation</label>
                      <input
                        type="text"
                        id="Occupation"
                        className="form-control"
                        placeholder="Occupation"
                        onChange={onEdit}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="Religion">Religion</label>
                      <select
                        id="Religion"
                        className="form-control"
                        onChange={onEdit}
                      >
                        <option value="">Select Option</option>
                        <option value="Christianity">Christianity</option>
                        <option value="Islam">Islam</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="Password">Password</label>
                      <input
                        type="password"
                        id="Password"
                        className="form-control"
                        placeholder="Password*"
                        onChange={onEdit}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="ConfirmPassword">Confirm Password</label>
                      <input
                        type="password"
                        id="ConfirmPassword"
                        className="form-control"
                        placeholder="Confirm Password*"
                        onChange={onEdit}
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="default-btn btn w-100 active">
                  Register
                </button>
                <Link to="/admission/application/login">
                  Already registered? Login here
                </Link>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  );
}
const mapStateToProps = (state) => {
  return {
    applicationLogin: state.ApplicationLoginDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnApplicationLoginDetails: (p) => {
      dispatch(setApplicationLoginDetails(p));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationRegistration);
