import React from "react";
import {
  projectAddress,
  projectEmail,
  projectFacebook,
  projectInstagram,
  projectLinkedIn, projectLogo, projectName,
  projectPhone,
  projectTwitter,
} from "../../../resources/url";
import {Link} from "react-router-dom";

export default function SidebarModal() {
  return (
    <div
      className="sidebarModal modal right fade hideFooter"
      id="sidebarModal"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <button type="button" className="close" data-bs-dismiss="modal">
            <i className="ri-close-line"/>
          </button>
          <div className="modal-body">
            <Link to="/">
              <img src={projectLogo} className="main-logo" alt="logo"/>
              <img src={projectLogo} className="white-logo" alt="logo"/>
            </Link>
            <div className="sidebar-content">
              <h3>About Us</h3>
              <p>
                The philosophy of the {projectName} is anchored on a
                zeal for excellence, professionalism and service as core values.
              </p>
              <div className="sidebar-btn">
                <Link to="/contact" className="default-btn">
                  Let’s Talk
                </Link>
              </div>
            </div>
            <div className="sidebar-contact-info">
              <h3>Contact Information</h3>
              <ul className="info-list">
                <li>
                  <i className="ri-phone-fill"/>{" "}
                  <a href={`tel:${projectPhone}`}>{projectPhone}</a>
                </li>
                <li>
                  <i className="ri-mail-line"/>
                  <a href={`mailto:${projectEmail}`}>
                    <span
                      className="__cf_email__"
                      data-cfemail="7a121f1616153a091b140f54191517"
                    >
                      {projectEmail}
                    </span>
                  </a>
                </li>
                <li>
                  <i className="ri-map-pin-line"/> {projectAddress}
                </li>
              </ul>
            </div>
            <ul className="sidebar-social-list">
              <li>
                <a href={projectFacebook} target="_blank">
                  <i className="flaticon-facebook"/>
                </a>
              </li>
              <li>
                <a href={projectTwitter} target="_blank">
                  <i className="flaticon-twitter"/>
                </a>
              </li>
              <li>
                <a href={projectLinkedIn} target="_blank">
                  <i className="flaticon-linkedin"/>
                </a>
              </li>
              <li>
                <a href={projectInstagram} target="_blank">
                  <i className="flaticon-instagram"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
