import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../../common/loader/loader";
import { Link } from "react-router-dom";
import OfflineMessage from "../../common/message/offline";
import { projectShortCode, serverLink } from "../../../resources/url";
import PageName from "../../common/pagename/pagename";
import {
  setHRStaffDataDetails,
  setHRStaffListDetails,
} from "../../../actions/setactiondetails";
import axios from "axios";

function StaffList(props) {
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [titles, setTitles] = useState([]);
  const [designation, setDesignation] = useState([]);

  const updateStaffHitCount = async (id) => {
    await axios
        .put(`${serverLink}academic/update/staff/hit/count/${id}`)
        .then((response) => {})
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
  };

  const getHRStaffList = async () => {
    await axios
        .get(`${serverLink}academic/staff/all/`)
        .then((response) => {
          props.setOnHRStaffListDetails(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });

      await axios
          .get(`${serverLink}academic/titles/`)
          .then((response) => {
              setTitles(response.data);
              setLoading(false);
          })
          .catch((error) => {
              console.log("NETWORK ERROR", error);
          });

      await axios
          .get(`${serverLink}academic/designation/`)
          .then((response) => {
              setDesignation(response.data);
              setLoading(false);
          })
          .catch((error) => {
              console.log("NETWORK ERROR", error);
          });
  };

  const getHRStaffData = async () => {
    await axios
        .get(`${serverLink}academic/staff/data/`)
        .then((response) => {
          props.setOnHRStaffDataDetails(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
  };

  useEffect(() => {
    getHRStaffList().then((r) => {});
    getHRStaffData().then((r) => {});
    getHRStaffData().then((r) => {});

  }, []);

  return (
      <>
        {loading ? (
            <Loader />
        ) : (
            <>
              {props.hrStaffList ? (
                  <>
                    <PageName page={"Staff List"} />
                    <div className="page-banner-area bg-2">
                      <div className="container">
                        <div className="page-banner-content">
                          <h1>Staff List</h1>
                          <ul>
                            <li>
                              <Link to="/">Home</Link>
                            </li>
                            <li>Staff List</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="serch-content">
                      {/*<h3>Search</h3>*/}
                      <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Find Staff"
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        <button type="submit" className="src-btn">
                          <i className="flaticon-search" />
                        </button>
                      </div>
                    </div>
                    <div className="events-area pt-100 pb-70">
                      <div className="container">
                        <div className="row justify-content-center">
                          {props.hrStaffList
                              .filter((result) => {
                                if (query === "") {
                                  return result;
                                } else if (
                                    result.FirstName.toLowerCase().includes(
                                        query.toLowerCase()
                                    )
                                ) {
                                  return result;
                                }
                              })
                              .map((r, index) => (
                                  <div className="col-lg-3" key={index}>
                                    <div className="single-events-card style-5 ">
                                      <div className="events-image">
                                        <Link to={`/staff/${r.Slug}`}>
                                          <img
                                              onClick={() => updateStaffHitCount(r.StaffID)}
                                              width={300}
                                              height={300}
                                              src={r.Image !== null ? r.Image.includes("simplefileupload") ? r.Image :  `${serverLink}public/uploads/${projectShortCode}/hr/document/${r.Image}` : ""}
                                              alt="Image"
                                          />
                                        </Link>
                                      </div>
                                      <div className="events-content">
                                        {/*<div className="admin">*/}
                                        <p
                                            style={{
                                              textAlign: "left",
                                            }}
                                        >
                                          {" "}
                                        </p>
                                        <Link to={`/staff/${r.Slug}`}>
                                          <h6 onClick={() => updateStaffHitCount(r.StaffID)}>
                                              {titles.length > 0 &&
                                              titles.filter((i) =>
                                                          i.EntryID ===
                                                          r.TitleID
                                                  )
                                                  .map((r) => r.TitleName)} {""}

                                              {r.FirstName} {r.Surname} {r.MiddleName}
                                          </h6>
                                          <p
                                              style={{
                                                fontSize: "12px",
                                                wordWrap: "break-word",
                                              }}
                                          >
                                              {designation.length > 0 &&
                                              designation.filter((i) =>
                                                  i.EntryID ===
                                                  r.DesignationID
                                              )
                                                  .map((r) => r.DesignationName)}
                                            <br />
                                            <small className="text-decoration-underline text-success">
                                              {r.OfficialEmailAddress}
                                            </small>
                                          </p>
                                        </Link>

                                        <Link
                                            to={`/staff/${r.Slug}`}
                                            onClick={() => updateStaffHitCount(r.StaffID)}
                                        ></Link>
                                        {/*</div>*/}
                                      </div>
                                    </div>
                                  </div>
                              ))}
                        </div>
                      </div>
                    </div>
                  </>
              ) : (
                  <OfflineMessage />
              )}
            </>
        )}
      </>
  );
}

const mapStateToProps = (state) => {
  return {
    hrStaffList: state.HRStaffListDetails,
    hrStaffData: state.HRStaffDataDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHRStaffListDetails: (p) => {
      dispatch(setHRStaffListDetails(p));
    },
    setOnHRStaffDataDetails: (p) => {
      dispatch(setHRStaffDataDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffList);
