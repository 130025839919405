import React from "react";
import PageTitle from "../../common/pagetitle/pagetitle";
import PageName from "../../common/pagename/pagename";
import { connect } from "react-redux";
const Teaching = (props) => {
    return (
        <>
            <PageName page={"Teaching"} />
            <PageTitle title={"Teaching"} breadCrumbs={["Academics","Teaching"]} />
            <div className="container mt-5 mb-5">
                <div className="row align-items-center pt-10 mt-3">
                    <div className="col-lg-12">
                        <div className="campus-content pr-20">
                            <div className="campus-title">
                                <h3>What it is to us</h3>
                                <p style={{ textAlign: 'justify' }}>
                                Teaching is an extremely important part of the mandate and mission of OUB. Knowledge cannot be advanced without being effectively transmitted from one generation to the next. It is the primary reason centers of learning—primary, secondary and tertiary institutions—were invented in the first place: to provide institutions where two critical things can be done on a more or less, permanent basis: teaching and the conservation of knowledge and information. To that extent, teaching is one of the core functions and missions of OUB. We have a capable faculty dedicated to providing our students with excellent instruction, utilizing state-of-the-art pedagogical tools and techniques. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default connect(mapStateToProps, null)(Teaching);
