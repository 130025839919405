import React from "react";
import { Link } from "react-router-dom";
import PageName from "../common/pagename/pagename";

export default function NotFound() {
  return (
    <>
      <PageName page={"404 Not Found"} />
      <div className="error-area ptb-100">
        <div className="container">
          <div className="top-content">
            <ul>
              <li>4</li>
              <li>0</li>
              <li>4</li>
            </ul>
          </div>
          <h2>Error 404 : Page Not Found</h2>
          <p>
            The page you are looking for might have been removed or had its name
            changed or is temporarily unavailable.
          </p>
          <Link to={"/"} className="btn default-btn">
            Back to Homepage
          </Link>
        </div>
      </div>
    </>
  );
}
