import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopBar from "../common/navigation/topbar";
import NavigationBar from "../common/navigation/navigationbar";
import SidebarModal from "../common/navigation/sidebarmodal";
import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";
import Homepage from "../homepage/homepage";
import FacultyPage from "../academic/faculty/faculty-page";
import StaffList from "../academic/staff/staff-list";
import Education from "../academic/education/education";
import LearningGoal from "../academic/goal/learning-goal";
import UniversityRegistry from "../academic/registry/academic-registry";
import StaffDetail from "../academic/staff/staff-detail";
import About from "../about/about";
import OurCampus from "../about/our-campus";
import Board from "../about/leadership/board";
import Gallery from "../about/gallery/gallery";
import Senate from "../about/leadership/Senate";
import Contact from "../contact/Contact";
// import UniversityInPress from "../resources/universityinthepress/universityinthepress";
import News from "../resources/news/news";
import NewsDetails from "../resources/news/newsdetails";
import Event from "../resources/event/event";
import Publication from "../resources/publication/publication";
import CourseFinder from "../admission/coursefinder/coursefinder";
import CourseDetails from "../admission/coursedetail/coursedetails";
import Footer from "../common/footer/footer";
import Fees from "../admission/fees/fees";
import Tuition from "../admission/fees/tuition";
import Accommodation from "../admission/fees/accommodation";
import Feeding from "../admission/fees/feeding";
import AcademicCalender from "../academic/calendar/academic-calendar";
import ApplicationLandingPage from "../admission/application/landingpage/applicationlandingpage";
import ApplicationLogin from "../admission/application/loginpage/applicationlogin";
import ApplicationRecoverPassword from "../admission/application/loginpage/recover-password";
import ApplicationRegistration from "../admission/application/registrationpage/applicationregistration";
import ApplicationDashboard from "../admission/application/dashboard/applicationdashboard";
import NotFound from "../404notfound/404notfound";
import UndergraduateApplicationDashboard from "../admission/application/undergraduate/dashboard";
import PostgraduateApplicationDashboard from "../admission/application/postgraduate/dashboard";
import PersonalInformation from "../admission/application/undergraduate/PersonalInformation";
import Olevel from "../admission/application/undergraduate/Olevel";
import Jamb from "../admission/application/undergraduate/Jamb";
import GuardianInformation from "../admission/application/undergraduate/GuardianInformation";
import DocumentsUpload from "../admission/application/undergraduate/DocumentsUpload";
import Homepage2 from "../homepage/homepage2";
import UGApplicationPreview from "../admission/application/undergraduate/DocumentPreview";
import JobOpening from "../job-opening/job-opening";
import JobDetails from "../job-opening/job-details";
import LifeInCity from "../about/life-in-city";
import WelcomeMessage from "../about/welcome-message";
import EventDetail from "../resources/event/eventdetails";
import Research from "../academic/research/research";
import Teaching from "../academic/teaching/teaching";
import Innovation from "../academic/innovation/innovation";
import Community from "../academic/community/community";
import BriefHistory from "../about/brief-history";
import StaffEnrolment from "../job-opening/staff-enrolment/enrolment";
import PaymentReceipt from "../admission/application/payment-receipt/payment-receipt";
import NewStudentEnrolment from "../admission/new-student-enrolment/new-student-enrolment";
import OfferDashboard from "../admission/application/offer/offerdashboard";
import Visarequirements from "../admission/international-students/visa-requirements";
import Management from "../about/leadership/management";
import GovernanceStructure from "../about/governance-structure";
import WhyStudyAtOlivia from "../admission/why-study/why-study-at-olivia";

export default function PageRoutes() {
  return (
    <Routers>
      <TopBar />
      <NavigationBar />
      <SidebarModal />

      <Routes>
        <Route path={"/"} element={<Homepage2 />} />
        <Route path={"/home"} element={<Homepage />} />

        <Route path={"/login"} element={""} />

        {/*Abdulmutallib Start*/}
        <Route path="/faculty/:slug" element={<FacultyPage />} />
        <Route path="/staff-list" element={<StaffList />} />
        <Route path="/education" element={<Education />} />
        <Route path="/learning-goal" element={<LearningGoal />} />
        <Route path="/academic-registry" element={<UniversityRegistry />} />
        <Route path="/academic-calendar" element={<AcademicCalender />} />
        <Route path="/staff/:slug/" element={<StaffDetail />} />
        {/*Abdulmutallib End*/}

        {/*Abdulsalam Start*/}
        <Route path={"/about-us"} element={<About />} />
        <Route path={"/our-campus"} element={<OurCampus />} />
        <Route path={"/our-history"} element={<BriefHistory/>} />
        <Route path={"/board-members"} element={<Board />} />
        <Route path={"/gallery"} element={<Gallery />} />
        <Route path={"/senate"} element={<Senate />} />
        <Route path={"/management"} element={<Management/>} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/life-in-burundi"} element={<LifeInCity />} />
        <Route path={"/welcome-message"} element={<WelcomeMessage />} />
        <Route
          path={"/admission/application/undergraduate/personal-information/"}
          element={<PersonalInformation />}
        />
        <Route
          path={"/admission/application/undergraduate/secondary-school-result"}
          element={<Olevel />}
        />
        <Route
          path={"/admission/application/undergraduate/secondary-school-result"}
          element={<Olevel />}
        />
        <Route
          path={"/admission/application/undergraduate/jamb-result"}
          element={<Jamb />}
        />
        <Route
          path={"/admission/application/undergraduate/guardian-information"}
          element={<GuardianInformation />}
        />
        <Route
          path={"/admission/application/undergraduate/documents-uploads"}
          element={<DocumentsUpload />}
        />
        <Route
          path={"/admission/application/undergraduate/document-preview"}
          element={<UGApplicationPreview />}
        />
        {/*Abdulsalam End*/}

        {/*Adam Start*/}
        {/*UNIVERSITY IN THE PRESS ROUTE*/}
        {/*<Route path={"/press"} element={<UniversityInPress />} />*/}

        {/*NEWS ROUTE*/}
        <Route path={"/news"} element={<News />} />
        <Route path={"/news/:slug"} element={<NewsDetails IsNews={true} />} />

        {/*EVENT ROUTE*/}
        <Route path={"/event"} element={<Event />} />
        <Route path={"/event/:slug"} element={<EventDetail />} />

        {/*PUBLICATION ROUTE*/}
        <Route path={"/publication"} element={<Publication />} />
        <Route path={"/admission/course-finder"} element={<CourseFinder />} />
        <Route path={"/course/:slug"} element={<CourseDetails />} />
        <Route path={"/fees"} element={<Fees />} />
        <Route path={"/fees/tuition"} element={<Tuition />} />
        <Route path={"/accommodation"} element={<Accommodation />} />
        <Route path={"/fees/feeding"} element={<Feeding />} />
        <Route path={"/research"} element={<Research/>}  />
        <Route path={"/teaching"} element={<Teaching/>}  />
        <Route path={"/innovation"} element={<Innovation/>}  />
        <Route path={"/community"} element={<Community/>}  />
        {/*Adam End*/}

        {/*Application Start*/}

        <Route path="/admission/application/visa-requirements" element={<Visarequirements/>} />
        <Route path={"/admission/application/offer"} element={<OfferDashboard />} />
        <Route path={"/admission/application"} element={<ApplicationLandingPage />} />
        <Route path={"/admission/application/print/:slug"} element={<PaymentReceipt />} />
        <Route path={"/admission/enrolment"} element={<NewStudentEnrolment />} />
        <Route path="/admission/application/reset-password/:slug" element={<ApplicationRecoverPassword/>}/>
        <Route path={"/admission/application/print/:app_id/:app_status"} element={<PaymentReceipt />} />
        <Route path={"/admission/application/enrolment"} element={<NewStudentEnrolment />} />
        <Route
          path={"/admission/application/dashboard"}
          element={<ApplicationDashboard />}
        />
        <Route
          path={"/admission/application/login"}
          element={<ApplicationLogin />}
        />
        <Route
          path={"/admission/application/register"}
          element={<ApplicationRegistration />}
        />
        <Route
          path={"/admission/application/undergraduate"}
          element={<UndergraduateApplicationDashboard />}
        />
        <Route
          path={"/admission/application/postgraduate"}
          element={<PostgraduateApplicationDashboard />}
        />

        {/* Job-opening */}
        <Route path="/jobs" element={<JobOpening />} />
        <Route path="/jobs/details/:id" element={<JobDetails />} />
        <Route path="/enrol/:id" element={<StaffEnrolment/>} />
        <Route path={"/governance-structure"} element={<GovernanceStructure />} />
        <Route path={"/why-study-at-olivia"} element={<WhyStudyAtOlivia />} />


        {/*Application End*/}
        <Route path={"*"} element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />

      <Footer />
    </Routers>
  );
}
