import React from "react";
import PageName from "../../common/pagename/pagename";
import PageTitle from "../../common/pagetitle/pagetitle";

function WhyStudyAtOlivia() {
    return (
        <>
            <PageName page={"Why Study at Olivia University Bujumbura"} />
            <PageTitle title={"Why Study at Olivia University Bujumbura"} breadCrumbs={["Home", "Why Study at Olivia University Bujumbura"]} />
            <div className="container mt-0">
                <div className="row align-items-center">
                    <div className="col-lg-10">
                        <div className="" style={{ marginTop: '60px' }}>
                            <div className="row align-items-center pb-50">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne"
                                                aria-expanded="false"
                                                aria-controls="collapseOne"
                                            >
                                                Serene & Beautiful City
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseOne"
                                            className="accordion-collapse collapse active show"
                                            aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body text-justify">
                                                <p style={{ textAlign: "justify" }}>
                                                    •	OUB is one of the leading universities in Burundi, offering a wide range of high-quality undergraduate courses (postgraduate programs under development).<br />
                                                    •	Our faculty are experts in their fields and are committed to providing students with the knowledge and skills they need to succeed.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="collapseTwo"
                                            >
                                                Global outlook
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseTwo"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Olivia University is a truly international university, attracting students and staff from all over the world.
                                                </p>
                                                <p>
                                                    We offer a variety of programs that prepare students for global careers.
                                                </p>
                                                <p>
                                                    We also have strong partnerships with universities and businesses around the world, which gives our students opportunities to study and intern abroad.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree"
                                                aria-expanded="false"
                                                aria-controls="collapseThree"
                                            >
                                                Affordable education
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseThree"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p style={{ textAlign: "justify" }}>
                                                    •	Olivia University Bujumbura offers high-quality education at an affordable price.<br />
                                                    •	We also offer a variety of scholarships and financial aid options to help students make their education a reality.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour"
                                                aria-expanded="false"
                                                aria-controls="collapseFour"
                                            >
                                                Affordable education
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseFour"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="headingFour"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p style={{ textAlign: "justify" }}>
                                                    •	Olivia University Bujumbura offers high-quality education at an affordable price.<br />
                                                    •	We also offer a variety of scholarships and financial aid options to help students make their education a reality.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFive">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseFive"
                                                aria-expanded="false"
                                                aria-controls="collapseFive"
                                            >
                                                Student-centred approach
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseFive"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="headingFive"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p style={{ textAlign: "justify" }}>
                                                    •	At Olivia University, we believe that students come first.<br />
                                                    •	We are committed to providing our students with the support they need to succeed, both academically and personally.<br/>
                                                    •	We offer a variety of student services, including academic advising, career counselling, and financial aid assistance (full & partial scholarship).
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingSix">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseSix"
                                                aria-expanded="false"
                                                aria-controls="collapseSix"
                                            >
                                                International partnerships
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseSix"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="headingSix"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p style={{ textAlign: "justify" }}>
                                                    We have established partnerships with several international universities and organizations, providing our students with opportunities for exchange programs, research collaborations, and internships.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default WhyStudyAtOlivia;
