import React from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DattiBabaAhmed from "../../../images/general/Olivia/paul.jpg";
import { formatDateAndTime, projectShortCode, serverLink } from "../../../resources/url";
import * as DOMPurify from 'dompurify';

function SectionNewsUpdates(props) {
    const navigate = useNavigate();
    const onCourseChange = (e) => {
        e.preventDefault();
        navigate(`/course/${e.target.value}`);
    };

    return (
        <div className="podcasts-area pt-30">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className=" item-title style2">
                            <h2 className="column">News & Updates
                            </h2>
                            <div className="title-divider column" id="div_divider"/>
                        </div>
                        {props.news_list.length > 0 &&
                        props.news_list.map((news, index) => {
                            if (index < 2) {
                                return (
                                    <div key={index} className="single-podcasts-card">
                                        <div className="row align-items-center">
                                            <div className="col-lg-5 col-md-5">
                                                <div className="podcasts-image">
                                                    <img
                                                        src={news.ImagePath !== null ? news.ImagePath.includes("simplefileupload") ? news.ImagePath : `${serverLink}public/uploads/${projectShortCode}/news_uploads/${news.ImagePath}` : ""} className="img-thumbnail w-100"
                                                        alt="Image"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7">
                                                <div className="podcast-content">
                                                        <span>
                                                          {formatDateAndTime(
                                                              news.InsertedDate,
                                                              "date_and_time"
                                                          )}
                                                        </span>
                                                    <h3>{news.Title}</h3>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                DOMPurify.sanitize(news.Description.substring(0, 100)) + "...",
                                                        }}
                                                    />
                                                    <div className="play-icon">
                                                        <Link
                                                            to={`/news/${news.Slug}`}
                                                            className="popup-youtube play-btn"
                                                        >
                                                            <i className="ri-arrow-right-circle-line" />
                                                            <span>Read more</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <div className="col-lg-4">
                        <div className="subscribe-area">
                            <div className="top-content">
                                <i className="flaticon-college-graduation" />
                                <h3>Find Course</h3>
                            </div>
                            <form className="newsletter-form" data-toggle="validator">
                                <select
                                    name=""
                                    id=""
                                    className="form-control"
                                    onChange={onCourseChange}
                                >
                                    <option value="">Select Course</option>
                                    {props.courseList.length > 0 &&
                                    props.courseList.map((course, index) => {
                                        return (
                                            <option key={index} value={course.Slug}>
                                                {course.CourseName}
                                            </option>
                                        );
                                    })}
                                </select>
                            </form>
                        </div>

                        <div className="student-life-card">
                            <img src={DattiBabaAhmed} alt="Datti Baba Ahmed" width={"100%"} />
                            <div className="tags" style={{ opacity: 0.8 }}>
                                <p>
                                    Sir. Paul Chukwuma <br /> ( Pro Chancellor)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        courseList: state.CourseListDetails,
    };
};

export default connect(mapStateToProps, null)(SectionNewsUpdates);
