import React from "react";
import { Link } from "react-router-dom";
import { projectShortCode, serverLink } from "../../../../resources/url";

export default function SideSearch(props) {
  const bottomClicked = async (item) => {
    const newsData = { ...item };
    props.setGeneralDetails(newsData);
    props.channgeScreen(item);
  };
  return (
    <div className="col-lg-4">
      {props.newsList.length > 0 ? (
        <div className="related-post-area">
          <h3>Related Post</h3>
          <div className="related-post-box">
            {props.newsList
              .sort(() => Math.random() - 0.5)
              .map((item, key) => {
                return (
                  <div className="related-post-content" key={key}>
                    <Link to={`/news/${item.Slug}`}>
                      <img
                        src={item.ImagePath !== null ? item.ImagePath.includes("simplefileupload") ? item.ImagePath :  `${serverLink}public/uploads/${projectShortCode}/news_uploads/${item.ImagePath}` : ""}
                        onClick={() => bottomClicked(item)}
                        alt="Image"
                      />
                    </Link>
                    <h4>
                      <Link to={`/news/${item.Slug}`}>
                        <span onClick={() => bottomClicked(item)}>
                          {" "}
                          {item.Title}{" "}
                        </span>{" "}
                      </Link>
                    </h4>
                    <p>
                      <i className="flaticon-tag" /> {item.FacultyCode}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
