import React from "react";
import PageTitle from "../common/pagetitle/pagetitle";
import ContactAddress from "./ContactAddress";
import ContactForm from "./ContactForm";
import PageName from "../common/pagename/pagename";

function Contact() {
  return (
    <>
      <PageName page={"Contact Us"} />
      <PageTitle title={"Contact Us"} breadCrumbs={["Home", "Contact Us"]} />
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-6">{<ContactForm />}</div>
          <div className="col-lg-6">{<ContactAddress />}</div>
        </div>
      </div>
    </>
  );
}

export default Contact;
