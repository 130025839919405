import React, {useState} from "react";
import {projectShortCode, serverLink} from "../../../../resources/url";
import imgF from "../../../../images/event.jpg";
import {formatDate} from "../../../common/dateformat/dateformat";
import {connect} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";

 function EventDetailsContent(props) {

    return <div className="col-lg-8">
        <div className="news-details">
            <div className="news-simple-card">
                <img className="img-fluid img-thumbnail w-100" src={!props.eventData.ImagePath ? imgF : props.eventData.ImagePath !== null ? props.eventData.ImagePath.includes("simplefileupload") ? props.eventData.ImagePath :  `${serverLink}public/uploads/${projectShortCode}/event_uploads/${props.eventData.ImagePath}` : ""} alt="Image"/>
                <div className="list">
                    <ul>
                        <li><i className="flaticon-user"/>By {props.eventData.InsertedBy}</li>
                        <li><i className="flaticon-tag"/>{props.eventData.FacultyCode}</li>
                    </ul>
                </div>
                <h3>{props.eventData.Title}</h3>
                <span  dangerouslySetInnerHTML={{ __html: props.eventData.Description }}/>
            </div>

            <div className="tags-and-share mt-4">
                {
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6 col-md-6 mb-3">
                            <div className="tags">
                                <ul>
                                    <li><span>Event Date:</span></li>
                                    <li>{formatDate(props.eventData.EventDate)}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="share">
                                <ul>
                                    <li><span>Event Time:</span></li>
                                    <li>{props.eventData.StartTime} - {props.eventData.EndTime}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-6">
                            <div className="tags">
                                <ul>
                                    <li><span>Location:</span></li>
                                    <li>{props.eventData.Location} </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                <hr/>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="tags">
                            <ul>
                                <li><span>Tags :</span></li>
                                <li><a href="/admission/application">Admission</a></li>
                                <li><a href="">Research</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="share">
                            <ul>
                                <li><span>Share :</span></li>
                                <li>
                                    <a href="https://www.facebook.com/" target="_blank" referrerPolicy="no-referrer"><i className="flaticon-facebook"/></a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/" target="_blank" referrerPolicy="no-referrer"><i  className="flaticon-twitter"/></a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/?lang=en" target="_blank" referrerPolicy="no-referrer"><i className="flaticon-instagram"/></a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/?lang=en" target="_blank" referrerPolicy="no-referrer"><i className="flaticon-linkedin"/></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}


export default EventDetailsContent;