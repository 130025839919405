import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { Link } from "react-router-dom";
import Section from "../section/section";
import OfflineMessage from "../../common/message/offline";
import PageName from "../../common/pagename/pagename";
import axios from "axios";
import {serverLink} from "../../../resources/url";

function LearningGoal() {
  const [loading, setLoading] = useState(true);
  const [goal, setGoal] = useState([]);
  const [goalItems, setGoalItems] = useState("");


  const getAllUniversityLearningGoals = async () => {
    await axios
        .get(`${serverLink}academic/goal/all/`)
        .then((response) => {
          setGoal(response.data)
          setLoading(false);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
  };

  const getUniversityLearningGoalItems = async () => {
    await axios
        .get(`${serverLink}academic/get/goal/items`)
        .then((response) => {
          setGoalItems(response.data)
          setLoading(false);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
  };

  useEffect(() => {
    getAllUniversityLearningGoals().then(r => {});
    getUniversityLearningGoalItems().then(r => {});
  }, []);


  return (
    <>
      {loading ?
        <Loader />
       : (
        <>
          {goal.length > 0 ? (
            <>
              <PageName page={"Learning Goal"} />
              <div className="page-banner-area bg-2">
                <div className="container">
                  <div className="page-banner-content">
                    <h1>Learning Goal</h1>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>Goal</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="graduate-admission pt-100 pb-70" style={{ textAlign: "justify" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="how-to-apply">
                        {goal.length > 0 && goal.map((r, index) => (
                          <div key={index}>
                            <h3>{r.ContentTitle}</h3>
                            <p>{r.ContentText}</p>
                          </div>
                        ))}
                        <h4>Goals</h4>
                        <div className="apply-list">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <ul>
                                {goalItems.length > 0  && goalItems.map((r, index) => (
                                  <li key={index}>
                                    <i className="ri-checkbox-circle-line"></i>
                                    <strong>{r.GoalTitle}: </strong>{" "}
                                    {r.GoalDescription}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Section />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <OfflineMessage />
          )}
        </>
      )}
    </>
  );
}
export default LearningGoal;
