import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { projectShortCode, serverLink } from "../../../resources/url";
import { Link } from "react-router-dom";
import "../homepage.css";

function Slider(props) {
  return (
    <div className="banner-area">
      <OwlCarousel
        className="hero-slider2 style1 owl-carousel owl-theme"
        items={1}
        margin={10}
        nav={false}
        dots={false}
        loop={true}
        autoplay={true}
      >
        {props.slider.length > 0 &&
          props.slider.map((item, index) => {
            return (
              <div
                key={index}
                className="slider-item"
                style={{
                  backgroundImage: `url(${serverLink}public/uploads/${projectShortCode}/sliders_uploads/${item.ImagePath})`,
                }}
              >
                <div className="container-fluid">
                  <div className="slider-content style1">
                    <h1 className="bg-text ">{item.Title}</h1>
                    {/*<p>{item.Caption}</p>*/}
                    {item.BtnName !== "" && (
                      <Link
                        to={item.BtnLink}
                        className="default-btn btn"
                        style={{ backgroundColor: "#999999", color: "#fff" }}
                      >
                        {item.BtnName} <i className="flaticon-next" />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </OwlCarousel>
    </div>
  );
}

export default Slider;
