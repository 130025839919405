import React from "react";
import { Link } from "react-router-dom";

export default function CourseContent(props) {
    return (
        <div className="col-lg-8">
            <div className="courses-left-content">
                {props.courseList.length > 0 ? (
                <div className="row">
                    {props.courseList.map((item, key) => {
                        return <div className="col-lg-6 col-md-6" key={key}>
                        <div className="single-courses-card style4">
                            <div className="courses-content">
                                <Link to={`/course/${item.Slug}`}><h3>{item.CourseName}</h3></Link>
                                <div className="bottom-content">
                                    <ul className="d-flex justify-content-between">
                                        <li>
                                            <ul>
                                                <li><i className="fa fa-clock-o"/>{item.Duration} {item.DurationType}</li>
                                                <li><i className="flaticon-student-with-graduation-cap"/>{item.CourseClass}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    })}

                </div>
                    ) : (
                    <h3>No Course Found</h3>
                    )}
                {/*<div className="paginations">*/}
                {/*    <ul>*/}
                {/*        <li><a href="#"><i className="flaticon-back"/></a></li>*/}
                {/*        <li><a href="#">01</a></li>*/}
                {/*        <li><a href="#">02</a></li>*/}
                {/*        <li><a href="#">03</a></li>*/}
                {/*        <li><a href="#" className="active"><i className="flaticon-next"/></a></li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}


