import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import {
  projectAddress,
  projectName,
  projectShortCode,
  projectURL,
  sendEmail,
  serverLink,
} from "../../../../resources/url";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";

function UGApplicationPreview(props) {
  const navigate = useNavigate();
  const [canSubmit, setCanSubmit] = useState(true);
  const [requiredDoc, setRequiredDoc] = useState(false);
  const [userData] = useState(props.applicationLogin);

  useEffect(() => {
    let checker = [];
    props.appRequirement.length > 0 &&
      props.appRequirement.map((req) => {
        if (req.IsRequired === 1) {
          let check = props.applicant_data.documents.filter(
            (doc) => doc.DocumentType === req.DocumentName
          );
          if (check.length === 0 || check.length < req.MinDocument) {
            setRequiredDoc(false);
            checker += 1;
          }
        }
      });
    if (!checker) setRequiredDoc(true);
  }, []);

  const handleSubmit = async () => {
    if (props.applicationData.EntryID) {
      toast.info(`Please wait while submitting your application...`);
      await axios
        .patch(
          `${serverLink}application/pg/update/status/${props.applicationData.EntryID}`
        )
        .then((res) => {
          if (res.data.message === "success") {
            toast.success(`Application submitted successfully`);

            sendEmail(
              props.applicant_data.applicant_data[0].EmailAddress,
              `Application for ${props.applicationData.CourseName}`,
              "Application Submitted",
              `${props.applicant_data.applicant_data[0].FirstName} ${props.applicant_data.applicant_data[0].MiddleName} ${props.applicant_data.applicant_data[0].Surname}`,
              `We have received your application to OUB. We are happy that you have chosen OUB to be your university of choice. Your application will take about 5 – 8 days to process.<br /> You will receive a letter letting you know the outcome of your application.<br/>
              Again, thank you for choosing OUB as your university of choice.`,
                `Regards,<br/>Admissions Officer<br/>OUB`,
                `OUB/APP/${props.applicationData.EntryID}`
            );
            navigate("/admission/application/dashboard");
          } else {
            toast.error(
              `Something went wrong. Please check your connection and try again!`
            );
          }
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    }
  };

  let passport = props.applicant_data.documents.filter((item) =>
    item.DocumentType.includes("Passport")
  );

  if (
    props.applicant_data.oLevel.length > 0 &&
    props.applicant_data.guardian.length > 0 &&
    requiredDoc
  ) {
    // onSubmit();
    setCanSubmit(true);
  }

  const onPrevious = () => {
    navigate("/admission/application/undergraduate/documents-uploads");
  };

  let jamb_total_score = 0;

  return (
    <>
      <div className="graduate-admission pb-70">
        <div className="container">
          <div className="row">
            <Sidebar userData={userData} />
            <div className="col-9">
              {canSubmit ? (
                <>
                  <div className="pt-3">
                    <div
                      className="header"
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          padding: "12px",
                          color: "black",
                        }}
                      >
                        <h5
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          {projectName}
                        </h5>
                        <p
                          style={{
                            textAlign: "center",
                            color: "black",
                            marginButton: "50px",
                            lineHeight: "1.6",
                          }}
                        >
                          {projectAddress} <br />
                          Applicant Submission Summary
                        </p>
                      </div>
                    </div>

                    <div className="pt-4">
                      <div
                        className=""
                        style={{
                          backgroundColor: "#e0e0e0",
                          color: "black",
                          height: "40px",
                        }}
                      >
                        <h6
                          className="pt-2 p-lg-2"
                          style={{
                            color: "black",
                          }}
                        >
                          Personal Information
                        </h6>
                      </div>

                      <div className="row pt-2">
                        <div className="col-lg-3 col-sm-3">
                          <img
                            src={passport[0].FileName !== null ? passport[0].FileName.includes("simplefileupload") ? passport[0].FileName :
                              passport.length > 0
                                ? `${serverLink}public/uploads/${projectShortCode}/application/document/${passport[0].FileName}`
                                : "https://via.placeholder.com/150" : ""
                            }
                            height={150}
                            alt="Applicant Passport"
                          />
                        </div>
                        <div className="col-lg-9 col-sm-9">
                          <table className="table">
                            <tbody>
                              <tr>
                                <th scope="row">Applicant ID:</th>
                                <td>
                                  #
                                  {
                                    props.applicant_data.applicant_data[0]
                                      .EntryID
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Names</th>
                                <td>
                                  {
                                    props.applicant_data.applicant_data[0]
                                      .FirstName
                                  }{" "}
                                  {""}
                                  {
                                    props.applicant_data.applicant_data[0]
                                      .MiddleName
                                  }{" "}
                                  {""}
                                  {
                                    props.applicant_data.applicant_data[0]
                                      .Surname
                                  }{" "}
                                  {""}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Phone Number</th>
                                <td>
                                  {
                                    props.applicant_data.applicant_data[0]
                                      .PhoneNumber
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Email</th>
                                <td>
                                  {
                                    props.applicant_data.applicant_data[0]
                                      .EmailAddress
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="pt-3">
                      <div
                        className=""
                        style={{
                          backgroundColor: "#e0e0e0",
                          color: "black",
                          height: "40px",
                        }}
                      >
                        <h6
                          className="pt-2 p-lg-2"
                          style={{
                            color: "black",
                          }}
                        >
                          O'Level Results
                        </h6>
                      </div>

                      <div className="pt-4">
                        {props.applicant_data.oLevel ? (
                          <>
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">Subjects</th>
                                  <th scope="col">Scores</th>
                                </tr>
                              </thead>
                              <tbody>
                                {props.applicant_data.oLevel.length > 0 &&
                                  props.applicant_data.oLevel.map(
                                    (item, index) => (
                                      <tr key={index}>
                                        <td>{item.ExamsSubject}</td>
                                        <td>{item.Score}</td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <>
                            <div className="alert alert-info">
                              There is no record.
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    {/*<div className="pt-3">*/}
                    {/*  <div*/}
                    {/*    className=""*/}
                    {/*    style={{*/}
                    {/*      backgroundColor: "#e0e0e0",*/}
                    {/*      color: "black",*/}
                    {/*      height: "40px",*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    <h6*/}
                    {/*      className="pt-2 p-lg-2"*/}
                    {/*      style={{*/}
                    {/*        color: "black",*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      Jamb Results*/}
                    {/*    </h6>*/}
                    {/*  </div>*/}

                    {/*  <div className="pt-4">*/}
                    {/*    {props.applicant_data.jamb ? (*/}
                    {/*      <>*/}
                    {/*        <table className="table table-striped">*/}
                    {/*          <thead>*/}
                    {/*            <tr>*/}
                    {/*              <th scope="col">Subjects</th>*/}
                    {/*              <th scope="col">Scores</th>*/}
                    {/*            </tr>*/}
                    {/*          </thead>*/}
                    {/*          <tbody>*/}
                    {/*            {props.applicant_data.jamb.length > 0 &&*/}
                    {/*              props.applicant_data.jamb.map(*/}
                    {/*                (item, index) => {*/}
                    {/*                  jamb_total_score += item.SubjectScore;*/}
                    {/*                  return (*/}
                    {/*                    <tr key={index}>*/}
                    {/*                      <td>{item.SubjectName}</td>*/}
                    {/*                      <td>{item.SubjectScore}</td>*/}
                    {/*                    </tr>*/}
                    {/*                  );*/}
                    {/*                }*/}
                    {/*              )}*/}
                    {/*          </tbody>*/}
                    {/*          <tfoot>*/}
                    {/*            <tr>*/}
                    {/*              <th>Total</th>*/}
                    {/*              <th>{jamb_total_score}</th>*/}
                    {/*            </tr>*/}
                    {/*          </tfoot>*/}
                    {/*        </table>*/}
                    {/*      </>*/}
                    {/*    ) : (*/}
                    {/*      <>*/}
                    {/*        <div className="alert alert-info">*/}
                    {/*          There is no record.*/}
                    {/*        </div>*/}
                    {/*      </>*/}
                    {/*    )}*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    <div className="pt-3">
                      <div
                        className=""
                        style={{
                          backgroundColor: "#e0e0e0",
                          color: "black",
                          height: "40px",
                        }}
                      >
                        <h6
                          className="pt-2 p-lg-2"
                          style={{
                            color: "black",
                          }}
                        >
                          Guardian Information
                        </h6>
                      </div>

                      <div className="pt-4">
                        {props.applicant_data.guardian ? (
                          <>
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">Name</th>
                                  <td scope="col">
                                    {props.applicant_data.guardian[0].FirstName}{" "}
                                    {
                                      props.applicant_data.guardian[0]
                                        .MiddleName
                                    }{" "}
                                    {props.applicant_data.guardian[0].Surname}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="col">Email Address</th>
                                  <td scope="col">
                                    {
                                      props.applicant_data.guardian[0]
                                        .PhoneNumber
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="col">Phone Number</th>
                                  <td scope="col">
                                    {
                                      props.applicant_data.guardian[0]
                                        .EmailAddress
                                    }
                                  </td>
                                </tr>
                              </thead>
                            </table>
                          </>
                        ) : (
                          <>
                            <div className="alert alert-info">
                              There is no record.
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="pt-3">
                      {props.applicant_data.documents ? (
                        <>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Document Type</th>
                                <th>File Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.applicant_data.documents.length > 0 &&
                                props.applicant_data.documents.map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td>{item.DocumentType}</td>
                                      <td>
                                        <a
                                          target="_blank"
                                          referrerPolicy="no-referrer"
                                          href={item.FileName !== null ? item.FileName.includes("simplefileupload") ? item.FileName : `${serverLink}public/uploads/${projectShortCode}/application/document/${item.FileName}` : ""}
                                        >
                                          <i className="fa fa-file-pdf-o" />
                                        </a>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <>
                          <div className="alert alert-info">
                            There is no record.
                          </div>
                        </>
                      )}
                    </div>
                    {/*</div>*/}
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <button
                        className="default-btn btn w-100 active"
                        onClick={onPrevious}
                      >
                        Previous
                      </button>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <button
                        className="default-btn btn w-100 active"
                        onClick={handleSubmit}
                      >
                        Submit Application
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="alert alert-info">
                    Form not completed. Kindly complete the application and come
                    back to this page for submission
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <button
                      className="default-btn btn w-100 active"
                      onClick={onPrevious}
                    >
                      Previous
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    applicant_data: state.GeneralDetails,
    appRequirement: state.ApplicationRequirementDetails,
    applicationData: state.ApplicationDataDetails,
    applicationLogin: state.ApplicationLoginDetails,
  };
};
export default connect(mapStateToProps, null)(UGApplicationPreview);
