import React from "react";
import PageTitle from "../../common/pagetitle/pagetitle";
import Tuition from "../../../images/tuition.jpg";
import Hostel from "../../../images/hostel.jpg";
import Feeding from "../../../images/feeding3.jpg";
import { Link } from "react-router-dom";
import PageName from "../../common/pagename/pagename";

function Fees() {
  return (
    <>
      {
        <>
          <PageName page={"Tuition Fees"} />
          <PageTitle title={` Fees `} breadCrumbs={["Admission", "Fees"]} />
          <div className="latest-news-area pt-100 pb-70">
            <div className="container">
              <h3 className="mb-4">Tuition fees and student funding</h3>
              <p>
                Learn more about the courses we offer, and find out how to make
                an application to join us.
              </p>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="single-campus-card">
                    <div className="img">
                      <Link to={"/fees/tuition"}>
                        <img
                          src={Tuition}
                          style={{ width: "100%" }}
                          alt="Tuition"
                        />
                      </Link>
                    </div>
                    <div className="campus-content">
                      <span className="text-secondary">Tuition</span>
                      <Link to={"/fees/tuition"}>
                        <h5>
                          Find out everything you need to know to plan your
                          student finances
                        </h5>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="single-campus-card">
                    <div className="img">
                      <Link to={"/fees/accommodation"}>
                        <img
                          src={Hostel}
                          style={{ width: "100%" }}
                          alt="Hostel"
                        />
                      </Link>
                    </div>
                    <div className="campus-content">
                      <span className="text-secondary">Accommodation</span>
                      <Link to={"/fees/accommodation"}>
                        <h5>Accommodation right in the university campus</h5>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="single-campus-card">
                    <div className="img">
                      <Link to={"/fees/feeding"}>
                        <img
                          src={Feeding}
                          style={{ width: "100%" }}
                          alt="Image"
                        />
                      </Link>
                    </div>
                    <div className="campus-content">
                      <span className="text-secondary">Feeding</span>
                      <Link to={"/fees/feeding"}>
                        <h5>
                          The University has different cafeteria's with
                          different types of meals
                        </h5>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default Fees;
