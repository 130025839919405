import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { connect } from "react-redux";
import {
  setCourseListDetails,
  setGeneralDetails,
} from "../../../actions/setactiondetails";
import axios from "axios";
import { projectName, serverLink } from "../../../resources/url";
import PageTitle from "../../common/pagetitle/pagetitle";
import CourseFinderSide from "./section/coursefinderside";
import CourseContent from "./section/coursecontent";
import { Helmet } from "react-helmet";
import PageName from "../../common/pagename/pagename";

function CourseFinder(props) {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [courseList, setCourseList] = useState(
    props.courseList.filter((e) =>
      e.CourseName.toLowerCase().includes(search.toLowerCase())
    )
  );
  const [courses, setCourses] = useState(props.courseList);

  
  const getData = async () => {
    await axios
      .get(`${serverLink}academics/course/view/list`)
      .then((result) => {
        const data = result.data;
        setLoading(false);
        setCourseList(data);
        props.setOnCourseDetails(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getData();
  }, [""]);
  useEffect(() => {
    setCourseList(
      props.courseList.filter((e) =>
        e.CourseName.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search]);

  const onCourseChange = (e) => {
    const selectedCourse = e.target.value;
    setSearch(selectedCourse);
  };

  const handleOnFacultyClick = (e) => {
    setCourseList(props.courseList.filter((item) => item.FacultyCode === e));
  };

  const handleOnApplicationTypeClick = (e) => {
    setCourseList(
      props.courseList.filter((item) => item.ApplicationType === e)
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageName page={"Course Finder"} />
          <PageTitle
            title={"Course Finder"}
            breadCrumbs={["Admission", "Course Finder"]}
          />
          <div className="latest-news-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <CourseContent courseList={courseList} />
                <CourseFinderSide
                  courseList={courses}
                  onCourseChange={onCourseChange}
                  handleOnFacultyClick={handleOnFacultyClick}
                  handleOnApplicationTypeClick={handleOnApplicationTypeClick}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    courseList: state.CourseListDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
    setOnCourseDetails: (p) => {
      dispatch(setCourseListDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CourseFinder);
