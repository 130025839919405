import React, { useState } from "react";
import StateData from "../../../../../resources/state_and_lga.json";
import { toast } from "react-toastify";
import axios from "axios";
import { formatDateAndTime, serverLink } from "../../../../../resources/url";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function PGApplicationQualification(props) {
  const [applicantData, setApplicantData] = useState(props.application_id);
  const [shouldSave, setShouldSave] = useState(false);
  const [applicantQualification, setApplicantQualification] = useState(
    props.applicant_data
  );
  const [qualification, setQualification] = useState(
    props.applicant_data.qualification
  );

  const [addQualification, setAddQualification] = React.useState(false);
  const addForm = () => setAddQualification(true);
  const currentYear = new Date().getFullYear();
  const [register, setRegister] = useState({
    ApplicationID: props.application_id,
    QualificationTitle: "",
    QualificationName: "",
    AwardingInstitution: "",
    GradeObtained: "",
    DateAwarded: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    for (let key in register) {
      if (register.hasOwnProperty(key) && key !== "GradedObtained") {
        if (register[key] === "") {
          toast.error(`${key} can't be empty`);
          return false;
        }
      }
    }

    toast.info(`Submitting... Please wait!`);
    await axios
      .post(`${serverLink}application/pg/qualification`, register)
      .then((res) => {
        if (res.data.message === "success") {
          props.update_app_data();
        } else {
          toast.error(
            `Something went wrong. Please check your connection and try again!`
          );
        }
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });
    setAddQualification(false);
  };

  const onEdit = (e) => {
    const value = e.target.value;
    const id = e.target.id;

    setRegister({
      ...register,
      [id]: value,
    });

    setQualification({
      ...qualification,
      [id]: value,
    });
  };

  const deleteItem = async (id) => {
    if (id) {
      toast.info(`Deleting... Please wait!`);
      await axios
        .delete(`${serverLink}application/pg/qualification/delete/${id}`)
        .then((res) => {
          if (res.data.message === "success") {
            props.update_app_data();
            toast.success(`Deleted`);
          } else {
            toast.error(
              `Something went wrong. Please check your connection and try again!`
            );
          }
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    }
  };

  const onPrevious = () => {
    props.active_page({
      personal_info: "active",
    });
  };

  const onNext = () => {
    props.active_page({
      employment: "active",
    });
  };

  return (
    <div className="row">
      <div className="register">
        <h3>Education</h3>

        {addQualification ? (
          <div style={{ float: "right" }}>
            <button className="default-btn btn w-100 active" onClick={onSubmit}>
              Save
            </button>
          </div>
        ) : (
          <div style={{ float: "right" }}>
            <button className="default-btn btn w-100 active" onClick={addForm}>
              Add Qualification
            </button>
          </div>
        )}
        <br />
        <br />
        <br />
        <hr />

        {addQualification ? (
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="AwardingInstitution">
                  Awarding Institution
                </label>
                <input
                  type="text"
                  id="AwardingInstitution"
                  className="form-control"
                  placeholder="Awarding Institution"
                  required
                  onChange={onEdit}
                />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="DateAwarded">Date Awarded</label>
                <input
                  type="date"
                  id="DateAwarded"
                  required
                  className="form-control"
                  placeholder="Date Awarded"
                  onChange={onEdit}
                />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="GradeObtained">Grade Obtained</label>
                <input
                  type="text"
                  id="GradeObtained"
                  className="form-control"
                  required
                  placeholder="Grade Obtained"
                  onChange={onEdit}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="PhoneNumber">Qualification Name</label>
                <input
                  type="tel"
                  id="QualificationName"
                  className="form-control"
                  placeholder="(BSc, MSc, MBA, etc)"
                  required
                  onChange={onEdit}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="Nationality">Qualification Title</label>
                <input
                  type="text"
                  id="QualificationTitle"
                  className="form-control"
                  placeholder="(Computer Science, etc)"
                  onChange={onEdit}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="table-responsive">
          {!props.applicant_data.qualification.length < 1 ? (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Qualification</th>
                  <th>Institution</th>
                  <th>Grade</th>
                  <th>Date Awarded</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {props.applicant_data.qualification.map((item, index) => (
                  <tr key={index}>
                    <td>{`${item.QualificationName} ${item.QualificationTitle}`}</td>
                    <td>{item.AwardingInstitution}</td>
                    <td>{item.GradeObtained}</td>
                    <td>
                      {formatDateAndTime(
                        item.DateAwarded.split("T")[0],
                        "date"
                      )}
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => deleteItem(item.EntryID)}
                      >
                        <i
                          className="fa fa-trash-o small"
                          style={{ fontsize: "30px" }}
                        ></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-info">
              There is no record. Click on Add Qualification
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <button
              className="default-btn btn w-100 active"
              onClick={onPrevious}
            >
              Previous
            </button>
          </div>
          <div className="col-lg-6 col-sm-6">
            <button className="default-btn btn w-100 active" onClick={onNext}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PGApplicationQualification;
