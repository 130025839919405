
import React from "react";
import { decryptData, encryptData } from "../../common/cryptography/cryptography";
import CountryList from "../../../resources/country.json";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../common/pagetitle/pagetitle";
import PageName from "../../common/pagename/pagename";
import { EmailTemplates, projectName, sendEmail, serverLink } from "../../../resources/url";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect } from "react";
import Loader from "../../common/loader/loader";
import JoditEditor from "jodit-react";


const StaffEnrolment = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    let user = window.sessionStorage.getItem("userData2")
    const [titleList, setTitleList] = useState([]);
    const [facultyList, setFacultyList] = useState([])
    const [departmentList, setDepartmentList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLgaList] = useState([]);
    const [stateList2, setStateList2] = useState([]);
    const [lgaList2, setLgaList2] = useState([]);

    const app_id = window.location.href.split('/')[4]
    const currentYear = new Date().getFullYear();
    const [formType, setFormType] = useState("");
    const [imageSrc, setsetImageSrc] = useState({
        Passport: ""
    })
    const [data, setdata] = useState([])
    const [account, setAccount] = useState({
        EmailAddress: "",
        Password: ""
    })
    const [formData, setFormData] = useState({
        ApplicationID: window.atob(app_id),
        FirstName: "",
        MiddleName: "",
        Surname: "",
        TitleID: "",
        Gender: "",
        DateOfBirth: "",
        MaritalStatus: "",
        NationalityID: "",
        StateID: "",
        LgaID: "",
        Religion: "",
        PhoneNumber: "",
        AltPhoneNumber: "",
        EmailAddress: "",
        ContactAddress: "",
        DepartmentCode: "",
        Research: "",
        Facebook: "",
        Linkedin: "",
        Twitter: "",
        Scholar: "",
        Researchgate: "",
        Academia: "",
        Orcid: "",
        Image: "",
        Biography: "",
        InsertedBy: "",
        Passport: "",
        EnrolStatus: "",
        Email: "",
    })
    const getData = async () => {
        try {
            await axios.get(`${serverLink}jobs/job-applications/${window.atob(app_id)}`)
                .then((result) => {
                    if (result.data.length > 0) {
                        const _data = result.data[0]
                        setdata(result.data);
                        if (result.data[0].EnrolStatus.toString() !== "0") {
                            navigate('/')
                        }
                        if (result.data[0].Status.toString() !== '3') {
                            navigate('/')
                        }
                        setFormData({
                            ...formData,
                            FirstName: _data.FirstName,
                            Surname: _data.Surname,
                            MiddleName: _data.MiddleName,
                            EmailAddress: _data.EmailAddress,
                            PhoneNumber: _data.PhoneNumber,
                            InsertedBy: _data.EmailAddress,
                            DepartmentCode: _data.Department,
                            EnrolStatus: _data.EnrolStatus,
                        })
                    }
                    else {
                        navigate('/')
                    }
                })

            await axios.get(`${serverLink}jobs/title/list`)
                .then((result) => {
                    if (result.data.length > 0) {
                        setTitleList(result.data)
                    }
                })
            await axios.get(`${serverLink}jobs/faculty/list`)
                .then((result) => {
                    if (result.data.length > 0) {
                        setFacultyList(result.data)
                    }
                })

            await axios.get(`${serverLink}jobs/department/list`)
                .then((result) => {
                    if (result.data.length > 0) {
                        setDepartmentList(result.data)
                    }
                })
            await axios.get(`${serverLink}jobs/country/list`)
                .then((result) => {
                    if (result.data.length > 0) {
                        setCountryList(result.data)
                    }
                })

            await axios.get(`${serverLink}jobs/state/list`)
                .then((result) => {
                    if (result.data.length > 0) {
                        setStateList2(result.data)
                    }
                })
            await axios.get(`${serverLink}jobs/lga/list`)
                .then((result) => {
                    if (result.data.length > 0) {
                        setLgaList2(result.data)
                    }
                })

            setIsLoading(false)
        } catch (e) {
            console.log('NETWORK ERROR')
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const onEdit = (e) => {
        if (e.target.name === "EmailAddress" || e.target.name === "Password") {
            setAccount({
                ...account,
                [e.target.name]: e.target.value,
            })
            return;
        }

        if (e.target.name === "Email") {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })
            return;
        }

        if (e.target.id === "NationalityID") {
            let StateList_ = stateList2.filter(x => x.NationalityID.toString() === e.target.value.toString())
            setStateList(StateList_)
            setFormData({
                ...formData,
                [e.target.id]: e.target.value
            })
            return;
        }
        if (e.target.id === "StateID") {
            let lgaList_ = lgaList2.filter(x => x.StateID.toString() === e.target.value.toString());
            setLgaList(lgaList_)
            setFormData({
                ...formData,
                [e.target.id]: e.target.value
            })
            return;
        }
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const sendRecoveryMail = async (e) => {
        e.preventDefault();
        try {
            await axios.get(`${serverLink}jobs/password_recovery/${formData.Email}`)
                .then((result) => {
                    if (result.data.length > 0) {
                        let data = decryptData(result.data[0].Password);
                        const fData = {
                            EmailAddress: formData.EmailAddress,
                            FirstName: formData.FirstName,
                            Password: data

                        }
                        let email = EmailTemplates('1', fData);
                        sendEmail(
                            formData.EmailAddress,
                            email.subject,
                            email.title,
                            formData.FirstName,
                            email.body,
                            '',
                            ''
                        )
                        toast.success('An email have been sent to your email address, check to proceed...')
                    }
                })
        } catch (e) {
            console.log('NETWORK ERROR')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.Gender === "" || formData.DateOfBirth === "" || formData.NationalityID === ""  || formData.Religion === "" || formData.Address === "") {
            toast.error('Please fill in all fields in personal information section')
        }
        else if (formData.EnrolStatus.toString() !== "0") {
            toast.error("Sorry, you have enrolled in the past. Thank you")
        }
        else if (formData.Passport === "") {
            toast.error("Please Upload passport")
        }
        else {
            toast.info('enrolling, please wait...')
            await axios.post(`${serverLink}jobs/enrol_staff`, formData)
                .then((result) => {
                    if (result.data.message === "success") {
                        const fData = new FormData();
                        fData.append("app_id", app_id);
                        fData.append("Passport", formData.Passport);
                        axios.patch(`${serverLink}jobs/enrol_staff/add_photo/${window.atob(app_id)}`, fData)
                            .then((result) => {
                                if (result.data.message === "success") {
                                    toast.success("Profile submitted successfully...")
                                    window.sessionStorage.removeItem("userData2")
                                    setTimeout(() => {
                                        navigate('/')
                                    }, 2000);
                                }

                            })
                    } else if (result.data.message === "failed") {
                        toast.error("adding failed, try again...")
                    }
                })

        }

    }
    const formToggle = () => {
        if (formType === "Login") {
            setFormType("Recover")
        } else {
            setFormType("Login")
        }
    }

    const Login = async (e) => {
        e.preventDefault();
        toast.info("Logging in, please wait...");
        const formData = {
            EmailAddress: account.EmailAddress,
            ePassword: encryptData(account.Password)
        }
        await axios.post(`${serverLink}jobs/job-openings/applicant/login`, formData)
            .then((result) => {
                if (result.data.message === "success") {
                    setAccount({
                        ...account,
                        email: "",
                        password: "",
                    });
                    const user = result.data.userData[0]
                    const userData2 = user.EmailAddress + "," + user.FirstName + "," + user.MiddleName + "," + user.Surname + "," + user.PhoneNumber + "," + user.CurriculumVitae
                    window.sessionStorage.setItem("userData2", userData2)
                    toast.success("Login successful");
                    setTimeout(() => {
                        document.getElementById("closeModal").click()
                    }, 500);
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                } else {
                    toast.error("wrong login details");
                }
            })
            .catch((error) => {
                toast.error("Network error!, Try again later...");
            });

    }

    const onPassportChange = (e) => {
        setsetImageSrc({
            [e.target.id]: URL.createObjectURL(e.target.files[0])
        })
        setFormData({
            ...formData,
            Passport: e.target.files[0]
        })
    }
    return isLoading ? (
        <Loader />
    ) : (
        <>
            <PageName page={"Staff Enrolment"} />
            <PageTitle
                title={"Staff Enrolment"}
                breadCrumbs={["Home", "Applicant", "Enrolment"]}
            />

            {user === null &&
                <div className="register-area pt-100 pb-70">
                    <div className="container">
                        <div className="register">
                            <h3>Congratulations</h3>
                            <div>
                                <p>The management of {projectName} is pleased to welcome you to join the team. You are on this page because you have decided to accept the offer of appointment given to you.</p>
                                <h3>Please Note:</h3>
                                <p>You are to fill an enrolment form before proceeding to the HR office.</p>
                                <p>Remember to fill in appropriate details as the data you enrol here would be used to create your profile and generate your ID card</p>
                                <p>Login with the credentials of your job portal profile</p>
                                <li data-bs-toggle="modal" data-bs-target="#sModal" className="default-btn btn w-100 active" onClick={() => {
                                    setFormType("Login")
                                }}>
                                    Click here to begin
                                </li>
                            </div>
                        </div>
                    </div>
                </div>}

            {
                user !== null && <div className="register-area pt-100 pb-70">
                    <div className="container">
                        <div className="register">
                            {data.length > 0 ? <>
                                <form autoComplete={"off"}>
                                    <div className="row">
                                        <h3>Personal Information</h3>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="FirstName">Full Name</label>
                                                <h6>{data[0].FirstName + " " + data[0].MiddleName + " " + data[0].Surname}</h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="MiddleName">Position</label>
                                                <h6>{data[0].Position}</h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="MiddleName">Department</label>
                                                <h6>
                                                    {departmentList.length > 0 &&
                                                        departmentList.filter(x => x.DepartmentCode === data[0].Department)[0].DepartmentName}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="MiddleName">Faculty</label>
                                                <h6>
                                                    {facultyList.length > 0 &&
                                                        facultyList.filter(x => x.FacultyCode === data[0].Faculty)[0].FacultyName}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="MiddleName">Email Address</label>
                                                <h6>{data[0].EmailAddress}</h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="MiddleName">Phone Number</label>
                                                <h6> {data[0].PhoneNumber}</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <div className="col-md-4 offset-4">
                                                    <div className="text-center">
                                                    {imageSrc.Passport !== ""
                                                        && <img src={imageSrc.Passport} style={{
                                                            maxWidth: '180px',
                                                            maxHeight: '150px',
                                                            borderRadius: '5px'
                                                        }} alt="img" />
                                                    }
                                                    </div>
                                                    <div>
                                                    <label htmlFor="Passport">Passport</label>
                                                    <input
                                                        type="file"
                                                        id="Passport"
                                                        className="default-btn btn w-100 active"
                                                        accept="image/*"
                                                        onChange={onPassportChange}
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="TitleID">Title</label>
                                                {
                                                    titleList.length > 0 ?
                                                        <>
                                                            <select
                                                                id="TitleID"
                                                                className="form-control"
                                                                onChange={onEdit}
                                                            >
                                                                <option value="">Select Option</option>
                                                                {
                                                                    titleList.map((x, y) => {
                                                                        return (
                                                                            <option key={y} value={x.EntryID}>{x.TitleName}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </>
                                                        : <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="Gender">Gender</label>
                                                <select
                                                    id="Gender"
                                                    className="form-control"
                                                    onChange={onEdit}
                                                >
                                                    <option value="">Select Option</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Male">Male</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="DateOfBirth">Date of Birth</label>
                                                <input
                                                    type="date"
                                                    id="DateOfBirth"
                                                    className="form-control"
                                                    placeholder="Date of Birth*"
                                                    max={`${currentYear - 13}-01-01`}
                                                    onChange={onEdit}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="NationalityID">Nationality</label>
                                                <select
                                                    id="NationalityID"
                                                    className="form-control"
                                                    onChange={onEdit}
                                                >
                                                    <option value="">Select Option</option>
                                                    {countryList.length > 0 &&
                                                        countryList.map((country, index) => {
                                                            return (
                                                                <option key={index} value={country.EntryID}>
                                                                    {country.Country}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="StateID">State of Origin</label>
                                                <select
                                                    id="StateID"
                                                    className="form-control"
                                                    onChange={onEdit}
                                                >
                                                    <option value="">Select Option</option>
                                                    {stateList.length > 0 &&
                                                        stateList.map((state, index) => {
                                                            return (
                                                                <option key={index} value={state.EntryID}>
                                                                    {state.StateName}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="LgaID">Local Government</label>
                                                <select
                                                    id="LgaID"
                                                    className="form-control"
                                                    onChange={onEdit}
                                                >
                                                    <option value="">Select Option</option>
                                                    {lgaList.length > 0 &&
                                                        lgaList.map((lga, index) => {
                                                            return (
                                                                <option key={index} value={lga.EntryID}>
                                                                    {lga.LgaName}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="Religion">Religion</label>
                                                <select
                                                    id="Religion"
                                                    className="form-control"
                                                    onChange={onEdit}
                                                >
                                                    <option value="">Select Option</option>
                                                    <option value="Christianity">Christianity</option>
                                                    <option value="Islam">Islam</option>
                                                    <option value="Others">Others</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="MaritalStatus">Marital Status</label>
                                                <select
                                                    id="MaritalStatus"
                                                    className="form-control"
                                                    onChange={onEdit}
                                                >
                                                    <option value="">Select Option</option>
                                                    <option value="Married">Married</option>
                                                    <option value="Single">Single</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="AltPhoneNumber">Alternative Phone Number</label>
                                                <input
                                                    type="number"
                                                    id="AltPhoneNumber"
                                                    className="form-control"
                                                    placeholder="Phone Number*"
                                                    onChange={onEdit}
                                                />
                                            </div>
                                        </div>


                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="ContactAddress">Address</label>
                                                <input
                                                    type="text"
                                                    id="ContactAddress"
                                                    className="form-control"
                                                    placeholder="Address*"
                                                    onChange={onEdit}
                                                />
                                            </div>
                                        </div>
                                        <h3>Profile Information</h3>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="Facebook">Facebook</label>
                                                <input
                                                    type="text"
                                                    id="Facebook"
                                                    className="form-control"
                                                    placeholder="profile link..."
                                                    onChange={onEdit}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="LinkedIn">LinkedIn</label>
                                                <input
                                                    type="text"
                                                    id="LinkedIn"
                                                    className="form-control"
                                                    placeholder="profile link..."
                                                    onChange={onEdit}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="Twitter">Twitter</label>
                                                <input
                                                    type="text"
                                                    id="Twitter"
                                                    className="form-control"
                                                    placeholder="profile link..."
                                                    onChange={onEdit}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="Scholar">Google Scholar</label>
                                                <input
                                                    type="text"
                                                    id="Scholar"
                                                    className="form-control"
                                                    placeholder="profile link..."
                                                    onChange={onEdit}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="Researchgate">Researchgate</label>
                                                <input
                                                    type="text"
                                                    id="Researchgate"
                                                    className="form-control"
                                                    placeholder="profile link..."
                                                    onChange={onEdit}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="Academia">Academia</label>
                                                <input
                                                    type="text"
                                                    id="Academia"
                                                    className="form-control"
                                                    placeholder="profile link..."
                                                    onChange={onEdit}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="Orcid">Orcid</label>
                                                <input
                                                    type="text"
                                                    id="Orcid"
                                                    className="form-control"
                                                    placeholder="profile link..."
                                                    onChange={onEdit}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="Biography">Short Biography</label>
                                                <JoditEditor
                                                    value={""}
                                                    tabIndex={1}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData, Biography: e
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="Research">Research/Interests</label>
                                                <JoditEditor
                                                    value={""}
                                                    tabIndex={1}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData, Research: e
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <button type="submit" onClick={handleSubmit} className="default-btn btn w-100 active">
                                        Submit
                                    </button>
                                </form>
                            </>
                                : <></>}
                        </div>
                    </div>
                </div>
            }

            <div className="sidebarModal modal right fade" id="sModal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" id="closeModal" className="close" data-bs-dismiss="modal"><i className="ri-close-line"></i>
                        </button>
                        <div className="modal-body">
                            <div className="contact-form">
                                {formType === "Login" &&
                                    <>
                                        <div className="sidebar-content">
                                            <h3>Login</h3>
                                        </div>

                                        <form id="contactForm" onSubmit={Login}>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-6">
                                                    <div className="form-group">
                                                        <input type="email" name="EmailAddress" className="form-control" required
                                                            data-error="Please enter your email"
                                                            placeholder="Your email address" onChange={onEdit} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input type="password" name="Password" className="form-control" required
                                                            data-error="Please enter your password"
                                                            placeholder="Your Password" onChange={onEdit} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <button type="submit" className="default-btn">Proceed to Login<span></span>
                                                    </button>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-end">
                                                        <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={formToggle} >Forget password</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                }

                                {
                                    formType === "Recover" &&
                                    <>
                                        <div className="sidebar-content">
                                            <h3>Recover Password</h3>
                                        </div>
                                        <form id="contactForm">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" name="Email" className="form-control"
                                                            data-error="Please enter your email"
                                                            placeholder="Email Address" onChange={onEdit} />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <button type="submit" className="default-btn"
                                                        onClick={sendRecoveryMail}>Recover Password<span></span>
                                                    </button>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-end">
                                                        <a onClick={formToggle} >Login</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StaffEnrolment;