import * as DOMPurify from 'dompurify';

const LeadershipProfileModal = (props) => {
  return (
    <>
      {/* <div id="myModal" className="leadership-modal modal">
        <div className="leadership-modal-content modal-content">
          <div className="leadership-modal-body modal-body pt-3">
            <p
              dangerouslySetInnerHTML={{
                __html: props.boardmember.Description,
              }}
            />
            <hr />
            <button type="button" onClick={() => {
              let modal = document.getElementById("myModal");
              modal.style.display = "none";
            }} className="btn sm-btn default-btn leadership-close close">Close<span></span>
            </button>
          </div>
        </div>
<<<<<<< HEAD
      </div> */}
      <div className="sidebarModal modal modal right fade" id="pModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <button type="button" id="closeModal" className="close" data-bs-dismiss="modal"><i className="ri-close-line"></i>
            </button>
            <div className="modal-body">
              <div className="contact-form" style={{textAlign:'justify' }}>
              <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.boardmember.Description),
              }}
            />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadershipProfileModal;
