import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { connect } from "react-redux";
import {
  setGeneralDetails,
  setPublicationDetails,
} from "../../../actions/setactiondetails";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import PageTitle from "../../common/pagetitle/pagetitle";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import SideFilter from "./section/punlicationsections";
import { decryptData } from "../../common/cryptography/cryptography";
import PageName from "../../common/pagename/pagename";

function Publication(props) {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [publication, setPublication] = useState(props.publicationList);
  const [data, setData] = useState(
    props.publicationList.length === 0
      ? []
      : props.publicationList.filter((e) =>
          e.PubType.toLowerCase().includes(search.toLowerCase())
        )
  );
  const [facultyList, setFacultyList] = useState([]);
  const [publicationType, setPublicationType] = useState([]);
  const [columns] = useState([
    {
      name: "Title",
      selector: (row) => (
        <a
          href={decryptData(row.OnlineURL)}
          target="_blank"
          referrerPolicy="no-referrer"
        >
          {" "}
          {row.WorkTitle}{" "}
        </a>
      ),
      sortable: true,
      width: "30%",
    },
    {
      name: "Publication Type",
      selector: (row) => `${row.PubType}`,
      sortable: true,
      width: "15%",
    },
    {
      name: "Author",
      selector: (row) => `${row.Authors !== "" ? row.Authors : "N/A"}`,
      sortable: true,
      width: "15%",
    },
    {
      name: "Year",
      selector: (row) => `${row.PubYear}`,
      sortable: true,
      width: "10%",
    },
    {
      name: "Hit",
      selector: (row) => {
        return (
          <span>
            {" "}
            <i className="fa fa-eye" /> {row.ViewCount} <br />{" "}
            <i className="fa fa-file-pdf-o" /> {row.DownloadCount}
          </span>
        );
      },
      sortable: true,
      width: "10%",
    },
  ]);

  const getData = async () => {
    await axios
      .get(`${serverLink}general/publication/data`)
      .then((result) => {
        const pubData = result.data;
        console.log(pubData);
        setLoading(false);
        setData(
          props.publicationList === ""
            ? []
            : pubData.publicationList.filter((e) =>
                e.PubType.toLowerCase().includes(search.toLowerCase())
              )
        );
        setPublication(pubData.publicationList);
        setPublicationType(pubData.publicationType);
        setFacultyList(pubData.facultyList);
        props.setOnPublicationDetails(pubData.publicationList);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const updateData = (value) => {
    setSearch(value);
  };
  useEffect(() => {
    setData(
      props.publicationList.filter((e) =>
        e.PubType.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search]);
  useEffect(() => {
    getData();
  }, [""]);

  const tableData = {
    columns,
    data,
    print: false,
    exportHeaders: true,
    filterPlaceholder: "Search Publications",
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageName page={"Publications"} />
          <PageTitle
            title={"Publications"}
            breadCrumbs={["Resources", "Publications"]}
          />
          <div className="latest-news-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-9">
                  <h4> {search === "" ? "All Publications" : search}</h4>
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </div>
                <SideFilter
                  publicationType={publicationType}
                  facultyList={facultyList}
                  data={publication}
                  setGeneralDetails={props.setOnGeneralDetails}
                  updateData={updateData}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    publicationList: state.PublicationDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
    setOnPublicationDetails: (p) => {
      dispatch(setPublicationDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Publication);
