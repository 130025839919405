import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { Link } from "react-router-dom";
import Section from "../section/section";
import OfflineMessage from "../../common/message/offline";
import PageName from "../../common/pagename/pagename";
import axios from "axios";
import { serverLink } from "../../../resources/url";

function UniversityRegistry() {
  const [loading, setLoading] = useState(true);
  const [registry, setRegistry] = useState([]);
  const [registryItems, setRegistryItems] = useState([]);

  const getRegistry = async () => {
    await axios
      .get(`${serverLink}academic/registry/all/`)
      .then((response) => {
        setRegistry(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });
  };

  const getRegistryItems = async () => {
    await axios
      .get(`${serverLink}academic/get/registry/items/`)
      .then((response) => {
        setRegistryItems(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });
  };

  useEffect(() => {
    getRegistry().then((r) => {});
    getRegistryItems().then((r) => {});
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageName page={"Academic Registry"} />
          <div className="page-banner-area bg-2">
            <div className="container">
              <div className="page-banner-content">
                <h1>Academic Registry</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Academic Registry</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="graduate-admission pt-100 pb-70" style={{ textAlign: "justify" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="how-to-apply">
                    {registry.length > 0 &&
                      registry.map((r, index) => (
                        <div key={index}>
                          <h3>{r.Title}</h3>
                          <p>{r.Description}</p>
                        </div>
                      ))}

                    <div className="apply-list">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <ul>
                            {registryItems.length > 0 &&
                              registryItems.map((r, index) => (
                                <li key={index}>
                                  <i className="ri-checkbox-circle-line"></i>
                                  {r.Title}
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Section />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default UniversityRegistry;
