import React from "react";
import { Link } from "react-router-dom";

function PGApplicationRequirement(props) {
  return (
    <div className="instructor">
      <div className="row align-items-center">
        <div className="col-lg-12">
          <h3>Application Guide</h3>
          <p>
            <strong>
              Note: You can start filling the application by clicking{"  "}
            </strong>
            on{"  "}
            <span style={{ color: "green" }}>Start Application</span>
          </p>
          <p>
            You can re-enter and complete your application(s) at any time
            convenient to you. Please fill in the application details using
            guidance on the screens, you will see ‘next’ and ‘previous’ buttons
            to help you navigate your way around.
          </p>
          <ul>
            {/* {props.requirement.length > 0 ? (
              <>
                {props.requirement.map((requirements, index) => (
                  <li key={index}>{requirements.DocumentName}</li>
                ))}
              </>
            ) : null} */}
          </ul>
          <p>
            Before you submit your application you can view your application in
            the application summary page. Before you submit your application you
            will be asked to confirm by declaration that the data you are
            submitting is correct.
          </p>
          <div className="col-lg-6 col-sm-6" style={{ float: "right" }}>
            {props.app_status === 0 && (
              <button
                className="default-btn btn w-100 active"
                onClick={props.start_application}
              >
                Start Application
              </button>
            )}
            {props.app_status === 1 && (
              <div className="alert alert-info">
                Application Submitted. Awaiting Decision
              </div>
            )}
            {props.app_status === 2 && (
              <Link to={"/admission/application/print"}>
                <button
                  className="default-btn btn w-100 active"
                  onClick={props.start_application}
                >
                  Print Admission Letter
                </button>
              </Link>
            )}
            {props.app_status === 3 && (
              <div className="alert alert-danger">
                Sorry, your application was not successful. {props.app_reason}
              </div>
            )}
            {props.app_status === 4 && (
              <div className="alert alert-warning">
                You've already cancelled your application
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PGApplicationRequirement;
