import React, { useEffect, useState } from "react";
import { setApplicationDataDetails, setApplicationLoginDetails, } from "../../../../actions/setactiondetails";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  AdmissionSuccessfulEmailTemplate,
  currencyConverter,
  formatDateAndTime,
  projectShortCode,
  serverLink,
} from "../../../../resources/url";
import PageName from "../../../common/pagename/pagename";
import { toast } from "react-toastify";
import axios from "axios";
import PaymentGuideline from '../../../../images/general/Olivia/BIS_OLIVIA_UNIVERSITY.pdf'

function OfferDashboard(props) {
  const navigate = useNavigate();

  if (props.applicationData.Status !== 1 && props.applicationData.Status !== 2 && props.applicationData.Status !== 4) {
    window.location.href = "/admission/application/dashboard"
  }
  const [activePage, setActivePage] = useState('dashboard')
  const [userData] = useState(props.applicationLogin);
  const [applicationData] = useState(props.applicationData);
  const east_african_countries = ['Tanzania', 'Kenya', 'Uganda', 'Rwanda', 'Burundi', 'Democratic', 'Congo', 'Sudan']
  const [paymentItem, setPaymentItem] = useState({
    amountPaid: '',
    paymentReference: '',
    applicationID: props.applicationData.EntryID,
    imagePath: ''
  })
  const [paymentDetail, setPaymentDetail] = useState([]);
  const [receiptList, setReceiptList] = useState([]);
  const [admissionLetter, setAdmissionLetter] = useState([]);

  const getRecord = async () => {
    await axios.get(`${serverLink}application/receipt/list/${props.applicationData.EntryID}`)
      .then(res => {
        setReceiptList(res.data)
      })
      .catch(err => {
        toast.error("NETWORK ERROR")
      })

    await axios.get(`${serverLink}application/admission-letter/${props.applicationData.EntryID}`)
      .then((result) => {
        if (result.data.length > 0) {
          setAdmissionLetter(result.data);
        }
      })
  }

  const handleLogout = () => {
    props.setOnApplicationLoginDetails("");
    props.setOnApplicationDataDetails("");
    navigate("/admission/application");
  };

  const onEdit = (e) => {
    if (e.target.id === 'amountPaid')
      setPaymentItem({
        ...paymentItem,
        [e.target.id]: parseFloat(e.target.value)
      })
    else
      setPaymentItem({
        ...paymentItem,
        [e.target.id]: e.target.value
      })
  }

  const handleCheck = (e) => {
    let form_data = paymentDetail;
    if (e.target.checked) {
      form_data.push(e.target.name)
    } else {
      form_data = form_data.filter(i => i !== e.target.name)
    }
    setPaymentDetail(form_data)
  }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setPaymentItem({
        ...paymentItem,
        imagePath: event.target.files[0]
      });
    } else {
      setPaymentItem({
        ...paymentItem,
        imagePath: ""
      });
    }
  }

  const handleSubmit = async () => {
    if (paymentItem.amountPaid === '') {
      toast.error("Please enter the amount paid");
      return false;
    }

    if (paymentItem.paymentReference === '') {
      toast.error("Please enter the payment reference");
      return false;
    }

    if (paymentDetail.length === 0) {
      toast.error("Please select payment for");
      return false;
    }

    if (paymentItem.imagePath === '') {
      toast.error("Please select payment receipt");
      return false;
    }

    let fd = new FormData();
    fd.append("imagePath", paymentItem.imagePath);
    fd.append('amountPaid', paymentItem.amountPaid)
    fd.append('paymentReference', paymentItem.paymentReference)
    fd.append('applicationID', paymentItem.applicationID)
    fd.append('description', paymentDetail.toString())

    await axios.post(`${serverLink}application/upload/payment`, fd)
      .then(res => {
        if (res.data.message === 'success') {
          setPaymentItem({
            amountPaid: '',
            paymentReference: '',
            applicationID: props.applicationData.EntryID,
            imagePath: ''
          })
          document.getElementById('imagePath').value = null
          toast.success("Payment Receipt Uploaded Successfully");
          getRecord();
        } else {
          toast.error("Something went wrong uploading payment receipt")
        }
      })
      .catch(err => {
        toast.error("NETWORK ERROR. Please try again!")
      })
  }

  useEffect(() => {
    getRecord()
  }, [])

  return (
    props.applicationData.Status === 1 || props.applicationData.Status === 2 || props.applicationData.Status === 4 ? (
      <>
        <PageName page={"Dashboard"} />

        <div className="graduate-admission pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="additional-information">
                  <h3>{applicationData.Status !== 1 ? 'Congratulations' : 'Hi'}, {userData.FirstName}!</h3>
                  <div className="list">
                    <ul>
                      <li onClick={() => setActivePage('dashboard')}>
                        <Link to={""}>
                          Dashboard
                        </Link>
                      </li>
                      {
                        applicationData.Status === 1 || applicationData.Status === 2 ?
                          <>
                            <li>
                              <Link to={`/admission/application/print/${btoa(applicationData.EntryID)}/${btoa(applicationData.Status)}`}>
                                Print Invoice
                              </Link>
                            </li>
                            <li onClick={() => setActivePage('payment')}>
                              <Link to={``}>
                                Upload Payment
                              </Link>
                            </li>
                          </> : ''
                      }

                      {
                        applicationData.Status === 4 &&
                        <li>
                          <Link to={`/admission/application/enrolment`} >
                            Student Enrolment
                          </Link>
                        </li>
                      }
                      <li>
                        <Link to={"/admission/application/dashboard"}>
                          Return Home
                        </Link>
                      </li>
                      <li onClick={handleLogout}>
                        <Link to={""}>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
              <div className="col-lg-8">
                <div className="instructor">
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <div className="instructor-content">
                        <div className="name">
                          <h3>{`${userData.FirstName} ${userData.MiddleName} ${userData.Surname}`}</h3>
                          <p>Course: {applicationData.CourseName} </p>
                          <p>
                            Status:
                            {
                              applicationData.Status === 1 && <button className={"btn btn-primary btn-sm"}>Submitted</button>
                            }
                            {
                              applicationData.Status === 2 && <span className={"btn btn-success btn-sm"}>Approved</span>
                            }
                            {
                              applicationData.Status === 4 && <span className={"btn btn-info btn-sm"}>Approved Enrolment</span>
                            }
                          </p>
                        </div>
                        {
                          activePage === 'dashboard' ?
                            applicationData.Status === 1 ?
                              <div className="alert alert-success">
                                <p>We have received your application to {projectShortCode}. We are happy that you have chosen {projectShortCode} to be your university of choice. Your application will take about 5 – 8 days to process.
                                  <br /> You will receive a letter letting you know the outcome of your application.<br />
                                  Again, thank you for choosing {projectShortCode} as your university of choice.
                                </p>
                                <p>In order to process your application, kindly <Link to={`/admission/application/print/${btoa(applicationData.EntryID)}/${btoa(applicationData.Status)}`}>print the invoice</Link> and make payment at any of the Ecobank branch in Burundi or your country of residence</p>
                              </div>

                              : applicationData.Status === 2 ?
                                <>
                                  <div
                                    className="alert alert-success"
                                    dangerouslySetInnerHTML={{
                                      __html: AdmissionSuccessfulEmailTemplate(applicationData.CourseName)
                                    }}

                                  />
                                  <div className="">
                                    {
                                      projectShortCode !== "OUB" &&
                                      <div>
                                        {
                                          admissionLetter.length > 0 &&
                                          <a href={`${serverLink}public/uploads/${projectShortCode}/admission-letters/${admissionLetter[0].AdmissionLetter}.pdf`} target="_blank" style={{ textDecoration: 'underline', marginBottom: '10px' }} className="link">Click here to download your admission letter</a>
                                        }
                                      </div>
                                    }

                                  </div>
                                  <br />
                                  <a href={PaymentGuideline} target="_blank" style={{ textDecoration: 'underline' }} className="link">Click here to download payment guideline</a>
                                </>
                                :
                                <div className="alert alert-success">
                                  <p>Congratulations, you've been cleared for enrolment. Kindly click on the <b>Student Enrolment</b> link from the sidebar to proceed.</p>
                                </div>

                            :
                            <div className="table-responsive">
                              {
                                receiptList.length > 0 &&
                                <>
                                  <h3>Upload History</h3>
                                  <table className="table table-stripped mb-3">
                                    <thead>
                                      <tr>
                                        <th>Description</th>
                                        <th>Amount Paid</th>
                                        <th>Inserted On</th>
                                        <th>File</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        receiptList.map((item, index) => {
                                          return <tr key={index}>
                                            <td>{item.Description}</td>
                                            <td>{currencyConverter(item.AmountPaid, east_african_countries.includes(props.applicationLogin.Nationality))}</td>
                                            <td>{formatDateAndTime(item.InsertedDate, 'date_and_time')}</td>
                                            <td><a href={`${serverLink}public/uploads/${projectShortCode}/application/document/${item.FilePath}`} target="_blank"><i className="fa fa-file" /></a></td>
                                          </tr>
                                        })
                                      }
                                    </tbody>
                                  </table>
                                </>
                              }

                              <h3>Upload Payment Evidence</h3>

                              <div className="form-group mb-3">
                                <label htmlFor="amountPaid">Total Amount Paid ({east_african_countries.includes(props.applicationLogin.Nationality) ? 'BIF' : 'USD'})</label>
                                <input type="number" step={0.01} onChange={onEdit} id="amountPaid" className="form-control" value={paymentItem.amountPaid} />
                              </div>

                              <div className="form-group mb-3">
                                <label htmlFor="paymentReference">Payment Reference</label>
                                <input type="text" onChange={onEdit} id="paymentReference" className="form-control" value={paymentItem.paymentReference} />
                              </div>

                              <div className="form-group mb-3">
                                <label htmlFor="paymentReference">Payment For (Please Select)</label>
                                <div className="form-check mb-3">
                                  <input className="form-check-input" type="checkbox" name="Application" onChange={handleCheck} />
                                  <label className="form-check-label" htmlFor="paymentForApplication">
                                    Application Fee
                                  </label>
                                </div>

                                {
                                  applicationData.Status === 2 &&
                                  <div className="form-check mb-3">
                                    <input className="form-check-input" type="checkbox" name="Complete Semester Tuition" onChange={handleCheck} />
                                    <label className="form-check-label" htmlFor="paymentFor">
                                      Tuition Fee(Complete Semester Payment)
                                    </label>
                                  </div>
                                }

                                {
                                  applicationData.Status === 2 &&
                                  <div className="form-check mb-3">
                                    <input className="form-check-input" type="checkbox" name="Complete Two Semester Tuition" onChange={handleCheck} />
                                    <label className="form-check-label" htmlFor="paymentFor">
                                      Tuition Fee(Two Complete Semester payment)
                                    </label>
                                  </div>
                                }
                                {
                                  applicationData.Status === 2 &&
                                  <div className="form-check mb-3">
                                    <input className="form-check-input" type="checkbox" name="Installmental Tuition" onChange={handleCheck} />
                                    <label className="form-check-label" htmlFor="paymentFor">
                                      Tuition Fee(Installmental (1.575M BIF))
                                    </label>
                                  </div>
                                }

                                {
                                  applicationData.IsHostelRequired === 'Yes' &&
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="Hostel" onChange={handleCheck} />
                                    <label className="form-check-label" htmlFor="paymentFor">
                                      Hostel Fee
                                    </label>
                                  </div>
                                }
                              </div>

                              <div className="form-group mb-3">
                                <label htmlFor="imagePath">Payment Receipt</label>
                                <input type="file" onChange={onImageChange} id="imagePath" className="form-control" accept="application/pdf, image/*" />
                              </div>

                              <button className="btn btn-primary w-100" onClick={handleSubmit}>Submit</button>

                            </div>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : 'Permission denied'
  );
}

const mapStateToProps = (state) => {
  return {
    applicationLogin: state.ApplicationLoginDetails,
    applicationData: state.ApplicationDataDetails
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnApplicationLoginDetails: (p) => {
      dispatch(setApplicationLoginDetails(p));
    },
    setOnApplicationDataDetails: (p) => {
      dispatch(setApplicationDataDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OfferDashboard);
