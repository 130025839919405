import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import {
  setFacultyDataDetails,
  setFacultyListDetails,
} from "../../../actions/setactiondetails";
import { connect } from "react-redux";
import axios from "axios";
import { projectShortCode, serverLink } from "../../../resources/url";
import DataLoader from "../../common/dataLoader/dataLoader";
import PageName from "../../common/pagename/pagename";
import HomepageSectionNews from "../../homepage/section/section-news";
import HomepageSectionEventArea from "../../homepage/section/section-event";
import FacultyNews from "./section/facultynews";
import * as DOMPurify from 'dompurify';

function FacultyPage(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();

  if (slug === "") navigate("/");

  const [loading, setLoading] = useState(true);
  let facultyRecord = props.facultyList.filter((item) => item.Slug === slug);
  const [facultyDetail, setFacultyDetail] = useState(
    facultyRecord.length > 0
      ? props.facultyData.filter(
          (item) => item.FacultyCode === facultyRecord[0].FacultyCode
        )
      : []
  );

  useEffect(() => {
    const slug_record = location.pathname.split("/")[2];
    facultyRecord.push(
      props.facultyList.filter((item) => item.Slug === slug_record)
    );
    setFacultyDetail(
      facultyRecord.length > 0
        ? props.facultyData.filter(
            (item) => item.FacultyCode === facultyRecord[0].FacultyCode
          )
        : []
    );
  }, [location.pathname]);

  const getFacultyData = async () => {
    await axios
      .get(`${serverLink}academics/faculty/academic/list`)
      .then((response) => {
        const data = response.data;

        const faculty_data = data.filter((item) => item.Slug === slug);
        props.setOnFacultyListDetails(response.data);

        if (data.filter((item) => item.Slug === slug).length < 1) {
          window.location.href = "/";
          //navigate("/");
        } else {
          facultyRecord.push(faculty_data);
        }
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });

    await axios
      .get(`${serverLink}academics/faculty/details`)
      .then((response) => {
        props.setOnFacultyDataDetails(response.data);
        setFacultyDetail(
          response.data.filter(
            (item) => item.FacultyCode === facultyRecord[0].FacultyCode
          )
        );
        if (facultyDetail.length < 1) navigate("/");
        else console.log(facultyDetail);
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });
  };

  if (facultyDetail.length < 1) {
    if (loading) {
      getFacultyData();
      setLoading(false);
    }
  }

  return facultyDetail.length < 1 ? (
    <DataLoader />
  ) : (
    <>
      <PageName page={facultyRecord[0].FacultyName} />
      <div
        className="page-banner-area"
        style={{
          backgroundImage: facultyDetail[0].FacultyImage !== null ? facultyDetail[0].FacultyImage.includes("simplefileupload") ? `url(${ facultyDetail[0].FacultyImage })` :  `url(${serverLink}public/uploads/${projectShortCode}/faculty_uploads/${facultyDetail[0].FacultyImage})` :  `url("")`,
        }}
      >
        <div className="container">
          <div className="page-banner-content">
            <h1>{facultyRecord[0].FacultyName}</h1>
            <ul>
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>Academics</li>
              <li>{facultyRecord[0].FacultyName}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="academics-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="academics-details">
                <div className="details" style={{textAlign: 'justify'}}>
                  <h3 className="text-uppercase">
                    {facultyRecord[0].FacultyName}
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(facultyDetail[0].FacultyDetail),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="detgails-right-content">
                <div className="academics-list">
                  <h3>Our Courses</h3>
                  <ul>
                    {props.courseList.filter(
                      (item) =>
                        item.FacultyCode === facultyRecord[0].FacultyCode
                    ).length > 0 &&
                      props.courseList
                        .filter(
                          (item) =>
                            item.FacultyCode === facultyRecord[0].FacultyCode
                        )
                        .map((course, index) => {
                          return (
                            <li key={index}>
                              <Link to={`/course/${course.Slug}`}>
                                {course.CourseName}
                                <i className="ri-arrow-drop-right-fill" />
                              </Link>
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <div className="related-download">
                  <h3>Contact Us</h3>
                  <ul>
                    <li>
                      <a href={`tel:${facultyDetail[0].FacultyPhone}`}>
                        <i className="flaticon-phone-call" />
                        {facultyDetail[0].FacultyPhone}
                      </a>
                    </li>
                    <li>
                      <a href={`mailto:${facultyDetail[0].FacultyEmail}`}>
                        <i className="flaticon-email" />
                        Send us mail
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FacultyNews
        news_list={props.newsList.length > 0 ? props.newsList.filter((item) => item.FacultyCode === facultyRecord[0].FacultyCode) : []}
        event_list={props.eventList.length > 0 ? props.eventList.filter((item) => item.FacultyCode === facultyRecord[0].FacultyCode) : []}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    facultyList: state.FacultyListDetails,
    facultyData: state.FacultyDataDetails,
    courseList: state.CourseListDetails,
    eventList: state.EventDetails,
    newsList: state.NewsDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnFacultyListDetails: (p) => {
      dispatch(setFacultyListDetails(p));
    },
    setOnFacultyDataDetails: (p) => {
      dispatch(setFacultyDataDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FacultyPage);
