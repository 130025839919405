import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Section(props) {
  // const [activePage, setActivePage] = useState({
  //   registry: "active",
  //   goal: "",
  //   calendar: "",
  //   education: "",
  // });

  const current_url = window.location.href.split("/")[3];

  return (
    <>
      <div className="col-lg-4">
        <div className="additional-information">
          <h3>Academics</h3>
          <div className="list">
            <ul>
              {current_url === "education" ? (
                <li
                  className={
                    current_url === "education"
                      ? "default-btn bg-secondary"
                      : ""
                  }
                >
                  <Link to="/education">
                    <span
                      className={
                        current_url === "education" ? "text-white" : ""
                      }
                    >
                      Education
                    </span>
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/education">Education</Link>
                </li>
              )}

              <li>
                <Link to="/staff-list">Staff</Link>
              </li>

              {current_url === "learning-goal" ? (
                <li
                  className={
                    current_url === "learning-goal"
                      ? "default-btn bg-secondary"
                      : ""
                  }
                >
                  <Link to="/learning-goal">
                    <span
                      className={
                        current_url === "learning-goal" ? "text-white" : ""
                      }
                    >
                      Learning Goal
                    </span>
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/learning-goal">Learning Goal</Link>
                </li>
              )}

              {current_url === "academic-registry" ? (
                <li
                  className={
                    current_url === "academic-registry"
                      ? "default-btn bg-secondary"
                      : ""
                  }
                >
                  <Link to="/academic-registry">
                    <span
                      className={
                        current_url === "academic-registry" ? "text-white" : ""
                      }
                    >
                      Academic Registry
                    </span>
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/academic-registry">Academic Registry</Link>
                </li>
              )}

              {current_url === "academic-calendar" ? (
                <li
                  className={
                    current_url === "academic-calendar"
                      ? "default-btn bg-secondary"
                      : ""
                  }
                >
                  <Link to="/academic-calendar">
                    <span
                      className={
                        current_url === "academic-calendar" ? "text-white" : ""
                      }
                    >
                      Academic Calendar
                    </span>
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/academic-calendar">Academic Calendar</Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section;
