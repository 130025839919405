import React from "react";
import PageTitle from "../common/pagetitle/pagetitle";
import PageName from "../common/pagename/pagename";
import Governance_Structure from "../../../src/images/governance.png";

function GovernanceStructure() {
    return (
        <>
            <PageName page={"Governance Structure"} />
            <PageTitle title={"Governance Structure "} breadCrumbs={["Home", "Governance Structure"]} />
            <div className="container mt-0">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="campus-content pr-20" style={{ marginTop: '80px' }}>
                            <div className="campus-title">
                                <div className="campus-image pl-20">
                                    <img src={Governance_Structure} alt={"Governance Structure"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GovernanceStructure;
