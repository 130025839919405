import React, { useEffect, useState } from "react";
import Slider from "./section/slider";
import HomepageSectionNews from "./section/section-news";
import HomepageSectionInfoArea from "./section/section-info-area";
import HomepageSectionAdmissionArea from "./section/section-admission-area";
import HomepageSectionEventArea from "./section/section-event";
import Loader from "../common/loader/loader";
import HomepageSectionEducationArea from "./section/section-education-area";
import { connect } from "react-redux";
import {
  setEventDetails,
  setNewsDetails,
} from "../../actions/setactiondetails";
import axios from "axios";
import { projectName, serverLink } from "../../resources/url";
import HomepageSectionOurFaculties from "./section/section-campus-life";
import { Helmet } from "react-helmet";
import PageName from "../common/pagename/pagename";

function Homepage(props) {
  const [loading, setLoading] = useState(true);
  const [slider, setSlider] = useState([]);
  const [newsList] = useState(props.newsList);
  const [eventList] = useState(props.eventList);

  const getData = async () => {
    await axios
      .get(`${serverLink}slider/homepage`)
      .then((result) => {
        const data = result.data;
        setSlider(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageName />
          <Slider slider={slider} />
          <HomepageSectionEducationArea />
          <HomepageSectionInfoArea />
          <HomepageSectionOurFaculties />
          <HomepageSectionNews news_list={newsList} />
          <HomepageSectionAdmissionArea />
          <HomepageSectionEventArea event_list={eventList} />
          {/*<HomepageSectionStudentStories />*/}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    eventList: state.EventDetails,
    newsList: state.NewsDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnEventDetails: (p) => {
      dispatch(setEventDetails(p));
    },
    setOnNewsDetails: (p) => {
      dispatch(setNewsDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
