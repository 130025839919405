import React from "react";
import PageTitle from "../common/pagetitle/pagetitle";
import PageName from "../common/pagename/pagename";
import paul from "../../images/general/Olivia/vc-image.jpeg";

function WelcomeMessage() {
    return (
        <>
            <PageName page={"Welcome Message"} />
            <PageTitle title={"Welcome Message "} breadCrumbs={["Home", "Welcome Message"]} />
            <div className="container mt-0">
                <div className="row align-items-center">
                    <div className="col-lg-4 text-center">
                        <div className="campus-image pl-20 pt-3">
                            <img src={paul} alt={"VC"} />
                        </div>
                        <h3>Rev Fr Prof Obi J. Oguejiofor</h3>
                        <small>( Vice Chancellor)</small>
                    </div>
                    <div className="col-lg-8">
                        <div className="campus-content pr-20" style={{ marginTop: '80px' }}>
                            <div className="campus-title">
                                <h2>A WORD OF WELCOME!</h2>
                                <hr style={{ width: '100px', border: '1px solid darkblue' }} />
                                <p style={{ textAlign: "justify" }}>
                                    You are heartily welcome to Olivia University in Bujumbura. Olivia University is not just any university in Africa, but one that is full of promises as it is poised to be a trailblazer among other universities in Africa and beyond. Olivia University is therefore programmed for excellence in tertiary education in sub-Saharan Africa and by so doing aligns itself with international best practices in higher education. You are very welcome as students to be part of the history that Olivia University is making by providing meaningful and exceptional contributions to the advancement of learning. You are highly welcome to make optimal use of the opportunities offered by the University to its young students which include the provision of adequate and up to date facilities that are invaluable for effective tertiary education in the modern world. The University has supreme trust in your ability to imbibe the quality of learning and research ability which it impacts on its students. It creates a learning atmosphere that enables its students to optimize the positive learning factors at their disposal.
                                </p>
                                {/* <p style={{ textAlign: "justify" }}>
                                    Olivia University has a crop of lecturers and administrators whose main focus is the positive growth of their students in all aspects of the human endeavor. They are highly qualified, disciplined and hard working and are invaluable role models and mentors for young people under their charge.
                                    The overarching purpose of higher education is to acquire the necessary culture and skills to serve God and humanity by sharpening one’s natural endowments. It is therefore a major aim of our different programmes to equip you with knowledge, confidence and skills that are necessary for both national and international job markets.
                                    Your time in Olivia University will certainly be one of the most significant in your entire life. You are therefore highly encouraged to begin to carve a special niche for yourself by making the best of this period, making optimal use of the numerous opportunities the University offers to all who are associated with it in diverse ways.
                                </p> */}
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}


export default WelcomeMessage;
