import React from "react";
import PageTitle from "../common/pagetitle/pagetitle";
import PageName from "../common/pagename/pagename";
import { connect } from "react-redux";
import BujumBura1 from './Bujumbura1.jpg'
import BujumBura2 from './Bujumbura2.jpg'
import './life-in-city.css'
const LifeInCity = (props) => {
    return (
        <>
            <PageName page={"Life in Burundi"} />
            <PageTitle title={"Life in Burundi"} breadCrumbs={["About","Life in Burundi"]} />
            <div className="container">
                <div className="row align-items-center pt-10 mt-3">
                    <div className="col-lg-6">
                        <div className="campus-content pr-20">
                            <div className="campus-title">
                                <h3>A Bird’s Eye View of Bujumbura</h3>
                                <p style={{ textAlign: 'justify' }}>
                                    Bujumbura is the beautiful and verdant commercial capital of Burundi, a landlocked East African jewel of a country, that is bordered by Lake Tanganyika, a water-way divide it shares with the Democratic Republic of Congo. However, Burundi being a relatively small East African country (its current population is estimated at a little over 12 million people), places great emphasis on tourism as a foreign exchange earner. Consequently, the government and people of Burundi are especially hospitable, welcoming, kind and gracious to visitors to the country—tourists, students, businessmen and/or women, as well as members of the Diplomatic Corps.<br />

                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="campus-image pl-20">
                            <img
                                src={BujumBura1}
                                alt={"Life in Bujumbura"}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="campus-image pl-20">
                            <img
                                src={BujumBura2}
                                alt={"Life in Bujumbura"}
                            />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="campus-content pr-20">
                            <div className="campus-title">
                                <div style={{ textAlign: 'justify' }}>
                                    <p> As a result of being a popular tourist destination, Bujumbura has literally something wonderful for everyone:</p>
                                    <ul style={{ textAlign: 'justify' }}>
                                        <li><p>Quiet, sleepy and safe Canopic suburbs, for those who like the laid back, easygoing lifestyle</p></li>
                                        <li><p>World-class restaurants dotted all over the city, offering a rich variety of African and international cuisines (many, if not most of whom deliver)</p>
                                        </li>
                                        <li>
                                            <p>
                                                A rich and vibrant nightlife, for those who like to ‘let their hair down’ and hit the clubs on weekends (at international standard nightclubs such as: Arena, Toxic, Cristal, Zion Beach Club and the 8 Kiss Club, to name a few)
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                A “Hiker’s Paradise” as James Kahongen, of The East African magazine, described Bujumbura. He notes that: “If hiking is your cup of tea, you can enjoy the activity in Bujumbura. On weekends and most evenings, droves of residents jog through the scenic Kiriri Hills, overlooking the city from the eastern side. From the hills . . . is the uninhibited view of half the city and a section of Lake Tanganyika to the point where it hugs villages in Uvira, in the Democratic Republic of Congo.” OUB Burundi, located in the quiet suburbs of Avenue Inkondo, is a perfect retreat, from whence one can venture out to get a taste of Bujumbura in measured doses.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default connect(mapStateToProps, null)(LifeInCity);
