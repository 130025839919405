import React, { useEffect, useState } from "react";
import {
  projectStaffURL,
  projectStudentURL,
  serverLink,
} from "../../../resources/url";
import "./top-bar.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

export default function TopBar() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [headline, setHeadline] = useState([]);
  const getHeadline = async () => {
    await axios
      .get(`${serverLink}general/website_headline`)
      .then((response) => {
        setHeadline(response.data);
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
      });
  };

  useEffect(() => {
    getHeadline();
  }, []);

  return (
    <div className="top-header-area hideFooter">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-7">
            <div className="header-left-content">
              {headline.length > 0 && (
                <div style={{ color: "white" }}>
                  <span dangerouslySetInnerHTML={{ __html: `${headline[0].Message}` }}/>
                  {headline[0].LinkPath !== "" && (
                    <Link
                      to={headline[0].LinkPath}
                      className={"m-2 btn-sm"}
                      style={{ color: "whitesmoke", textDecoration:'underline' }}
                    >
                      {`${headline[0].LinkTitle}`}
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <div className="header-right-content">
              <div className="list">
                <ul>
                  <li>
                    <a
                      href={projectStudentURL}
                      target="_blank"
                      referrerPolicy="no-referrer"
                    >
                      Students
                    </a>
                  </li>
                  <li>
                    <a
                      href={projectStaffURL}
                      target="_blank"
                      referrerPolicy="no-referrer"
                    >
                      Faculty & Staff
                    </a>
                  </li>
                  <li>
                    <Link to={"/admission/application"}>Admission</Link>
                  </li>
                  <li>
                    <Link to={"/alumni"}>Alumni</Link>
                  </li>
                  <li>
                    <div id="google_translate_element"></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
