import React, { useEffect, useState } from "react";
import PageTitle from "../../../common/pagetitle/pagetitle";
import {
    EmailTemplates,
    formatDateAndTime,
    projectName,
    sendEmail,
    serverLink,
} from "../../../../resources/url";
import { Helmet } from "react-helmet";
import { setApplicationLoginDetails } from "../../../../actions/setactiondetails";
import { connect } from "react-redux";
import { encryptData } from "../../../common/cryptography/cryptography";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import PageName from "../../../common/pagename/pagename";
import Loader from "../../../common/loader/loader";
import { result } from "lodash";
import swal from "sweetalert";

function ApplicationRecoverPassword(props) {
    const navigate = useNavigate();
    const [allow, setAllow] = useState(1)
    const [loading, setLoading] = useState(false);
    const token = window.location.href.split('/')[6]
    const [login, setLogin] = useState({
        Password: "",
        cPassword: "",
        EmailAddress: ""
    });



    const ValidateToken = async () => {
        await axios
            .get(`${serverLink}application/forget_password/validate_token/${token}`)
            .then((res) => {
                if (res.data.length === 0) {
                    setAllow(0)
                    toast.info("This token has expired, please resend another email by clicking forgot password button")
                    setTimeout(() => {
                        navigate('/')
                    }, 5000);
                } else {
                    setLogin({
                        ...login,
                        EmailAddress: res.data[0].EmailAddress
                    })
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR", err);
            });
    };

    useEffect(() => {
        ValidateToken();
    }, []);


    const handleReset = async (e) => {
        e.preventDefault();
        if (login.Password !== login.cPassword) {
            toast.error('Password does not match');
            return;
        }

        const formData = {
            Password: encryptData(login.Password),
            EmailAddress: login.EmailAddress
        }

        if (allow === 1) {
            await axios.patch(`${serverLink}application/forget_password/change_password/${token}`, formData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success('Your password have been changed successfully.');
                        navigate('/admission/application/login')
                    } else {
                        toast.error('Please try again.')
                    }
                }).catch((e) => {
                    toast.error('Please try again.')
                    console.log('NETWORK ERROR')
                })
        }

    }

    const onEdit = (e) => {
        setLogin({
            ...login,
            [e.target.id]: e.target.value
        })
    };
    return loading ? (
        <Loader />
    ) : (
        <>
            <PageName page={"Reset Password"} />
            <PageTitle
                title={"Reset Password"}
                breadCrumbs={["Home", "Application", "Reset Password"]}
            />

            <div className="login-area pt-100 pb-70">
                <div className="container">
                    <div className="login">
                        <h3>Reset Password</h3>
                        <form onSubmit={handleReset}>
                            <div className="form-group">
                                <label htmlFor="Password">New Password</label>
                                <input
                                    type="password"
                                    id="Password"
                                    className="form-control"
                                    placeholder="Password*"
                                    onChange={onEdit}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="cPassword">Confirm Password</label>
                                <input
                                    type="password"
                                    id="cPassword"
                                    className="form-control"
                                    placeholder="Confirm Password*"
                                    onChange={onEdit}
                                />
                            </div>
                            <button type="submit" className="default-btn btn active">
                                Change Password
                            </button>

                            <Link to="/admission/application/login">
                                Login
                            </Link>
                        </form>
                    </div>

                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        applicationLogin: state.ApplicationLoginDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnApplicationLoginDetails: (p) => {
            dispatch(setApplicationLoginDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationRecoverPassword);
