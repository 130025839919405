import React from "react";
import { formatDateAndTime, serverLink } from "../../../resources/url";
import { Link } from "react-router-dom";

export default function HomepageSectionEventArea(props) {
  return (
    <div className="events-area bg-f4f6f9 ptb-50">
      <div className="container">
        <div className="section-title">
          <h2>Events</h2>
        </div>
        <div className="row justify-content-center">
          {props.event_list.length > 0 &&
            props.event_list.map((event, index) => {
              return (
                <div key={index} className="col-lg-4 col-md-6">
                  <div className="single-events-card">
                    <div className="events-image">
                      <div className="date">
                        <span>
                          {formatDateAndTime(event.InsertedDate, "day")}
                        </span>
                        <p>
                          {formatDateAndTime(event.InsertedDate, "short_month")}
                        </p>
                      </div>
                    </div>
                    <div className="events-content">
                      <div className="admin">
                        <p>
                          <Link to={`/event/${event.Slug}`}>
                            <i className="flaticon-user" /> {event.InsertedBy}
                          </Link>
                        </p>
                      </div>
                      <Link to={`/event/${event.Slug}`}>
                        <h4 className={"text-capitalize"}>
                          {event.Title.toLowerCase()}
                        </h4>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="more-health-care text-center">
          <p>
            <Link to="/event" className="read-more-btn active">
              More on Events
              <i className="flaticon-next" />
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
