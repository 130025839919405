import React from "react";
import { projectName, projectShortCode, serverLink } from "../../../resources/url";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import OurCampus from "../../../images/general/our-campus.png";
import { connect } from "react-redux";
import * as DOMPurify from 'dompurify';

function HomepageSectionInfoArea(props) {
  return (
    <div className="campus-information-area pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="campus-content pr-20">
              <div className="campus-title">
                <h2>Discover {projectName}</h2>
                <p
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(props.homeData.DiscoverContent),
                  }}
                />
                <Link to={"/about-us"} className="default-btn btn">
                  Read more
                </Link>
              </div>
              <div className="list">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <ul>
                      <li>
                        <i className="ri-check-fill" />
                        <p>Undergraduate</p>
                      </li>
                      <li>
                        <i className="ri-check-fill" />
                        <p>Programs</p>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <ul>
                      <li>
                        <i className="ri-check-fill" />
                        <p>International Hubs</p>
                      </li>
                      <li>
                        <i className="ri-check-fill" />
                        <p>Global Students</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="counter">
                <div className="row">
                  <div className="col-lg-4 col-4">
                    <div className="counter-card">
                      <h1>
                        <span className="odometer">
                          <CountUp end={500} />
                        </span>
                        <span className="target">+</span>
                      </h1>
                      <p>Initial Students</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-4">
                    <div className="counter-card">
                      <h1>
                        <span className="odometer">
                          <CountUp end={props.departmentList} />
                        </span>
                        <span className="target">+</span>
                      </h1>
                      <p>Our Departments</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-4">
                    <div className="counter-card">
                      <h1>
                        <span className="odometer">
                          <CountUp end={props.courseList} />
                        </span>
                        <span className="target">+</span>
                      </h1>
                      <p>Our Courses</p>
                    </div>
                  </div>
                </div>
              </div>
              <Link to={"/admission/application"} className="default-btn btn">
                Start your application <i className="flaticon-next" />
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="campus-image pl-20">
              <img
                src={props.homeData.DiscoverPicture !== null ? props.homeData.DiscoverPicture.includes("simplefileupload") ? props.homeData.DiscoverPicture :  `${serverLink}public/uploads/${projectShortCode}/content_uploads/${props.homeData.DiscoverPicture}` : ""}
                alt={"Our campus"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    courseList: state.CourseListDetails.length,
    departmentList: state.DepartmentListDetails.length,
    homeData: state.HomepageContentDetails,
  };
};

export default connect(mapStateToProps, null)(HomepageSectionInfoArea);
