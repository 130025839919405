import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import stateData from "../../../../resources/state_and_lga.json";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { serverLink } from "../../../../resources/url";
import { setApplicationLoginDetails } from "../../../../actions/setactiondetails";
import Sidebar from "./Sidebar";
import StateData from "../../../../resources/state_and_lga.json";
import { toast } from "react-toastify";

function PersonalInformation(props) {
  const navigate = useNavigate();
  const userId = props.applicationLogin.EntryID;
  const [activeLink, setActiveLink] = useState(false);
  const [userData] = useState(props.applicationLogin);
  const [applicantData, setApplicationData] = useState({
    EntryID: "",
    FirstName: "",
    MiddleName: "",
    Surname: "",
    PhoneNumber: "",
    EmailAddress: "",
    DateOfBirth: "",
    Gender: "",
    Nationality: "",
    StateOfOrigin: "",
    LGA: "",
    Occupation: "",
    Religion: "",
    Address: "",
  });

  // const [lgaList, setLGAList] = useState(
  //   StateData.filter(
  //     (state) => state.state === props.applicationLogin.StateOfOrigin
  //   )[0].lgas
  // );

  const [shouldSave, setShouldSave] = useState(false);

  useEffect(() => {
    async function getUserData() {
      axios
        .get(`${serverLink}application/user/${userId}`)
        .then((response) => {
          const res = response.data.data[0];
          setApplicationData({
            ...res,
            DateOfBirth: res.DateOfBirth.split("T")[0],
          });
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    }
    getUserData();
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;

    setShouldSave(true);

    // if (id === "StateOfOrigin") {
    //   if (value !== "") {
    //     setLGAList(StateData.filter((state) => state.state === value)[0].lgas);
    //   } else {
    //     setLGAList([]);
    //   }
    // }
    setApplicationData({ ...applicantData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let item in applicantData) {
      if (
        applicantData.hasOwnProperty(item) &&
        item !== "Occupation" &&
        item !== "MiddleName"
      ) {
        if (applicantData[item] === "") {
          toast.error(`${item} can't be empty`);
          return false;
        }
      }
    }

    setActiveLink(true);
    if (shouldSave) {
      toast.info(`Submitting... Please wait!`);

      await axios
        .patch(`${serverLink}application/personal/info`, applicantData)
        .then((res) => {
          if (res.data.message === "success") {
            props.setOnApplicationLoginDetails(applicantData);
            toast.info(`Record Saved!`);
            onNext();
          } else
            toast.error(
              `Something went wrong. Please check your connection and try again!`
            );
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    } else onNext();
  };

  const onPrevious = () => {
    navigate("/admission/application/undergraduate");
  };

  const onNext = () => {
    navigate("/admission/application/undergraduate/secondary-school-result");
  };

  return (
    <div className="graduate-admission pb-70">
      <div className="container">
        <div className="row">
          <Sidebar userData={userData} />

          <div className="col-lg-9">
            <div className="register">
              <h3>Personal Information</h3>

              <form onSubmit={handleSubmit}>
                <div className="row">
                  {/*{applicantData.m}*/}
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="FirstName">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="FirstName"
                        className="form-control"
                        placeholder="First Name"
                        value={applicantData.FirstName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="MiddleName">Middle Name</label>
                      <input
                        type="text"
                        id="MiddleName"
                        className="form-control"
                        placeholder="Middle Name"
                        value={applicantData.MiddleName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="Surname">
                        Surname <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="Surname"
                        className="form-control"
                        placeholder="Surname"
                        value={applicantData.Surname}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="PhoneNumber">
                        Phone Number<span className="text-danger">*</span>
                      </label>
                      <input
                        type="tel"
                        id="PhoneNumber"
                        className="form-control"
                        placeholder="Phone Number"
                        value={applicantData.PhoneNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="DateOfBirth">
                        Date of Birth <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        id="DateOfBirth"
                        className="form-control"
                        placeholder="Date of Birth*"
                        value={applicantData.DateOfBirth.split("T")[0]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="Gender">
                        Gender <span className="text-danger">*</span>
                      </label>
                      <select
                        id="Gender"
                        className="form-control"
                        onChange={handleChange}
                        value={applicantData.Gender}
                      >
                        <option value="">Select Option</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="Nationality">
                        Nationality <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="Nationality"
                        className="form-control"
                        placeholder="Nationality"
                        value={applicantData.Nationality}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="StateOfOrigin">
                        State of Origin <span className="text-danger">*</span>
                      </label>
                      <input
                          type="text"
                          id="StateOfOrigin"
                          className="form-control"
                          placeholder="StateOfOrigin"
                          value={applicantData.StateOfOrigin}
                          onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                      <label htmlFor="LGA">Local Government</label>
                      <input
                          type="text"
                          id="StateOfOrigin"
                          className="form-control"
                          placeholder="LGA"
                          value={applicantData.LGA}
                          onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="Occupation">Occupation</label>
                      <input
                        type="text"
                        id="Occupation"
                        className="form-control"
                        placeholder="Occupation"
                        value={applicantData.Occupation}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="Religion">
                        Religion <span className="text-danger">*</span>
                      </label>
                      <select
                        id="Religion"
                        className="form-control"
                        onChange={handleChange}
                        value={
                          applicantData?.Religion
                            ? applicantData?.Religion
                            : undefined
                        }
                      >
                        <option value="">Select Option</option>
                        <option value="Christianity">Christianity</option>
                        <option value="Islam">Islam</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="Address">
                      Address <span className="text-danger">*</span>
                    </label>
                    <textarea
                      type="text"
                      id="Address"
                      rows="3"
                      className="form-control"
                      placeholder="Address"
                      value={applicantData?.Address}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <button
                  className="default-btn btn w-50 active"
                  onClick={onPrevious}
                >
                  Previous
                </button>
                <button type="submit" className="default-btn btn w-50 active">
                  Save and Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    applicationLogin: state.ApplicationLoginDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setOnApplicationLoginDetails: (p) => {
      dispatch(setApplicationLoginDetails(p));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInformation);
