import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import { Link } from "react-router-dom";
import Section from "../section/section";
import OfflineMessage from "../../common/message/offline";
import PageName from "../../common/pagename/pagename";
import axios from "axios";
import { serverLink } from "../../../resources/url";

function AcademicCalender(props) {
  const [loading, setLoading] = useState(true);
  const [calender, setCalender] = useState([]);

  const getCalender = async () => {
    await axios
      .get(`${serverLink}academic/education/all/`)
      .then((response) => {
        setCalender(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("NETWORK ERROR", error);
      });
  };

  useEffect(() => {
    getCalender().then((r) => { });
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageName page={"Academic Calender"} />
          <div className="page-banner-area bg-2">
            <div className="container">
              <div className="page-banner-content">
                <h1>Academic Calendar</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Academic</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="graduate-admission pt-100 pb-70" style={{ textAlign: "justify" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="how-to-apply">
                    <h3>Academic Calendar</h3>
                    <p>
                      Our University academic calendar is divided into two
                      semesters. Students typically register minimum of 22
                      credit units and maximum of 30 credit units per semester.
                      A credit unit includes lectures, interactive
                      sessions, laboratory/workshops, seminars and field trips.
                    </p>
                  </div>
                </div>
                <Section />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default AcademicCalender;
