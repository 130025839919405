import React from "react";
import PageTitle from "../../common/pagetitle/pagetitle";
import PageName from "../../common/pagename/pagename";
import { connect } from "react-redux";
import ResearchPic from "./research.png"
const Research = (props) => {
    return (
        <>
            <PageName page={"Research"} />
            <PageTitle title={"Research"} breadCrumbs={["Academics", "Research"]} />
            <div className="container mt-5 mb-5">
                <div className="row align-items-center pt-10 mt-3">
                    <div className="col-lg-6">
                        <div className="campus-content pr-20">
                            <div className="campus-title">
                                <h3>Our commitment</h3>
                                <p style={{ textAlign: 'justify' }}>
                                OUB is committed to cutting-edge research in medical and agricultural sciences and technologies, with the objective of advancing Africa’s and the world’s store of knowledge and problem-solving tools. To that effect, OUB is working assiduously to form partnerships with universities and research organizations around the world, in order to bring together some of the finest minds as well as experienced researchers in medical and agricultural science and technologies, so that effective progress can be made in the area of research and development.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="campus-image pl-20">
                            <img
                                src={ResearchPic}
                                alt={"research"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default connect(mapStateToProps, null)(Research);
