import React, { useState } from "react";
import StateData from "../../../../../resources/state_and_lga.json";
import { serverLink } from "../../../../../resources/url";
import { toast } from "react-toastify";
import axios from "axios";
import { setApplicationLoginDetails } from "../../../../../actions/setactiondetails";
import { connect } from "react-redux";

function PGApplicationPersonalInfo(props) {
  const [applicantData, setApplicantData] = useState(props.applicant_info);
  const [lgaList, setLGAList] = useState(
    StateData.filter(
      (state) => state.state === props.applicant_info.StateOfOrigin
    )[0].lgas
  );
  const [shouldSave, setShouldSave] = useState(false);

  const onEdit = (e) => {
    const value = e.target.value;
    const id = e.target.id;

    setShouldSave(true);

    if (id === "StateOfOrigin") {
      if (value !== "") {
        setLGAList(StateData.filter((state) => state.state === value)[0].lgas);
      } else {
        setLGAList([]);
      }
    }

    setApplicantData({
      ...applicantData,
      [id]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    for (let key in applicantData) {
      if (
        applicantData.hasOwnProperty(key) &&
        key !== "Occupation" &&
        key !== "MiddleName"
      ) {
        if (applicantData[key] === "") {
          toast.error(`${key} can't be empty`);
          return false;
        }
      }
    }

    if (shouldSave) {
      toast.info(`Submitting... Please wait!`);

      await axios
        .patch(`${serverLink}application/personal/info`, applicantData)
        .then((res) => {
          if (res.data.message === "success") {
            props.setOnApplicationLoginDetails(applicantData);
            onNext();
          } else
            toast.error(
              `Something went wrong. Please check your connection and try again!`
            );
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
    } else {
      onNext();
    }
  };

  const onPrevious = () => {
    props.active_page({
      dashboard: "active",
      personal_info: "",
    });
  };

  const onNext = () => {
    props.active_page({
      personal_info: "",
      qualification: "active",
    });
  };

  return (
    <div className="register">
      <h3>Personal Information</h3>

      <div className="row">
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="FirstName">First Name</label>
            <input
              type="text"
              id="FirstName"
              className="form-control"
              placeholder="First Name*"
              required
              value={applicantData.FirstName}
              onChange={onEdit}
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="MiddleName">Middle Name</label>
            <input
              type="text"
              id="MiddleName"
              className="form-control"
              placeholder="Middle Name"
              required
              value={applicantData.MiddleName}
              onChange={onEdit}
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="Surname">Surname</label>
            <input
              type="text"
              id="Surname"
              className="form-control"
              placeholder="Surname"
              value={applicantData.Surname}
              onChange={onEdit}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="form-group">
            <label htmlFor="PhoneNumber">Phone Number</label>
            <input
              type="tel"
              id="PhoneNumber"
              className="form-control"
              placeholder="Phone Number*"
              required
              value={applicantData.PhoneNumber}
              onChange={onEdit}
            />
          </div>
        </div>

        <div className="col-lg-6 col-md-6">
          <div className="form-group">
            <label htmlFor="DateOfBirth">Date of Birth</label>
            <input
              type="date"
              id="DateOfBirth"
              className="form-control"
              placeholder="Date of Birth*"
              value={applicantData.DateOfBirth}
              onChange={onEdit}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="form-group">
            <label htmlFor="Gender">Gender</label>
            <select
              id="Gender"
              className="form-control"
              value={applicantData.Gender}
              onChange={onEdit}
            >
              <option value="">Select Option</option>
              <option value="Female">Female</option>
              <option value="Male">Male</option>
            </select>
          </div>
        </div>

        <div className="col-lg-6 col-md-6">
          <div className="form-group">
            <label htmlFor="Nationality">Nationality</label>
            <input
              type="text"
              id="Nationality"
              className="form-control"
              placeholder="Nationality*"
              value={applicantData.Nationality}
              onChange={onEdit}
            />
          </div>
        </div>

        <div className="col-lg-8 col-md-8">
          <div className="form-group">
            <label htmlFor="Address">Address</label>
            <input
              type="text"
              id="Address"
              className="form-control"
              placeholder="Address*"
              value={applicantData.Address}
              onChange={onEdit}
            />
          </div>
        </div>

        <div className="col-lg-4 col-md-4">
          <div className="form-group">
            <label htmlFor="StateOfOrigin">State of Origin</label>
            <select
              id="StateOfOrigin"
              className="form-control"
              value={applicantData.StateOfOrigin}
              onChange={onEdit}
            >
              <option value="">Select Option</option>
              {StateData.length > 0 &&
                StateData.map((state, index) => {
                  return (
                    <option key={index} value={state.state}>
                      {state.state}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="col-lg-4 col-md-4">
          <div className="form-group">
            <label htmlFor="LGA">Local Government</label>
            <select
              id="LGA"
              className="form-control"
              onChange={onEdit}
              value={applicantData.LGA}
            >
              {lgaList.length > 0 &&
                lgaList.map((lga, index) => {
                  return (
                    <option key={index} value={lga}>
                      {lga}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="col-lg-4 col-md-4">
          <div className="form-group">
            <label htmlFor="Occupation">Occupation</label>
            <input
              type="text"
              id="Occupation"
              className="form-control"
              placeholder="Occupation"
              value={applicantData.Occupation}
              onChange={onEdit}
            />
          </div>
        </div>

        <div className="col-lg-4 col-md-4">
          <div className="form-group">
            <label htmlFor="Religion">Religion</label>
            <select
              id="Religion"
              className="form-control"
              value={applicantData.Religion}
              onChange={onEdit}
            >
              <option value="">Select Option</option>
              <option value="Christianity">Christianity</option>
              <option value="Islam">Islam</option>
              <option value="Others">Others</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-sm-6">
          <button className="default-btn btn w-100 active" onClick={onPrevious}>
            Previous
          </button>
        </div>
        <div className="col-lg-6 col-sm-6">
          <button className="default-btn btn w-100 active" onClick={onSubmit}>
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOnApplicationLoginDetails: (p) => {
      dispatch(setApplicationLoginDetails(p));
    },
  };
};

export default connect(null, mapDispatchToProps)(PGApplicationPersonalInfo);
