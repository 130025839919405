import React from "react";
import PageTitle from "../../common/pagetitle/pagetitle";
import { Link } from "react-router-dom";
import brim from "../../../images/brim.jpg";
import wsauce from "../../../images/wsaucelocation.jpg";
import tuck_shop from "../../../images/tuck_shop.png";
import PageName from "../../common/pagename/pagename";
export default function Feeding() {
  return (
    <>
      <PageName page={"Feeding"} />
      <PageTitle
        title={` Feeding `}
        breadCrumbs={["Admission", "Fees", "Feeding"]}
      />
      <div className="academics-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-4">Feeding</h3>
              <h5 className="mb-4">Getting your Meals</h5>
              <p style={{ textAlign: "justify" }}>
                The meals arrangement is solely-based on the students’
                preference. The school however has made a provision for students
                and parents that want to regulate the feeding cost in an
                effective manner. Students can pay a certain amount (there are
                no limitations) and be given a feeding booklet. Using the slips
                in this booklet, they can buy food from the eating areas that
                use this method as a form of payment. This reduces the need to
                carry around cash whilst ensuring that students are properly fed
                and satisfied. Currently, the only cafeterias that use this form
                of transaction is Brim and Terminal 2
              </p>
              <div className="row mb-4">
                <div className="col-md-5">
                  <img src={brim} className="img-thumbnail w-100" />
                </div>
                <div className="col-md-7">
                  <h4 className="mb-4">The Brim</h4>
                  <hr />
                  <p style={{ textAlign: "justify" }}>
                    The Brim was the university’s first open cafeteria. Since
                    its inception, the Brim has been dedicated to serving the
                    most delightful array of dishes to ensure that their
                    customers have an enjoyable moment at the university.
                    Ranging from rice dishes to traditional swallows, the Brim
                    has proven to be a connoisseur of well-made food. It also
                    includes an outdoor arena where students can relax and enjoy
                    their meals as well as a shawarma shack.
                  </p>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-7">
                  <h4 className="mb-4">Grilled food</h4>
                  <hr />
                  <p style={{ textAlign: "justify" }}>
                    Grilled food is always a delight to partake in and WSauce
                    provides just the right amount of beautifully grilled meals
                    that will leave you pandering for extra helpings. Their
                    exhaustive menu of grilled meats, shawarma, and even noodles
                    are popular amongst the students of the university.
                  </p>
                </div>
                <div className="col-md-5">
                  <img src={wsauce} className="img-thumbnail w-100" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <img src={tuck_shop} className="img-thumbnail w-100" />
                </div>
                <div className="col-md-7">
                  <h4 className="mb-4">The Tuck Shop</h4>
                  <hr />
                  <p style={{ textAlign: "justify" }}>
                    The Tuck Shop offers a range of delicacies including grilled
                    awesomeness such as burgers and hotdogs served with a side
                    of crispy fries. Other appetizing meals include wings
                    (barbecued or crispy) as well as corndogs. The meals served
                    in this center are carefully and deliciously made and has
                    made the center a connoisseur of gourmet food.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="detgails-right-content">
                <div className="academics-list">
                  <h3>QUICK LINKS</h3>
                  <ul>
                    <li>
                      <Link to={`/fees`}>
                        Fees <i className="ri-arrow-drop-right-fill" />
                      </Link>
                    </li>
                    <li>
                      <Link to={`/admission/course-finder`}>
                        Course Finder <i className="ri-arrow-drop-right-fill" />
                      </Link>
                    </li>
                    <li>
                      <Link to={`/education`}>
                        Education <i className="ri-arrow-drop-right-fill" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
