export const formatDate = (date) => {
    if (date !== null) {
        const user_date = new Date(date);
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()}
                    
                    `
    } else {
        return '--'
    }
};