import React from "react";
import PageTitle from "../../common/pagetitle/pagetitle";
import PageName from "../../common/pagename/pagename";
import { connect } from "react-redux";
const Innovation = (props) => {
    return (
        <>
            <PageName page={"Innovation"} />
            <PageTitle title={"Innovation"} breadCrumbs={["Academics","Innovation"]} />
            <div className="container mt-5 mb-5">
                <div className="row align-items-center pt-10 mt-3">
                    <div className="col-lg-12">
                        <div className="campus-content pr-20">
                            <div className="campus-title">
                                <h3>Our endeavour</h3>
                                <p style={{ textAlign: 'justify' }}>
                                OUB is totally committed to innovation in all the fields of its institutional focus. In fact, as part and parcel of its mandate and mission, OUB seeks, encourages and incentives as much innovation as possible, especially, in the fields of medical sciences and agricultural; by its faculty and staff, by engaging visiting faculty, student and faculty exchange programs; as well as by extension services in the community of its institutional location.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default connect(mapStateToProps, null)(Innovation);
