import React from "react";
import PageName from "../../common/pagename/pagename";
import PageTitle from "../../common/pagetitle/pagetitle";

const Visarequirements = (props) => {
    return (
        <>
            <PageName page={"Visa Requirements"} />
            <PageTitle title={"Visa Requirements"} breadCrumbs={["Admission", "International Students","Visa Requirements"]} />
            <div className="container">
                <div className="row align-items-center pt-10 mt-3">
                    <div className="col-lg-12">
                        <div className="campus-content pr-20">
                            <div className="campus-title pt-10 mt-5 mb-5 pb-5">
                                <h3>What you need to know about Visa requirements</h3>
                                <p style={{ textAlign: 'justify' }}>
                                    <ol >
                                        <li>
                                            <p>Students from the East African Community don’t need to pay for visa. The student is given a Pass and it’s free of charge.</p>
                                        </li>
                                        <li>
                                            <p>Students from other countries are given a 2-year Resident Permit upon payment of USD 500.</p>
                                        </li>
                                        <li>
                                            <p>They must pay for the 1-month entry visa at the airport before they can apply for the Resident Permit.</p>
                                        </li>
                                        <li>
                                            <p>For those doing courses for more than two years, their Resident Visas, will just be renewed.</p>
                                        </li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        {/* <div className="campus-image pl-20">
                            <img
                                src={BujumBura1}
                                alt={"Life in Bujumbura"}
                            />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default Visarequirements; //connect(mapStateToProps, null)(Visarequirements);
