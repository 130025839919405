import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Loader from "../../common/loader/loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { projectShortCode, serverLink } from "../../../resources/url";
import PageName from "../../common/pagename/pagename";
import {
  setHRStaffDataDetails,
  setHRStaffListDetails,
} from "../../../actions/setactiondetails";
import axios from "axios";

function StaffDetail(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const navigate = useNavigate();

  const [titles, setTitles] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [staffRelatedDocuments, setStaffRelatedDocuments] = useState({
    qualifications: [],
    modules: [],
    publications: [],
  });



    let staffRecord = props.hrStaffList.filter((item) => item.Slug === slug);
  const [staffDetail, setStaffDetail] = useState(
      staffRecord.length > 0
          ? props.hrStaffData.filter(
              (item) => item.StaffID === staffRecord[0].StaffID
          )
          : []
  );

  const getHRStaffList = async () => {
        await axios
            .get(`${serverLink}academic/titles/`)
            .then((response) => {
                setTitles(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academic/designation/`)
            .then((response) => {
                setDesignation(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

      await axios
          .get(`${serverLink}academic/departments/`)
          .then((response) => {
              setDepartments(response.data);
              setLoading(false);
          })
          .catch((error) => {
              console.log("NETWORK ERROR", error);
          });
    };

  useEffect(() => {
    const slug_record = slug;
    staffRecord.push(
        props.hrStaffList.filter((item) => item.Slug === slug_record)
    );
    setStaffDetail(
        staffRecord.length > 0
            ? props.hrStaffData.filter(
                (item) => item.StaffID === staffRecord[0].StaffID
            )
            : []
    );
  }, [slug]);

  const getStaffData = async () => {
    await axios
        .get(`${serverLink}academic/staff/all/`)
        .then((response) => {
          const data = response.data;

          const staff_data = data.filter((item) => item.Slug === slug);
          props.setOnHRStaffListDetails(response.data);
          if (data.filter((item) => item.Slug === slug).length < 1) {
            navigate("/");
          } else {
            staffRecord.push(staff_data);
          }
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });

    await axios
        .get(`${serverLink}academic/staff/data/`)
        .then((response) => {
          props.setOnHRStaffDataDetails(response.data);
          setStaffDetail(
              response.data.filter(
                  (item) => item.EntryID === staffRecord[0].EntryID
              )
          );
          if (staffDetail.length < 1) navigate("/");
          else console.log(staffDetail);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
  };

  if (staffDetail.length < 1) {
    if (loading) {
      getStaffData().then((r) => {});
      setLoading(false);
    }
  }

  useEffect(() => {
    getStaffRelatedDocument().then((r) => {});
    getHRStaffList().then((r) => {});
  }, [dispatch]);

  const getStaffRelatedDocument = async () => {
    await axios
        .get(`${serverLink}academic/staff/${staffRecord[0].StaffID}`)
        .then((response) => {
          setStaffRelatedDocuments(response.data);
        })
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
  };

  const updateDownloadCount = async (id) => {
    await axios
        .put(`${serverLink}academic/update/download/count/${id}`)
        .then((response) => {})
        .catch((error) => {
          console.log("NETWORK ERROR", error);
        });
  };

  if (staffDetail.length < 1) {
    if (loading) {
      getStaffData().then((r) => {});
      setLoading(false);
    }
  }

  return staffDetail.length < 1 ? (
      <Loader />
  ) : (
      <>
        {staffDetail ? (
            <>
              <PageName page={"AddEditStaff Profile"} />
              <div className="page-banner-area bg-2">
                <div className="container">
                  <div className="page-banner-content">
                    <h1>Staff Profile</h1>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>Staff Profile</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="courses-details-area pt-100 pb-70">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="courses-details">
                        <div className="description">
                          <div className="container p-0">
                            <nav>
                              <div
                                  className="nav nav-tabs d-flex justify-content-between"
                                  id="nav-tab"
                                  role="tablist"
                              >
                                <button
                                    className="nav-link active"
                                    id="nav-instructor-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-instructor"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-instructor"
                                    aria-selected="true"
                                    tabIndex="-1"
                                >
                                  Biography
                                </button>

                                <button
                                    className="nav-link"
                                    id="nav-curriculum-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-curriculum"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-curriculum"
                                    aria-selected="false"
                                    tabIndex="-1"
                                >
                                  Qualification(s)
                                </button>

                                <button
                                    className="nav-link"
                                    id="nav-course-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-course"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-course"
                                    aria-selected="false"
                                    tabIndex="-1"
                                >
                                  Courses Taken
                                </button>

                                <button
                                    className="nav-link"
                                    id="nav-overview-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-overview"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-overview"
                                    aria-selected="false"
                                >
                                  Publications
                                </button>
                              </div>
                            </nav>

                            <div className="tab-content" id="nav-tabContent">
                              <div
                                  className="tab-pane fade active show"
                                  id="nav-instructor"
                                  role="tabpanel"
                                  aria-labelledby="nav-instructor-tab"
                              >
                                <div className="row">
                                  <div className="instructor">
                                    <div className="row align-items-center">
                                      <>
                                        <div className="col-lg-5">
                                          <div className="instructor-image">
                                            <img
                                                src={staffDetail[0].Image !== null ? staffDetail[0].Image.includes("simplefileupload") ? staffDetail[0].Image :  `${serverLink}public/uploads/${projectShortCode}/hr/document/${staffDetail[0].Image}` : ""}
                                                alt="Image"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-7">
                                          <div className="instructor-content">
                                            <div className="name">
                                              <h4>
                                                  {titles.length > 0 &&
                                                  titles.filter((i) =>
                                                      i.EntryID ===
                                                      staffDetail[0].TitleID
                                                  )
                                                      .map((r) => r.TitleName)} {""}

                                                {staffDetail[0].FirstName}{" "}
                                                {staffDetail[0].MiddleName}{" "}
                                                {staffDetail[0].Surname}
                                              </h4>
                                            </div>
                                            <p className="mb-30">
                                              {staffDetail[0].Biography}
                                            </p>
                                            <div className="quick-contact">
                                              <h4>Quick Contact</h4>
                                              <ul>
                                                <li>
                                                  <i className="ri-phone-line"></i>
                                                  <span>Phone Number:</span>
                                                  {staffDetail[0].PhoneNumber}
                                                </li>
                                                <li>
                                                  <i className="ri-mail-line"></i>
                                                  <span>Email:</span>
                                                  <a>
                                                <span className="__cf_email__">
                                                  {" "}
                                                  {
                                                    staffDetail[0]
                                                        .OfficialEmailAddress
                                                  }
                                                </span>
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="social">
                                              <ul>
                                                <li>
                                                  <a
                                                      target="_blank"
                                                      href={`${staffDetail[0].Linkedin}`}
                                                  >
                                                    <i className="ri-linkedin-fill"></i>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                      target="_blank"
                                                      href={staffDetail[0].Facebook}
                                                  >
                                                    <i className="ri-facebook-fill"></i>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                      target="_blank"
                                                      href={staffDetail[0].Scholar}
                                                  >
                                                    <i className="ri-google-fill"></i>
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                  className="tab-pane fade"
                                  id="nav-overview"
                                  role="tabpanel"
                                  aria-labelledby="nav-overview-tab"
                              >
                                <div className="overview">
                                  {staffRelatedDocuments.publications.length > 0 ? (
                                      <>
                                        <div className="overview-box">
                                          {staffRelatedDocuments.publications.map(
                                              (pub, index) => (
                                                  <div key={index}>
                                                    <h4>{pub.WorkTitle}</h4>
                                                    <strong>Authors: </strong>{" "}
                                                    <span>{pub.Authors}</span>
                                                    <p />
                                                    <strong>Download: </strong>{" "}
                                                    <span>{pub.DownloadCount}</span> |{" "}
                                                    <strong>View: </strong>{" "}
                                                    <span>{pub.ViewCount}</span>
                                                    <p>{pub.ArticleTitle}</p>
                                                    <p
                                                        style={{
                                                          fontStyle: "italic",
                                                        }}
                                                    >
                                                      <strong>Year Published :</strong>{" "}
                                                      {pub.PublishedYear} |{" "}
                                                      <a
                                                          style={{
                                                            color: "blue",
                                                          }}
                                                          href={`${serverLink}public/uploads/${projectShortCode}/academic/publication/${
                                                              pub.UploadFile
                                                                  ? pub.UploadFile
                                                                  : ""
                                                          }`}
                                                          target="_blank"
                                                          onClick={() =>
                                                              updateDownloadCount(pub.EntryID)
                                                          }
                                                      >
                                                        {" "}
                                                        Download Paper
                                                      </a>
                                                    </p>
                                                  </div>
                                              )
                                          )}
                                        </div>
                                      </>
                                  ) : (
                                      <>{null}</>
                                  )}
                                </div>
                              </div>

                              <div
                                  className="tab-pane fade"
                                  id="nav-curriculum"
                                  role="tabpanel"
                                  aria-labelledby="nav-curriculum-tab"
                              >
                                <div className="curriculum">
                                  <div className="curriculum-list">
                                    <div className="card-body">
                                      <ul>
                                        {staffRelatedDocuments.qualifications
                                            .length > 0 ? (
                                            <>
                                              {staffRelatedDocuments.qualifications.map(
                                                  (r, index) => (
                                                      <div key={index}>
                                                        <li>
                                                          <h5 className="card-title">
                                                            {r.InstitutionName}
                                                          </h5>
                                                          <h6 className="card-subtitle mb-2 text-muted">
                                                            {r.QualificationTitle}{" "}
                                                            {r.Discipline}
                                                          </h6>
                                                          <p className="card-text">
                                                            {r.Year}
                                                          </p>
                                                        </li>
                                                      </div>
                                                  )
                                              )}
                                            </>
                                        ) : (
                                            <>{null}</>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                  className="tab-pane fade"
                                  id="nav-course"
                                  role="tabpanel"
                                  aria-labelledby="course-tab"
                              >
                                <div className="curriculum">
                                  <div className="curriculum-list">
                                    {staffRelatedDocuments.modules.length > 0 ? (
                                        <>
                                          <ul>
                                            {staffRelatedDocuments.modules.map(
                                                (r, index) => (
                                                    <div key={index}>
                                                      <li>
                                                        {r.ModuleCode}:{" "}
                                                        {r.ModuleDescription}{" "}
                                                        <span>
                                                {r.CreditUnit} Credit Unit(s)
                                              </span>
                                                      </li>
                                                    </div>
                                                )
                                            )}
                                          </ul>
                                        </>
                                    ) : (
                                        <>{null}</>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                  className="tab-pane fade"
                                  id="nav-reviews"
                                  role="tabpanel"
                                  aria-labelledby="nav-reviews-tab"
                              >
                                <div className="reviews">
                                  <div className="single-reviews-card">
                                    <img
                                        src="assets/images/courses/review-1.png"
                                        alt="Images"
                                    />
                                    <h2>Jorge Overall</h2>
                                    <div className="date">
                                      <p>jan 17, 2022 at 10:43 am</p>
                                    </div>
                                    <div className="icon">
                                      <i className="ri-star-fill"></i>
                                      <i className="ri-star-fill"></i>
                                      <i className="ri-star-fill"></i>
                                      <i className="ri-star-fill"></i>
                                      <i className="ri-star-fill"></i>
                                    </div>
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tem incid
                                      idunt ut dolore magna aliqua. Ut enim ad minim
                                      ven iam quis nostrud{" "}
                                    </p>
                                  </div>
                                  <div className="single-reviews-card">
                                    <img
                                        src="assets/images/courses/review-2.png"
                                        alt="Images"
                                    />
                                    <h2>Manuel Myers</h2>
                                    <div className="date">
                                      <p>jan 17, 2022 at 10:43 am</p>
                                    </div>
                                    <div className="icon">
                                      <i className="ri-star-fill"></i>
                                      <i className="ri-star-fill"></i>
                                      <i className="ri-star-fill"></i>
                                      <i className="ri-star-fill"></i>
                                      <i className="ri-star-fill"></i>
                                    </div>
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tem incid
                                      idunt ut dolore magna aliqua. Ut enim ad minim
                                      ven iam quis nostrud{" "}
                                    </p>
                                  </div>
                                  <div className="single-reviews-card">
                                    <img
                                        src="assets/images/courses/review-1.png"
                                        alt="Images"
                                    />
                                    <h2>Sally Hatch</h2>
                                    <div className="date">
                                      <p>jan 17, 2022 at 10:43 am</p>
                                    </div>
                                    <div className="icon">
                                      <i className="ri-star-fill"></i>
                                      <i className="ri-star-fill"></i>
                                      <i className="ri-star-fill"></i>
                                      <i className="ri-star-fill"></i>
                                      <i className="ri-star-fill"></i>
                                    </div>
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tem incid
                                      idunt ut dolore magna aliqua. Ut enim ad minim
                                      ven iam quis nostrud{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="enroll-courses">
                        <div className="list">
                          <ul>
                            <>
                              <li>
                                <span>Names :</span>
                                  {titles.length > 0 &&
                                  titles.filter((i) =>
                                      i.EntryID ===
                                      staffDetail[0].TitleID
                                  )
                                      .map((r) => r.TitleName)} {""}

                                  {staffDetail[0].FirstName}{" "}
                                  {staffDetail[0].MiddleName}{" "}
                                  {staffDetail[0].Surname}
                              </li>
                              <li>
                                <span>Role :</span>
                                  {designation.length > 0 &&
                                  designation.filter((i) =>
                                      i.EntryID ===
                                      staffDetail[0].DesignationID
                                  )
                                      .map((r) => r.DesignationName)}
                              </li>
                              <li>
                                <span>Department :</span>
                                  {departments.length > 0 &&
                                  departments.filter((i) =>
                                      i.DepartmentCode ===
                                      staffDetail[0].DepartmentCode
                                  )
                                      .map((r) => r.DepartmentName)

                                }
                              </li>
                              <li>
                                <span>Profile Hit :</span>
                                {staffDetail[0].Hits}
                              </li>
                            </>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
        ) : (
            <di>
              <p>There is no record.</p>
            </di>
        )}
      </>
  );
}

const mapStateToProps = (state) => {
  return {
    hrStaffList: state.HRStaffListDetails,
    hrStaffData: state.HRStaffDataDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHRStaffListDetails: (p) => {
      dispatch(setHRStaffListDetails(p));
    },
    setOnHRStaffDataDetails: (p) => {
      dispatch(setHRStaffDataDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetail);
